import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useBranchStore } from "../../store";
import ScreenBottomNav from "../../components/Navigation/ScreenBottomNav";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMsg from "../../components/ErrorMsg";
import { useQuery } from "@tanstack/react-query";
import { getClosestBranchScheduleId } from "../../services/group";
import { getScheduleData } from "../../services/branchSchedule";
import { BranchScheduleResponse } from "../../types/dto";
import Button from "../../components/interface/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import UserGroupBox from "../../components/UserGroupBox";
import { BranchGroup } from "../../types/models";

type RegisterForm = {
  name: string;
  phone: string;
};

export default function ScreenRegister() {
  const { branchCode } = useParams();
  const storeBranchName = useBranchStore((state) => state.branchName);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState<BranchGroup>();
  const [registerOpen, setRegisterOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<RegisterForm>();

  const {
    data: scheduleId,
    isLoading,
    isError,
  } = useQuery<string, Error>(
    ["getClosestBranchScheduleId", branchCode],
    () => getClosestBranchScheduleId(branchCode!),
    {
      enabled: !!branchCode,
    }
  );

  const {
    data: scheduleData,
    isLoading: scheduleDataLoading,
    isError: scheduleDataError,
  } = useQuery<BranchScheduleResponse, Error>(
    ["getScheduleData", scheduleId],
    () => getScheduleData(scheduleId!, false),
    {
      enabled: !!scheduleId,
    }
  );

  const onValid = async (formData: RegisterForm) => {
    if (!scheduleId || !branchCode) {
      return;
    }
    setLoading(true);

    try {
      const { data } = await axios.post(`/api/public/group`, {
        branchCode: branchCode,
        scheduleId: scheduleId,
        name: formData.name,
        phone: formData.phone,
      });

      console.log(data);
      setUserData(data);
    } catch (error: any) {
      if (error.response && error.response.data) {
        alert(error.response.data);
      } else {
        alert(`서버 오류 발생 : ${error.message} `);
      }
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (scheduleData) {
      const startTimeString = scheduleData.startTime;
      const startTime = new Date(startTimeString);
      const twentyMinutesBefore = new Date(
        startTime.getTime() - 20 * 60 * 1000
      ); // 20 minutes = 20*60*1000 milliseconds

      const now = new Date();

      if (now > twentyMinutesBefore) {
        setRegisterOpen(true);
        console.log("현재시각이 startTime-20분 이후입니다.");
      } else {
        setRegisterOpen(false);
        console.log("현재시각이 startTime-20분 이전입니다.");
      }
    }
  }, [scheduleData]);

  console.log(scheduleData);

  return isLoading || scheduleDataLoading ? (
    <div className=" min-h-screen w-full flex justify-center items-center ">
      <span className="loading loading-spinner bg-gymmate "></span>
    </div>
  ) : registerOpen ? (
    <div className=" justify-center items-center min-h-screen flex w-full flex-col space-y-3    ">
      <img src="/main-logo.svg" className=" w-24  mb-5 " alt="logo" />
      <div className="text-center">
        <h1 className="text-xl font-bold ">[{storeBranchName}]</h1>
        <p>
          {scheduleData?.startTime?.replace("T", " ").slice(0, -3)} - 그룹배정
        </p>
      </div>

      {userData ? (
        <div className="w-full px-10">
          <UserGroupBox item={userData} i={userData.indexNum} readonly={true} />
          <p className="text-center mt-10">
            그룹 배정이 완료되었습니다. <br />
            본인의 자리를 확인하고 수업을 준비하세요.
          </p>
        </div>
      ) : (
        <form className="w-full px-10 max-w-lg space-y-5 ">
          <div>
            <label className="block text-gray-700  mb-2 text-sm ">이름</label>
            <input
              {...register("name", { required: "이름을 입력하세요" })}
              placeholder="이름"
              className="border rounded-lg py-2 px-3 text-gray-700 w-full border-black"
            />
          </div>
          <div>
            <label className="block text-gray-700  mb-2 text-sm ">
              연락처 뒤 4자리
            </label>
            <input
              {...register("phone", {
                pattern: {
                  value: /^[0-9]*$/,
                  message: "숫자만 입력해주세요",
                },
                minLength: 4,
                required: "전화번호을 입력해주세요",
              })}
              placeholder="연락처 뒤 4자리"
              maxLength={4}
              className="border rounded-lg py-2 px-3 text-gray-700 w-full border-black"
              type="tel"
            />
          </div>
          <Button
            loading={loading}
            title={"그룹 배정 받기"}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={isValid ? false : true}
          />
        </form>
      )}
    </div>
  ) : (
    <div className=" justify-center items-center min-h-screen flex w-full flex-col space-y-3    ">
      <img src="/main-logo.svg" className=" w-24  mb-5 " alt="logo" />
      <p className="text-center mt-10 font-bold">
        현재 배정 가능한 수업이 없습니다. <br />
        수업 배정은 수업 시작시간 <br />
        20분 전부터 가능합니다.
      </p>
    </div>
  );
}
