import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBook,
  faCirclePlus,
  faCircleXmark,
  faClock,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faLocation,
  faPerson,
  faPlus,
  faSign,
  faStopwatch,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";

import { useUserStore } from "../store";
import FormBox from "./interface/FormBox";
import Radio from "./interface/Radio";
import FormInput from "./interface/FormInput";
import Button from "./interface/Button";
import { useNavigate, useParams } from "react-router-dom";
import { cls } from "../libs/utils";
import Modal from "./interface/Modal";
import ContentsSearchModal from "./Modal/ContentsSearchModal";
import { v4 as uuidv4 } from "uuid";
import ContentsBoxWrap from "./ContentBoxDragWrap";
import ContentsGapDrag from "./ContentGapDrag";
import FullPageLoading from "./interface/FullPageLoading";
import { ProgramType } from "../constants/PrograyCategory";
import { Contents, Tool } from "../types/models";
import { ContentsDto, ContentsDtoWithId, ProgramDto } from "../types/dto";

interface UserFormData {
  id: number;
  programType: string;
  programCategory: string;
  name: string;
  totalMotions: number;
  exerciseTime: number;
  moveTime: number;
  description: string;
  comment: string;
}

interface ProgramDetailProps {
  readOnly?: boolean;
  programId?: string;
}

export default function ProgramDetail({
  readOnly = false,
  programId,
}: ProgramDetailProps) {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalExerciseTime, setTotalExerciseTime] = useState("");
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [zone1Data, setZone1Data] = useState<ContentsDtoWithId[]>([]);
  const [zone2Data, setZone2Data] = useState<ContentsDtoWithId[]>([]);
  const [zone3Data, setZone3Data] = useState<ContentsDtoWithId[]>([]);
  const [zone1Tools, setZone1Tools] = useState<string[]>([]);
  const [zone2Tools, setZone2Tools] = useState<string[]>([]);
  const [zone3Tools, setZone3Tools] = useState<string[]>([]);
  const [getLoading, setGetLoading] = useState(false);
  const [originalZoneId, setOriginalZoneId] = useState<string>("");
  const [tutorialId, setTutorialId] = useState<number[]>([]);

  const zone = [
    {
      id: "zone1",
      title: "1구역",
      zoneData: zone1Data,
      setZoneData: setZone1Data,
      zoneTools: zone1Tools,
    },
    {
      id: "zone2",
      title: "2구역",
      zoneData: zone2Data,
      setZoneData: setZone2Data,
      zoneTools: zone2Tools,
    },
    {
      id: "zone3",
      title: "3구역",
      zoneData: zone3Data,
      setZoneData: setZone3Data,
      zoneTools: zone3Tools,
    },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<UserFormData>();

  const onValid = async (formData: UserFormData) => {
    if (watch("programType") === "본운동" && watch("totalMotions") % 3 !== 0) {
      alert("전체 동작수를 3의 배수로 입력해주세요.");
      return;
    }
    if (
      watch("programType") === "본운동" &&
      Number(zone1Data.length) !== Number(watch("totalMotions")) / 3
    ) {
      alert("1구역 동작이 부족하거나 초과했어요.");
      return;
    }
    if (
      watch("programType") === "본운동" &&
      Number(zone2Data.length) !== Number(watch("totalMotions")) / 3
    ) {
      alert("2구역 동작이 부족하거나 초과했어요.");
      return;
    }
    if (
      watch("programType") === "본운동" &&
      Number(zone3Data.length) !== Number(watch("totalMotions")) / 3
    ) {
      alert("3구역 동작이 부족하거나 초과했어요.");
      return;
    }
    if (
      watch("programType") !== "본운동" &&
      Number(zone1Data.length) !== Number(watch("totalMotions"))
    ) {
      alert("동작이 부족하거나 초과했어요.");
      return;
    }
    setLoading(true);
    try {
      const zone1Ids = zone1Data.map(
        (item: ContentsDtoWithId) => item.content.id
      );
      const zone2Ids = zone2Data.map(
        (item: ContentsDtoWithId) => item.content.id
      );
      const zone3Ids = zone3Data.map(
        (item: ContentsDtoWithId) => item.content.id
      );

      if (programId) {
        await axios.patch(`/api/admin/program/${programId}`, {
          programType: formData.programType,
          programCategory: formData.programCategory,
          name: formData.name,
          totalMotions: formData.totalMotions,
          totalSet:
            watch("programType") === "본운동"
              ? Number(formData.totalMotions) * 2
              : Number(formData.totalMotions),
          zone1Ids: zone1Ids.toString(),
          zone2Ids: zone2Ids.toString(),
          zone3Ids: zone3Ids.toString(),
          exerciseTime: Number(formData.exerciseTime),
          moveTime: Number(formData.moveTime),
          totalExerciseTime: Number(totalSeconds),
          description: formData.description,
          comment: formData.comment,
          tutorialIds: tutorialId.join(","),
        });
      } else {
        // 2. DB 저장
        await axios.post(`/api/admin/program`, {
          programType: formData.programType,
          programCategory: formData.programCategory,
          name: formData.name,
          totalMotions: formData.totalMotions,
          totalSet:
            watch("programType") === "본운동"
              ? Number(formData.totalMotions) * 2
              : Number(formData.totalMotions),
          zone1Ids: zone1Ids.toString(),
          zone2Ids: zone2Ids.toString(),
          zone3Ids: zone3Ids.toString(),
          exerciseTime: Number(formData.exerciseTime),
          moveTime: Number(formData.moveTime),
          totalExerciseTime: Number(totalSeconds),
          description: formData.description,
          comment: formData.comment,
          tutorialIds: tutorialId.join(","),
        });
      }
      navigate(`/admin/program`);
      setLoading(false);
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
      setLoading(false);
    }
  };

  function formatTime(seconds: number): string {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    const exerciseTime = watch("exerciseTime") as number;
    const moveTime = watch("moveTime") as number;
    const totalMotions = watch("totalMotions") as number;

    const totalSecond =
      (Number(exerciseTime) + Number(moveTime)) *
      (watch("programType") === "본운동" ? totalMotions * 2 : totalMotions);
    setTotalSeconds(totalSecond);
    const formattedTime = formatTime(totalSecond);

    setTotalExerciseTime(formattedTime);
  }, [
    watch("exerciseTime"),
    watch("moveTime"),
    watch("totalMotions"),
    watch("programType"),
  ]);

  //모달 열기
  const showModal = (zoneId: string) => {
    //@ts-ignore
    window[zoneId]?.showModal();
  };

  const onDelete = (id: string, zoneId: string) => {
    const zoneObject = zone.find((item) => item.id === zoneId);
    const zoneData = findZoneData(zoneId);
    const zoneSetZoneData = zoneObject ? zoneObject.setZoneData : undefined;

    if (zoneSetZoneData && zoneData) {
      const updatedZoneData = zoneData.filter(
        (item: ContentsDtoWithId) => item.id !== id
      );
      zoneSetZoneData(updatedZoneData);
    }
  };

  console.log("tutorialId", tutorialId);
  const extractTools = (arr: ContentsDtoWithId[]) => {
    const toolNames = Array.from(
      new Set(
        arr
          .map((item: ContentsDtoWithId) =>
            item.tools.map((tool: Tool) => tool.name)
          )
          .flat()
      )
    );

    return toolNames;
  };

  //도구 추출
  useEffect(() => {
    const toolsName: string[] = extractTools(zone1Data);
    setZone1Tools(toolsName);
  }, [zone1Data]);

  //도구 추출
  useEffect(() => {
    const toolsName = extractTools(zone2Data);
    setZone2Tools(toolsName);
  }, [zone2Data]);

  //도구 추출
  useEffect(() => {
    const toolsName = extractTools(zone3Data);
    setZone3Tools(toolsName);
  }, [zone3Data]);

  const findZoneData = (id: string) => {
    const zoneItem = zone.find((item) => item.id === id);
    return zoneItem ? zoneItem.zoneData : null;
  };

  const onDuplicate = (item: ContentsDtoWithId, zoneId: string) => {
    const zoneData = findZoneData(zoneId);
    if (zoneData) {
      const zoneObject = zone.find((i) => i.id === zoneId);
      const zoneSetZoneData = zoneObject ? zoneObject.setZoneData : undefined;

      const newItem = { ...item, id: uuidv4() }; // 새로운 id로 item 복사
      const newData = [...zoneData, newItem];

      if (zoneSetZoneData) {
        zoneSetZoneData(newData);
      }
    }
  };

  //Drop시 실행되는 함수
  const moveContent = (
    draggedId: string,
    index: number,
    originalZoneId: string,
    newZoneId: string
  ) => {
    console.log("draggedId:", draggedId);
    console.log("index: ", index);
    console.log("originalZoneId: ", originalZoneId);

    // 드래그를 시작한 구역의 데이터와 드래그 하는 아이템의 데이터를 찾습니다.
    const originalZoneData = findZoneData(originalZoneId);

    if (!originalZoneData) {
      return;
    }

    console.log(originalZoneData);
    const draggedItemIndex = originalZoneData.findIndex(
      (item: ContentsDtoWithId) => item.id === draggedId
    );
    const draggedItem = originalZoneData[draggedItemIndex];

    // 드래그를 시작한 구역에서 데이터를 삭제합니다.
    const newOriginalData = [...originalZoneData];
    newOriginalData.splice(draggedItemIndex, 1);

    if (originalZoneId === newZoneId) {
      // 구역이 같은 경우

      // 같은 구역의 해당 Index에 데이터를 넣습니다.
      newOriginalData.splice(index, 0, draggedItem);

      // 각각의 zone에 대한 setZoneData를 찾습니다.
      const zoneObject = zone.find((item) => item.id === originalZoneId);

      // state에 최종적용합니다.
      if (zoneObject && zoneObject.setZoneData) {
        zoneObject.setZoneData(newOriginalData);
      }
    } else {
      // 구역이 변경된 경우

      // 드랍한곳 구역의 데이터를 얻습니다.
      const newZoneData = findZoneData(newZoneId);

      if (!newZoneData) {
        return;
      }

      // 해당 구역의 해당 index에 데이터를 넣습니다.
      const newNewData = [...newZoneData];
      newNewData.splice(index, 0, draggedItem);

      // 각각의 zone에 대한 setZoneData를 찾습니다.
      const originalZoneObject = zone.find(
        (item) => item.id === originalZoneId
      );
      const newZoneObject = zone.find((item) => item.id === newZoneId);

      // state에 최종적용합니다.
      if (originalZoneObject && originalZoneObject.setZoneData) {
        originalZoneObject.setZoneData(newOriginalData);
      }
      if (newZoneObject && newZoneObject.setZoneData) {
        newZoneObject.setZoneData(newNewData);
      }
    }
  };

  //수정의 경우, 기존 데이터 불러오기
  useEffect(() => {
    if (programId) {
      getOriginalData();
    }
  }, [programId]);

  const getOriginalData = async () => {
    setGetLoading(true);
    try {
      const response = await axios.get(`/api/public/program/${programId}`, {});

      const responseData: ProgramDto = response.data;

      const programData = responseData.program;
      const zone1Data = responseData.zone1Data;
      const zone2Data = responseData.zone2Data;
      const zone3Data = responseData.zone3Data;

      setZone1Data(zone1Data);
      setZone2Data(zone2Data);
      setZone3Data(zone3Data);

      setValue("programType", programData.programType);
      setValue("programCategory", programData.programCategory);
      setValue("name", programData.name);
      setValue("totalMotions", programData.totalMotions);
      setValue("exerciseTime", programData.exerciseTime);
      setValue("moveTime", programData.moveTime);
      setValue("description", programData.description || "");
      setValue("comment", programData.comment || "");
      if (responseData.program.tutorialIds) {
        const tutorialIdsArray = responseData.program.tutorialIds
          .split(",")
          .map(Number);

        setTutorialId(tutorialIdsArray);
      }
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  };

  console.log(tutorialId);

  return (
    <>
      {!readOnly &&
        zone.map((z) => (
          <Modal id={z.id} title={`동작 추가`} size="xlarge" overflow="hidden">
            <div>
              <ContentsSearchModal
                zoneData={z.zoneData}
                setZoneData={z.setZoneData}
              />
            </div>
          </Modal>
        ))}
      <div className="">
        <div className="bg-white drop-shadow-md p-10 ">
          {getLoading && <FullPageLoading />}
          <FormBox
            title="프로그램 종류"
            icon={<FontAwesomeIcon icon={faLocation} />}
          >
            <div className="flex space-x-5">
              {ProgramType.map((program) => (
                <Radio
                  title={program.name}
                  value={program.name}
                  register={register("programType", {
                    required: "프로그램 종류를 선택해주세요",
                  })}
                  disabled={readOnly}
                />
              ))}
            </div>
          </FormBox>
          <FormBox
            title="프로그램 구분"
            icon={<FontAwesomeIcon icon={faLocation} />}
          >
            <div className="flex space-x-5">
              <Radio
                title="상체"
                value="상체"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />
              <Radio
                title="하체"
                value="하체"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />

              <Radio
                title="전신"
                value="전신"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />

              <Radio
                title="코어/유산소"
                value="코어/유산소"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />
            </div>
          </FormBox>

          <FormBox title="프로그램명" icon={<FontAwesomeIcon icon={faSign} />}>
            {readOnly ? (
              watch("name")
            ) : (
              <FormInput
                placeholder="프로그램명"
                register={register("name", {
                  required: "프로그램명을 입력해주세요",
                })}
              />
            )}
          </FormBox>
          <div className="flex w-full space-x-2 ">
            <div className="w-1/2">
              <FormBox
                title="전체 동작수"
                icon={<FontAwesomeIcon icon={faStopwatch} />}
              >
                <div className="flex items-center space-x-5">
                  <div
                    className={cls(
                      "w-20",
                      watch("programType") === "본운동"
                        ? watch("totalMotions") % 3 === 0
                          ? "text-black"
                          : "text-red-500"
                        : "text-black"
                    )}
                  >
                    {readOnly ? (
                      watch("totalMotions")
                    ) : (
                      <FormInput
                        placeholder="전체 동작수"
                        type="number"
                        step={watch("programType") === "본운동" ? 3 : 1}
                        register={register("totalMotions", {
                          required: "전체 동작수를 입력해주세요",
                        })}
                      />
                    )}
                  </div>
                  {!readOnly && watch("programType") === "본운동" && (
                    <p
                      className={cls(
                        "",
                        watch("totalMotions") % 3 === 0
                          ? "text-black"
                          : "text-red-500"
                      )}
                    >
                      * 3의 배수로 입력해주세요
                    </p>
                  )}
                </div>
              </FormBox>
            </div>
            <div className="w-1/2">
              <FormBox
                title="전체 세트수"
                required={false}
                icon={<FontAwesomeIcon icon={faStopwatch} />}
              >
                <p className="font-bold">
                  {" "}
                  {watch("programType") === "본운동"
                    ? watch("totalMotions") * 2
                    : watch("totalMotions")}
                </p>
              </FormBox>
            </div>
          </div>
          <FormBox title="동작" icon={<FontAwesomeIcon icon={faPerson} />}>
            <div className="overflow-x-auto">
              <table className="table">
                <tbody>
                  {(watch("programType") !== "본운동"
                    ? zone.slice(0, 1)
                    : zone
                  ).map((z, index) => (
                    <>
                      <tr>
                        <th
                          className={cls(
                            "text-center w-28 ",
                            watch("programType") === "본운동"
                              ? Number(z.zoneData.length) ===
                                Number(watch("totalMotions")) / 3
                                ? "text-green-600"
                                : "text-red-400"
                              : Number(z.zoneData.length) ===
                                Number(watch("totalMotions"))
                              ? "text-green-600"
                              : "text-red-400"
                          )}
                        >
                          {z.title}
                          <br />({z.zoneData.length} /{" "}
                          {watch("programType") === "본운동"
                            ? Math.floor(watch("totalMotions") / 3)
                            : watch("totalMotions")}
                          )
                        </th>
                        <td className="flex flex-wrap min-h-[176px]  ">
                          {z.zoneData.map(
                            (item: ContentsDtoWithId, index: number) => {
                              return (
                                <div className=" flex mb-2">
                                  <ContentsGapDrag
                                    item={item}
                                    index={index}
                                    zoneId={z.id}
                                    moveContent={moveContent}
                                    originalZoneId={originalZoneId}
                                  />
                                  <ContentsBoxWrap
                                    item={item}
                                    zoneId={z.id}
                                    onDelete={onDelete}
                                    onDuplicate={onDuplicate}
                                    readOnly={readOnly}
                                    setOriginalZoneId={setOriginalZoneId}
                                    tutorialCheckbox={
                                      watch("programType") === "본운동"
                                    }
                                    tutorialId={tutorialId}
                                    setTutorialId={setTutorialId}
                                  />
                                  {!readOnly &&
                                    z.zoneData.length - 1 === index && (
                                      <>
                                        <ContentsGapDrag
                                          item={item}
                                          index={index}
                                          zoneId={z.id}
                                          moveContent={moveContent}
                                          originalZoneId={originalZoneId}
                                        />
                                        <div
                                          onClick={() => showModal(z.id)}
                                          className="flex cursor-pointer  rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                                        >
                                          <FontAwesomeIcon icon={faPlus} />
                                        </div>
                                      </>
                                    )}
                                </div>
                              );
                            }
                          )}
                          {z.zoneData.length === 0 && (
                            <div
                              onClick={() => showModal(z.id)}
                              className="flex ml-2 cursor-pointer rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-center w-28 ">{z.title} 도구</th>
                        <td colSpan={2} className="">
                          {z.zoneTools.map((tool: string) => (
                            <div className="btn btn-xs m-1 bg-orange-200">
                              {tool}
                            </div>
                          ))}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </FormBox>

          <div className="flex w-full space-x-2 ">
            <div className="w-1/2">
              <FormBox
                title="SET당시간"
                icon={<FontAwesomeIcon icon={faClock} />}
              >
                <div className="flex items-center space-x-5">
                  <div className="flex items-center whitespace-nowrap space-x-2">
                    <p>운동</p>
                    <div className=" w-20 ">
                      {readOnly ? (
                        watch("exerciseTime")
                      ) : (
                        <FormInput
                          placeholder=""
                          type="number"
                          register={register("exerciseTime", {
                            required: "SET당시간을 입력해주세요",
                          })}
                        />
                      )}
                    </div>
                    <p>초</p>
                  </div>
                  <div className="flex items-center whitespace-nowrap space-x-2">
                    <p>이동</p>
                    <div className=" w-20 ">
                      {readOnly ? (
                        watch("moveTime")
                      ) : (
                        <FormInput
                          placeholder=""
                          type="number"
                          register={register("moveTime", {
                            required: "SET당시간을 입력해주세요",
                          })}
                        />
                      )}
                    </div>
                    <p>초</p>
                  </div>
                </div>
              </FormBox>
            </div>
            <div className="w-1/2">
              <FormBox
                title="총 운동시간"
                required={false}
                icon={<FontAwesomeIcon icon={faClock} />}
              >
                <p className="font-bold">{totalExerciseTime}</p>
              </FormBox>
            </div>
          </div>

          <FormBox
            title="프로그램 설명"
            required={false}
            icon={<FontAwesomeIcon icon={faBook} />}
          >
            {readOnly ? (
              watch("description")
            ) : (
              <FormInput
                placeholder="설명"
                type="textarea"
                register={register("description")}
              />
            )}
          </FormBox>

          <FormBox
            title="수업 코멘트"
            required={false}
            icon={<FontAwesomeIcon icon={faBook} />}
          >
            {readOnly ? (
              watch("comment")
            ) : (
              <FormInput
                placeholder="수업 코멘트"
                type="textarea"
                register={register("comment")}
              />
            )}
          </FormBox>

          {!readOnly && (
            <div className="w-full flex flex-col justify-center mt-5">
              <Button
                loading={loading}
                title={`프로그램  ${programId ? "수정" : "등록"}`}
                onClick={handleSubmit(onValid)}
                iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
