import { ProgramCategory, ProgramType } from "./PrograyCategory";

export const SearchDataForProgram = [
  {
    title: "프로그램 종류",
    value: ProgramType,
    searchKey: "name",
    searchFiled: "programType",
  },
  {
    title: "프로그램 구분",
    value: ProgramCategory,
    searchKey: "name",
    searchFiled: "programCategory",
  },
];
