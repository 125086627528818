import axios from "axios";

export const getScheduleData = async (
  scheduleId: string,
  withCategory = false
) => {
  const { data } = await axios.get(
    `/api/public/branch/schedule/${scheduleId}`,
    {
      params: { withCategory },
    }
  );
  return data;
};

export const getBranchSchedules = async (
  startDate: string,
  endDate: string,
  branchCode: string
) => {
  const response = await axios.get(
    `/api/public/branch/schedule?startDate=${startDate}&endDate=${endDate}&branchCode=${branchCode}`
  );

  return response.data;
};

export const getBranchSchedulesSortTime = async (
  startDate: string,
  endDate: string,
  branchCode: string
) => {
  const response = await axios.get(
    `/api/public/branch/schedule/sort-time?startDate=${startDate}&endDate=${endDate}&branchCode=${branchCode}`
  );

  return response.data;
};
