import { useEffect, useState } from "react";

import moment from "moment";
import axios from "axios";
import { ProgramSchedule } from "../types/dto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import LoadingMsg from "./LoadingMsg";

export default function WeekButton({
  daysOfWeek,
  setDaysOfWeek,
  initStartDate,
}: any) {
  const [currentWeek, setCurrentWeek] = useState(() =>
    moment().startOf("week")
  );

  useEffect(() => {
    if (initStartDate) {
      setCurrentWeek(moment(initStartDate).startOf("week"));
    }
  }, [initStartDate]);

  useEffect(() => {
    const getDay = Array(7)
      .fill(0)
      .map((_, i) => currentWeek.clone().add(i, "days"));

    setDaysOfWeek(getDay);
    // getSchedules(getDay);
  }, [currentWeek]);

  const weekOfMonth =
    currentWeek.clone().week() - currentWeek.clone().startOf("month").week();

  const handlePrevWeek = () => {
    setCurrentWeek(currentWeek.clone().subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setCurrentWeek(currentWeek.clone().add(1, "week"));
  };

  return (
    <>
      <div className="flex justify-center items-center mb-5 space-x-5">
        <div className="cursor-pointer" onClick={handlePrevWeek}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="font-bold text-3xl">
          <p>
            {currentWeek.format("YYYY-MM")} {weekOfMonth}주차
          </p>
          <div className="text-xs flex justify-center space-x-2 mt-2">
            <p>{daysOfWeek && daysOfWeek[0].format("YYYY-MM-DD")}</p>
            <p>~</p>
            <p>
              {daysOfWeek &&
                daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD")}
            </p>
          </div>
        </div>
        <div className="cursor-pointer" onClick={handleNextWeek}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </>
  );
}
