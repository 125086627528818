import { SecondsToMinutes } from "../../libs/utils";
import { Program } from "../../types/models";
import ProgramName from "./ProgramName";
import ProgramSetDuration from "./ProgramSetDuration";

export default function ProgramInfo({
  program,
  type,
}: {
  program: Program;
  type?: string;
}) {
  return (
    <div className="flex items-center justify-between border-b pb-2">
      <ProgramName program={program} type={type} />
      <ProgramSetDuration program={program} type={type} />
    </div>
  );
}
