import { useQuery } from "@tanstack/react-query";
import { getContentsByIds } from "../../services/contents";
import { ContentsWithOnlyVideoDto } from "../../types/dto";
import { BranchStreaming, Program } from "../../types/models";
import { useEffect, useRef, useState } from "react";
import { useBranchStore, useMuteStore } from "../../store";
import BottomProgress from "./BottomProgress";
import { motion } from "framer-motion";
import { count } from "console";
import useUpdateStremingIndex from "../../hooks/useUpdateStremingIndex";
import { useParams } from "react-router-dom";
import CompleteScreen from "./CompleteScreen";
import NonMainScreen from "./NonMainScreen";
import MainScreen from "./MainScreen";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface SubScreenProps {
  programType: string;
  startCounter: number;
}
export default function StartCountdown({
  programType,
  startCounter,
}: SubScreenProps) {
  // useEffect(() => {
  //   if (startCounter > 0) {
  //     const element: any = document.querySelector(".value-bar");
  //     // 애니메이션 클래스 제거
  //     element.classList.remove("animateRotate");

  //     // 강제 리플로우 (브라우저에게 애니메이션 변경을 알리는 트릭)
  //     void element.offsetWidth;

  //     // 애니메이션 클래스 다시 추가
  //     element.classList.add("animateRotate");
  //     element.style.animationDuration = `${10}s`;
  //   }
  // }, [startCounter]);

  return (
    <div className="text-white w-[3840px] h-[2160px] absolute z-[100] flex left-0 top-0  bg-black   min-h-screen justify-center items-center flex-col space-y-44">
      <h1 className="text-9xl font-bold">
        {
          {
            본운동: "곧 수업이 시작됩니다. 배정된 그룹에서 운동을 준비하세요.",
            스트레칭: "본운동이 모두 끝났어요! 스트레칭으로 몸을 정리하세요.",
            마무리운동: "본운동이 모두 끝났어요! 마무리운동을 준비하세요.",
          }[programType]
        }
      </h1>
      <p className="text-white text-[400px] pt-10 font-bold absolute m-auto">
        {startCounter}
      </p>
      <div style={{ width: 1000, height: 1000 }}>
        <svg
          className="circular-progress"
          width="100"
          height="100"
          viewBox="0 0 100 100"
        >
          <circle cx="50" cy="50" r="48" className="bg" />
          <circle cx="50" cy="50" r="48" className="progress" />
        </svg>

        {/* <CircularProgressbar
          value={(startCounter / 10) * 100}
          text={startCounter.toString()}
          counterClockwise={true}
          styles={buildStyles({
            pathTransitionDuration: 0.5,
            // Colors
            pathColor: "#D70051",
            textColor: "white",
            trailColor: "#d6d6d6",
            backgroundColor: "#D70051",
          })}
        /> */}
      </div>
    </div>
  );
}
