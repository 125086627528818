import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faCircleXmark,
  faGripVertical,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import ContentsBox from "./ContentsBox";
import { useDrag } from "react-dnd";
import { AdsDtoWithVideo, ContentsDtoWithId } from "../types/dto";
import { DragItem } from "../types/dnd";
import { BranchGroup } from "../types/models";

interface AdsBoxDragProps {
  item: any;
}

const BranchGroupNameDrag = ({ item }: AdsBoxDragProps) => {
  const [{ isDragging }, drag]: DragItem = useDrag({
    type: "NAME",
    item,
    collect: (monitor) => {
      const isDragging = monitor.isDragging();
      if (isDragging) {
        console.log("Drag has started!");
      }
      return { isDragging };
    },
  });

  const dragDropRef = (node: HTMLElement | null) => {
    drag(node);
  };

  return (
    <div
      ref={dragDropRef}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="px-2"
    >
      <div className="flex space-x-2 items-center ">
        <FontAwesomeIcon
          icon={faGripVertical}
          className=" opacity-25 hover:opacity-50 cursor-pointer z-40 "
        />
        <p className="font-bold">{item && item.name}</p>
        <p>{item && `(${item.phone})`}</p>
      </div>
    </div>
  );
};

export default BranchGroupNameDrag;
