import axios from "axios";

export const uploadVideoToVimeo = async (
  fileSize: string,
  videoName: string,
  videoFile: File
) => {
  try {
    //비메오에서 업로드 링크 가져오기
    const { data } = await axios.post(
      "/api/admin/video",
      {
        name: videoName,
        fileSize: fileSize,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    const { uploadLink, videoId } = data;

    // 비메오에 비디오 업로드
    const videoUploadResponse = await axios.patch(uploadLink, videoFile, {
      headers: {
        "Tus-Resumable": "1.0.0",
        "Upload-Offset": "0",
        "Content-Type": "application/offset+octet-stream",
      },
    });

    if (videoUploadResponse.status !== 204) {
      throw new Error("Upload to Vimeo failed");
    }

    console.log(`Video upload to Vimeo was successful. Video ID is ${videoId}`);

    return videoId;
  } catch (error: any) {
    throw new Error("Upload to Vimeo failed");
  }
};

export const uploadVideoWithVimeoUrl = async (videoCode: string) => {
  try {
    //비메오에서 업로드 링크 가져오기
    const { data } = await axios.post(
      "/api/admin/video/uploadWithVimeoUrl",
      {
        vimeoUrl: `/videos/${videoCode}`,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    const { videoId } = data;

    console.log(`Video upload to Vimeo was successful. Video ID is ${videoId}`);

    return videoId;
  } catch (error: any) {
    throw new Error("Upload to Vimeo failed");
  }
};
