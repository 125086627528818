import AdminLayout from "../../components/Layout/AdminLayout";

import "moment/locale/ko";

import ScheduleCalender from "../../components/ScheduleCalender";

export default function BranchProgram() {
  return (
    <AdminLayout>
      <div className="">
        <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
          일별 프로그램
        </h1>
        <ScheduleCalender readonly={true} />
      </div>
    </AdminLayout>
  );
}
