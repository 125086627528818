import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faClone,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faL,
  faLocation,
  faSign,
  faTriangleExclamation,
  faUpRightAndDownLeftFromCenter,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { cls } from "../libs/utils";
import { useRef, useState } from "react";

import { ContentsDto } from "../types/dto";
interface ContentsBoxProps {
  item: ContentsDto;
  handleCheck?: (item: ContentsDto) => void;
  checkedItems?: ContentsDto[];
  hover?: boolean;
  deleteMode?: boolean;
  onDelete?: any;
  extend?: boolean;
  onExtend?: (item: ContentsDto) => void;
  duplicate?: boolean;
  onDuplicate?: any;
  registrationCount?: boolean;
  tutorialCheckbox?: boolean;
  tutorialId?: number[];
  setTutorialId?: any;
  readOnly?: boolean;
}
export default function ContentsBox({
  item,
  handleCheck,
  checkedItems,
  hover = true,
  deleteMode = false,
  onDelete,
  extend = false,
  onExtend,
  duplicate = false,
  onDuplicate,
  registrationCount = false,
  tutorialCheckbox = false,
  tutorialId,
  setTutorialId,
  readOnly = false,
}: ContentsBoxProps) {
  const videoRef: any = useRef();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (videoRef.current) {
      const promise = videoRef.current.play();

      if (promise !== undefined) {
        promise
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {});
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!tutorialId || !setTutorialId) {
      return;
    }
    const isChecked = e.target.checked;

    if (isChecked) {
      // 체크된 경우 배열에 id 추가하기
      if (!tutorialId.includes(item.content.id)) {
        setTutorialId((prevIds: number[]) => [...prevIds, item.content.id]);
      }
    } else {
      // 체크 해제된 경우 배열에서 id 제거하기
      setTutorialId((prevIds: number[]) =>
        prevIds.filter((id) => id !== item.content.id)
      );
    }
  };

  return (
    <>
      <div
        onClick={() => (handleCheck ? handleCheck(item) : null)}
        className={cls(
          "relative w-40 h-36  border rounded-md shadow-md bg-base-100 overflow-hidden",
          hover ? "cursor-pointer" : ""
        )}
      >
        <div className="">
          <div className="relative">
            {checkedItems && handleCheck && (
              <input
                type="checkbox"
                className="checkbox checkbox-xs absolute z-50 m-2"
                checked={checkedItems.includes(item)}
                onChange={() => handleCheck(item)}
              />
            )}
            {extend && onExtend && (
              <FontAwesomeIcon
                icon={faUpRightAndDownLeftFromCenter}
                className="absolute m-2 z-50 right-0 opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
                width={10}
                onClick={() => onExtend(item)}
              />
            )}

            {deleteMode && (
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="absolute m-2 z-50 right-0 opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
                width={10}
                onClick={onDelete}
              />
            )}
            {duplicate && (
              <FontAwesomeIcon
                icon={faClone}
                width={10}
                onClick={onDuplicate}
                className="absolute m-2 z-50 right-0 top-5 opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
              />
            )}
            <div
              className="border-b w-full z-0 h-[88.88px]  relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {item.video && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={item.video.thumbnail || ""}
                  className={`absolute inset-0 m-auto ${
                    isHovered ? "hidden" : ""
                  } h-full`}
                />
              )}
              {item.video && (
                <video
                  preload="none"
                  ref={videoRef}
                  src={item.video.fileLink || ""}
                  className={`absolute inset-0 m-auto ${
                    isHovered ? "" : "hidden"
                  } h-full`}
                  controls={false}
                  muted
                />
              )}
            </div>
          </div>
          <div className="flex flex-col p-4">
            <div className="flex items-center  justify-between w-full">
              <h2 className="text-xs truncate ">{item.content.name}</h2>
              {registrationCount && (
                <p className="text-xs ml-1 ">
                  ({item.content.registrationCount})
                </p>
              )}
              {tutorialCheckbox && (
                <div className="form-control z-50 ">
                  <label className="cursor-pointer space-x-1 flex">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-xs"
                      onChange={handleCheckboxChange}
                      checked={
                        tutorialId
                          ? tutorialId.includes(item.content.id)
                          : false
                      }
                      disabled={readOnly ? true : false}
                    />
                    <span className="label-text text-xs truncate ">설명</span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
