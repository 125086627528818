import AccountTable from "../../components/AccountTable";

export default function AdminHome() {
  return (
    <>
      <AccountTable
        apiUrl="/api/admin/users"
        newPasswordApiUrl="/api/admin/users/changePassword"
      />
    </>
  );
}
