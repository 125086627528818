import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCirclePlus,
  faClock,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { useForm } from "react-hook-form";
import Button from "../interface/Button";
import { BranchSchedule } from "../../types/models";
import useMutation from "../../hooks/useMutation";

interface BranchAddTimeModalForm {
  id: number;
  startTime: string;
  duration: number;
  headcount: number;
}

interface BranchAddTimeModalProps {
  day: string;
  templateId: string;
  refetch: any;
  isOpen: boolean;
  selectData?: any;
}

export default function TemplateAddTimeModal({
  day,
  templateId,
  refetch,
  isOpen,
  selectData,
}: BranchAddTimeModalProps) {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<number>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<BranchAddTimeModalForm>();

  // 모달이 열리거나 닫힐 때마다 폼을 초기화
  useEffect(() => {
    reset();
    setId(undefined);
    setTimeout(() => {
      if (selectData) {
        setId(selectData.id);
        setValue("startTime", selectData.startTime);
        setValue("duration", selectData.duration);
        setValue("headcount", selectData.headcount);
      }
    }, 10); // 0ms 후에 콜백 함수를 실행합니다.
  }, [isOpen]);

  const [
    createTemplate,
    { loading: createTemplateLoading, data: createTemplateData },
  ] = useMutation("/api/branch/templateDetail", "post");

  const [
    updateTemplate,
    { loading: updateTemplateLoading, data: updateTemplateData },
  ] = useMutation("/api/branch/templateDetail", "put");

  const onValid = () => {
    if (id) {
      updateTemplate(
        {
          scheduleTemplateId: Number(templateId),
          day: day,
          startTime: watch("startTime"),
          duration: watch("duration"),
          headcount: watch("headcount"),
        },
        id
      );
    } else {
      createTemplate({
        scheduleTemplateId: Number(templateId),
        day: day,
        startTime: watch("startTime"),
        duration: watch("duration"),
        headcount: watch("headcount"),
      });
    }
  };

  useEffect(() => {
    if (createTemplateData?.ok || updateTemplateData?.ok) {
      refetch();
      //@ts-ignore
      window.addModal?.close();
    }
  }, [createTemplateData, updateTemplateData]);

  return (
    <Modal
      id="addModal"
      title={`개별 일정 ${id ? "수정" : "등록"} - ${day}요일 `}
    >
      <div className="mt-5">
        <FormBox
          title="수업 시작시간"
          icon={<FontAwesomeIcon icon={faClock} />}
        >
          <input
            type="time"
            className="input input-bordered w-full"
            {...register("startTime", { required: true })}
          ></input>
        </FormBox>
        <FormBox title="수업시간" icon={<FontAwesomeIcon icon={faClock} />}>
          <div className="flex items-center space-x-5">
            <FormInput
              placeholder=""
              type="number"
              register={register("duration", {
                required: "수업 시간을 입력해주세요",
              })}
            />
            <p>분</p>
          </div>
        </FormBox>
        <FormBox title="수업인원" icon={<FontAwesomeIcon icon={faClock} />}>
          <div className="flex items-center space-x-5">
            <FormInput
              placeholder=""
              type="number"
              register={register("headcount", {
                required: "수업 인원을 입력해주세요",
              })}
            />
            <p>명</p>
          </div>
        </FormBox>
        <Button
          loading={createTemplateLoading || updateTemplateLoading}
          title={`시간  ${id ? "수정" : "등록"}`}
          onClick={handleSubmit(onValid)}
          iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
          disabled={isValid ? false : true}
        />
      </div>
    </Modal>
  );
}
