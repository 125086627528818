export function handleAxiosError(error: any) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    if (error.response.data.message) {
      alert(error.response.data.message);
    } else {
      alert(error.response.data.error);
    }
  } else if (error.request) {
    // The request was made but no response was received
    alert("No response was received from the server.");
  } else {
    // Something happened in setting up the request that triggered an Error
    alert("An error occurred while setting up the request.");
  }
}
