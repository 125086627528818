import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import LoadingMsg from "../../components/LoadingMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCirclePlus,
  faClock,
  faCopy,
  faFolder,
  faFolderOpen,
  faGear,
  faHistory,
  faPenToSquare,
  faPlus,
  faSave,
  faTag,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  GetHHMM,
  cls,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../../libs/utils";
import axios from "axios";
import { ProgramSchedule } from "../../types/dto";
import WeekButton from "../../components/WeekButton";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import BranchAddTimeModal from "../../components/Modal/BranchAddTimeModal";
import { useNavigate, useParams } from "react-router-dom";
import { BranchSchedule } from "../../types/models";
import ScheduleSettingsModal from "../../components/Modal/ScheduleSettingsModal";
import BranchScheduleBox from "../../components/BranchScheduleBox";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getBranchSchedules } from "../../services/branchSchedule";
import Button from "../../components/interface/Button";
import { getProgramCategoryByDateRange } from "../../services/schedule";
import ScheduleTemplateModal from "../../components/Modal/ScheduleTemplateModal";
import useMutation from "../../hooks/useMutation";
import { useForm } from "react-hook-form";

import { subWeeks, format } from "date-fns";
import { getBranchData } from "../../services/branch";

export interface BranchForm {
  id: number;
  title: string;
  description: string;
  scheduleAutoCopy: boolean;
}

export default function BranchScheduleHome() {
  const navigate = useNavigate();

  const { branchCode } = useParams();
  const [daysOfWeek, setDaysOfWeek] = useState<any>();
  const [mutate, setMutate] = useState(false);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const [addModalDate, setAddModalDate] = useState<string>("");

  const [addModalIsOpen, setIsModalIsOpen] = useState(false);
  const [scheduleSettingslIsOpen, setScheduleSettingsIsOpen] = useState(false);

  const [dropdown, setDropdown] = useState({
    show: false,
    x: 0,
    y: 0,
  });
  const [selectData, setSelectData] = useState<BranchSchedule>();
  const [scheduleLoading, setSheduleLoading] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (daysOfWeek) {
      setStartDate(daysOfWeek[0].format("YYYY-MM-DD"));
      setEndDate(daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD"));
    }
  }, [daysOfWeek]);

  const {
    data: scheduleByDay,
    isLoading,
    isError,
    refetch,
  } = useQuery<any[], Error>(
    ["getBranchSchedules", startDate, endDate, branchCode],
    () => getBranchSchedules(startDate!, endDate!, branchCode!),
    {
      enabled: !!startDate && !!endDate && !!branchCode,
    }
  );

  const { data: branchData, refetch: branchDataRefetch } = useQuery(
    ["getBranchData", branchCode],
    () => getBranchData(branchCode!),
    {
      enabled: !!branchCode,
    }
  );

  console.log(branchData);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<BranchForm>();

  useEffect(() => {
    if (branchData?.ok) {
      setValue("scheduleAutoCopy", branchData?.data?.scheduleAutoCopy);
    }
  }, [branchData]);

  const { data: programByDay, isLoading: programByDayLoading } = useQuery<
    any[],
    Error
  >(
    ["getProgramCategoryByDateRanges", startDate, endDate],
    () => getProgramCategoryByDateRange(startDate!, endDate!),
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const addTime = async (date: string) => {
    setAddModalDate(date);
    showModal();
  };

  //모달 열기
  const showModal = () => {
    setSelectData(undefined);
    //@ts-ignore
    window.addModal.showModal();
    setIsModalIsOpen(!addModalIsOpen);
  };

  const showEditModal = () => {
    //@ts-ignore
    window.addModal.showModal();
    setIsModalIsOpen(!addModalIsOpen);
  };

  const onDelete = async () => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result || !selectData) {
      return;
    }

    setSheduleLoading([...scheduleLoading, selectData.id]);

    try {
      const response = await axios.delete(
        `/api/branch/schedule/${selectData.id}`
      );
      refetch();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }

    const newLoadingArray = scheduleLoading.filter(
      (item: number) => item !== selectData.id
    );

    setSheduleLoading(newLoadingArray);

    setDropdown({
      show: false,
      x: 0,
      y: 0,
    });
  };

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        dropdown.show &&
        !e.target.closest(".dropdown-content") &&
        !e.target.closest(".menu")
      ) {
        setDropdown({
          show: false,
          x: 0,
          y: 0,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const handleProgramClick = (e: any, selData: BranchSchedule) => {
    e.preventDefault();
    setDropdown({
      show: true,
      x: e.clientX,
      y: e.clientY,
    });

    setSelectData(selData);
    const dateTimeString = selData.startTime;
    const [date] = dateTimeString.split("T");
    setAddModalDate(date);
  };

  const onScheduleSettingsModal = () => {
    //@ts-ignore
    window.scheduleSettingsModal.showModal();
    setScheduleSettingsIsOpen(!scheduleSettingslIsOpen);
  };

  const onAssignUser = () => {
    navigate(`/branch/${branchCode}/assign/${selectData?.id}`);
  };

  console.log(startDate);
  console.log(endDate);
  const handleLoadButton = () => {
    //@ts-ignore
    window.templateModal.showModal();
  };

  const handleSaveButton = () => {
    //@ts-ignore
    window.newTp.showModal();
  };

  const [
    loadTemplate,
    { loading: loadTemplateLoading, data: loadTemplateData },
  ] = useMutation("/api/branch/template/load", "post", true);

  const runLoadTemplate = (templateId: number) => {
    console.log(templateId);
    loadTemplate({
      templateId: templateId,
      branchCode: branchCode,
      startDate: startDate,
    });
    //@ts-ignore
    window.templateModal.close();
  };

  const [
    saveTemplate,
    { loading: saveTemplateLoading, data: saveTemplateData },
  ] = useMutation("/api/branch/template/save", "post", true);

  const runSaveTemplate = (formData: BranchForm) => {
    saveTemplate({
      startDate: startDate,
      branchCode: branchCode,
      templateTitle: formData.title,
      templateDescription: formData.description,
    });

    //@ts-ignore
    window.newTp.close();
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    if (loadTemplateData?.ok) {
      refetch();
    }
  }, [loadTemplateData]);

  useEffect(() => {
    if (saveTemplateData?.ok) {
      queryClient.refetchQueries(["getTemplate"]);
      refetch();
    }
  }, [saveTemplateData]);

  const [
    copySchedule,
    { loading: copyScheduleLoading, data: copyScheduleData },
  ] = useMutation("  /api/branch/schedule/copy", "post", true);

  const runCopySchedule = () => {
    if (!startDate) {
      return;
    }
    var result = window.confirm(
      "정말 지난주 스케줄을 현재주로 복사하시겠어요?"
    );

    if (!result) {
      return;
    }

    const pasteDate = new Date(startDate); // 'startDate'는 "2024-01-28"과 같은 형식의 문자열
    const copyDate = subWeeks(pasteDate, 1); // pasteDate에서 일주일을 뺌

    copySchedule({
      branchCode: branchCode,
      copyDate: format(copyDate, "yyyy-MM-dd"), // '2024-01-21' 형식의 문자열로 변환
      pasteDate: startDate,
    });
  };

  useEffect(() => {
    if (copyScheduleData?.ok) {
      refetch();
    }
  }, [copyScheduleData]);

  const handleSettingsModal = () => {
    //@ts-ignore
    window.setting.showModal();
  };

  const [
    updateSettings,
    { loading: updateSettingsLoading, data: updateSettingsData },
  ] = useMutation("/api/branch/settings", "put", true);

  const runUpdateSettings = () => {
    updateSettings({
      branchCode: branchCode,
      scheduleAutoCopy: watch("scheduleAutoCopy"),
    });
  };

  useEffect(() => {
    if (updateSettingsData?.ok) {
      branchDataRefetch();
    }
  }, [updateSettingsData]);

  return (
    <AdminLayout>
      <Modal id="setting" title={`시간표 환경설정`} overflow="hidden">
        <div>
          <FormBox
            title="매주 자동복사"
            icon={<FontAwesomeIcon icon={faTag} />}
            toolTip={`매주 일요일 자정에 지난주 시간표를 자동 복사합니다.\n이미 존재하는 일정이 있을경우, 초기화 후 복사됩니다.`}
          >
            <input
              type="checkbox"
              className="toggle toggle-info"
              {...register("scheduleAutoCopy")}
            />
          </FormBox>
        </div>
        <div className="flex flex-col">
          <Button
            loading={updateSettingsLoading}
            title={`적용`}
            onClick={runUpdateSettings}
            iconComponent={<FontAwesomeIcon icon={faCheckCircle} />}
          />
        </div>
      </Modal>
      <Modal
        id="newTp"
        title={`현재 시간표를 템플릿으로 ${watch("id") ? "수정" : "등록"}`}
        size="large"
        overflow="hidden"
      >
        <div>
          <FormBox
            title="제목"
            required={true}
            icon={<FontAwesomeIcon icon={faTag} />}
          >
            <FormInput
              maxLength={10}
              placeholder="제목"
              register={register("title", {
                required: true,
              })}
            />
          </FormBox>
          <FormBox
            title="설명"
            required={true}
            icon={<FontAwesomeIcon icon={faTag} />}
          >
            <FormInput
              maxLength={20}
              placeholder="설명"
              register={register("description", {
                required: true,
              })}
            />
          </FormBox>
        </div>
        <div className="flex flex-col">
          <Button
            loading={saveTemplateLoading}
            title={`${watch("id") ? "수정" : "등록"}`}
            onClick={handleSubmit(runSaveTemplate)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={isValid ? false : true}
          />
        </div>
      </Modal>
      <Modal
        id={"templateModal"}
        title={`템플릿 불러오기`}
        size="xlarge"
        overflow="hidden"
      >
        <div>
          <ScheduleTemplateModal loadTempalte={runLoadTemplate} />
        </div>
      </Modal>
      <div className="w-full justify-between flex items-center mb-5">
        <h1 className="text-gray-800 font-bold text-center  md:text-left text-2xl">
          예약자 그룹 배정 / 시간표 관리
        </h1>
        <div className="mt-2 gap-2 flex">
          <button
            className=" btn "
            onClick={() => navigate(`/branch/${branchCode}/assign/log`)}
          >
            <FontAwesomeIcon icon={faHistory} />
          </button>
          <button className=" btn " onClick={handleSettingsModal}>
            <FontAwesomeIcon icon={faGear} />
          </button>
        </div>
      </div>
      <>
        <BranchAddTimeModal
          date={addModalDate}
          isOpen={addModalIsOpen}
          refetch={refetch}
          branchCode={branchCode!}
          selectData={selectData}
        />
        {daysOfWeek && daysOfWeek.length === 7 && (
          <ScheduleSettingsModal
            branchCode={branchCode!}
            initStartDate={daysOfWeek[0].format("YYYY-MM-DD")}
            initEndDate={daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD")}
            isOpen={scheduleSettingslIsOpen}
            refetch={refetch}
            setLoading={setLoading}
          />
        )}
        <div className="">
          <div className="w-full bg-white drop-shadow-md relative">
            {(loading || loadTemplateLoading || copyScheduleLoading) && (
              <div className="flex items-center justify-center h-full absolute z-20 bg-black w-full bg-opacity-20 ">
                <span className="loading loading-spinner loading-lg text-white"></span>
              </div>
            )}
            <div className="flex flex-col justify-center px-5 py-10 ">
              <div className="relative h-20 flex justify-center">
                <div className="flex mx-auto absolute">
                  <WeekButton
                    daysOfWeek={daysOfWeek}
                    setDaysOfWeek={setDaysOfWeek}
                  />
                </div>
                <div className="hidden lg:flex w-fit items-center gap-3 absolute right-0 ">
                  <button className=" btn mt-2 " onClick={handleSaveButton}>
                    <FontAwesomeIcon icon={faSave} />
                  </button>
                  <button className=" btn mt-2 " onClick={handleLoadButton}>
                    <FontAwesomeIcon icon={faFolderOpen} />
                  </button>
                  <button className=" btn mt-2 " onClick={runCopySchedule}>
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                  <Button
                    title={`일괄 변경`}
                    onClick={onScheduleSettingsModal}
                    iconComponent={<FontAwesomeIcon icon={faGear} />}
                    disabled={!daysOfWeek || daysOfWeek.length !== 7}
                  />
                </div>
              </div>
              <BranchScheduleBox
                daysOfWeek={daysOfWeek}
                scheduleByDay={scheduleByDay}
                handleProgramClick={handleProgramClick}
                addTime={addTime}
                programByDay={programByDay}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>

        {dropdown.show && (
          <div style={{ position: "fixed", top: dropdown.y, left: dropdown.x }}>
            <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box text-xs ">
              <li onClick={onAssignUser}>
                <a>
                  <FontAwesomeIcon icon={faUserGroup} />
                  그룹배정
                </a>
              </li>
              <li onClick={showEditModal}>
                <a>
                  <FontAwesomeIcon icon={faPenToSquare} />
                  수정
                </a>
              </li>
              <li onClick={onDelete}>
                <a>
                  <FontAwesomeIcon icon={faTrash} /> 삭제
                </a>
              </li>
            </ul>
          </div>
        )}
      </>
    </AdminLayout>
  );
}
