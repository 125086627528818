interface TableProps {
  rowSize: number;
  headerSize: number;
}

export default function TableSkeleton({ rowSize, headerSize }: TableProps) {
  return (
    <>
      {Array.from({ length: rowSize }).map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: headerSize }).map((_, cellIndex) => (
            <td key={cellIndex} className="h-[65px]">
              <div className="skeleton-box rounded-lg">&nbsp;</div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
