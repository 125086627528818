import { useState } from "react";
import axios from "axios";
import { handleAxiosError } from "../utils/handleAxiosError";

interface UseMutationState<T> {
  loading: boolean;
  data?: T;
  error?: object;
}
type UseMutationResult<T> = [
  (data: any, id?: number) => Promise<void>,
  UseMutationState<T>
];

export default function useMutation<T = any>(
  url: string,
  method: "post" | "put" | "delete" = "post",
  errorAlert: boolean = false
): UseMutationResult<T> {
  const [state, setState] = useState<UseMutationState<T>>({
    loading: false,
    data: undefined,
    error: undefined,
  });

  async function mutation(data: any, id?: number) {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const apiUrl = id ? `${url}/${id}` : url;
      const response = await axios[method](
        apiUrl,
        { ...data },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setState((prev) => ({ ...prev, data: response.data, loading: false }));
    } catch (error: any) {
      if (errorAlert) {
        handleAxiosError(error);
      }
      setState((prev) => ({ ...prev, error: error as object, loading: false }));
    }
  }

  return [mutation, { ...state }];
}
