import axios from "axios";

export const getSchedules = async (startDate: string, endDate: string) => {
  const response = await axios.get("/api/public/schedule", {
    params: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  return response.data;
};

export const getProgramCategoryByDateRange = async (
  startDate: string,
  endDate: string
) => {
  const response = await axios.get(
    `/api/public/schedule/programCategory?startDate=${startDate}&endDate=${endDate}`
  );

  return response.data;
};

export const getSchedulesToday = async () => {
  const response = await axios.get("/api/public/schedule/today");

  return response.data;
};

export const getSchedulesDescriptionToday = async () => {
  const response = await axios.get("/api/public/program/today");

  return response.data;
};
