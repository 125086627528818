import moment from "moment-timezone";

export function cls(...classnames: string[]) {
  return classnames.join(" ");
}

interface DateStringProps {
  date: Date;
}

interface StringToDateProps {
  dateString: string;
}

export function DateString({ date }: DateStringProps) {
  const createdAtPostTime = new Date(date);
  // const krTime = new Date(createdAtPostTime.getTime() + 9 * 60 * 60 * 1000);
  console.log(createdAtPostTime);

  moment.locale("ko"); // 한국어 설정
  const today = moment().tz("Asia/Seoul");

  const diffMSec = createdAtPostTime.getTime() - today.toDate().getTime();
  const diffMin = (diffMSec / (60 * 1000)) * -1;
  const diffHour = (diffMSec / (60 * 60 * 1000)) * -1;
  const diffDate = (diffMSec / (24 * 60 * 60 * 1000)) * -1;

  return (
    <>
      {Math.round(diffMin) < 60
        ? Math.round(diffMin) + "분 전"
        : Math.round(diffMin) < 1440
        ? Math.round(diffHour) + "시간 전"
        : Math.round(diffDate) + "일 전"}
    </>
  );
}

export function StringToDate({ dateString }: StringToDateProps) {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");
  const seconds = String(dateObject.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day}`;

  return <>{formattedDateTime}</>;
}

export function SecondsToMinutes({ seconds }: { seconds: number }) {
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return (
    <>
      {formattedMinutes}:{formattedSeconds}
    </>
  );
}

export const getDayStringClassNames = (day: string) =>
  cls(day === "일" ? "text-red-400" : day === "토" ? "text-blue-400" : "");

export const getDayClassNames = (day: any) =>
  cls(
    day.format("ddd") === "일"
      ? "text-red-400"
      : day.format("ddd") === "토"
      ? "text-blue-400"
      : ""
  );

// 날짜에 따라서 div 박스의 배경색 클래스를 반환하는 함수
export const getBoxBackgroundClass = (day: any) =>
  cls(
    moment().isSame(day, "day") // 오늘 날짜인지 확인
      ? "bg-[#ADD9F5]"
      : moment().isAfter(day, "day") // 과거 날짜인지 확인
      ? "bg-gray-300"
      : "bg-gray-50" // 기본 배경색
  );

export const GetHHMM = ({ dateTimeString }: { dateTimeString: string }) => {
  let date = new Date(dateTimeString);

  let hours = date.getHours(); // get hours
  let minutes = date.getMinutes(); // get minutes

  // pad start is used to add leading zero if hours or minutes is less than 10.
  let time = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return <span>{time}</span>;
};

export const convertToHHMM = (timeString: string) => {
  if (!timeString) {
    return "";
  }
  const timeParts = timeString.split(":"); // "23:00:00"을 ':' 기준으로 분리
  return `${timeParts[0]}:${timeParts[1]}`; // 시간과 분만 반환
};

export function formatDateString(dateString: string) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
