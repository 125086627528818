import { useForm } from "react-hook-form";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faDumbbell,
  faHouse,
  faPersonBiking,
  faSquarePollVertical,
  faTv,
  faUser,
  faPersonWalking,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { cls } from "../../libs/utils";
import { useStore } from "zustand";
import { useBranchStore, useMuteStore, useUserStore } from "../../store";
import { adminMenu, branchMenu } from "../../constants/AdminMenu";
import { Branch } from "../../types/models";
import { BranchDto } from "../../types/dto";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode, fetchBranches } from "../../services/branch";

export default function AdminMenu({ menu, currentPath }: any) {
  const onLogout = async () => {
    try {
      await axios.post("/api/logout", { withCredentials: true });
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <nav className="w-[257px] min-w-[257px] min-h-screen flex-col items-center bg-white p-5 hidden md:flex">
      <img
        src="/main-logo.svg"
        className=" w-36 h-36  object-contain"
        alt="logo"
      />
      <ul className="w-full text-sm font-bold flex flex-col">
        {menu &&
          menu.map((item: any, index: number) => (
            <Link to={item.url} key={index} className="w-full">
              <li className="h-10 cursor-pointer hover:bg-gray-50 border-gray-200 border-t w-full flex items-center space-x-2 ">
                <div
                  className={cls(
                    "h-full w-1 bg-gymmate",

                    currentPath === item.url ? "opacity-100" : "opacity-0"
                  )}
                ></div>
                <div className="w-5 flex justify-center">
                  <FontAwesomeIcon
                    icon={item.icon}
                    color={currentPath === item.url ? "#2ea7e0" : "#666577"}
                  />
                </div>
                <p
                  className={cls(
                    "",
                    currentPath === item.url ? "text-gymmate" : "text-[#666577]"
                  )}
                >
                  {item.title}
                </p>
              </li>
            </Link>
          ))}
      </ul>
    </nav>
  );
}
