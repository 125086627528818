import { useEffect, useState, useRef } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { v4 as uuidv4 } from "uuid";
import useWebSocketStore from "../store/wsStore";

export const useWebSocket = (url: string, isJson: boolean = true) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string>();
  const { clients, setClient } = useWebSocketStore();

  const shouldReconnect = useRef(true); // 연결을 유지하려는지의 여부를 판단하는 ref

  useEffect(() => {
    if (!url) return;
    if (url.includes("undefined")) return;

    // URL에 해당하는 현재 클라이언트를 가져온다.
    const currentClient = clients[url];

    // 이미 연결된 WebSocket이 있는지 확인
    if (currentClient && currentClient.readyState === W3CWebSocket.OPEN) {
      return; // 이미 연결이 있으면 새 연결을 생성하지 않음
    }

    const connect = () => {
      const newClient = new W3CWebSocket(url);

      setClient(url, newClient);

      newClient.onopen = () => {
        console.log(`WebSocket Client Connected : ${url}`);
        setError(undefined); // Reset the error state when successfully connected
      };

      newClient.onmessage = (message: any) => {
        if (isJson) {
          const newData = JSON.parse(message.data);
          setData(newData);
        } else {
          setData(message.data + uuidv4());
        }
      };

      newClient.onerror = (error: any) => {
        console.error("WebSocket error:", error);
        setError("Failed to connect to the server. Please try again later.");
      };

      newClient.onclose = (event) => {
        // 연결이 끊어진 경우 재연결 시도
        if (shouldReconnect.current) {
          console.log("WebSocket closed, retrying...");
          setTimeout(connect, 5000); // 5초 후 재연결 시도
        }
      };
    };

    connect();

    return () => {
      shouldReconnect.current = false;
      if (currentClient) {
        currentClient.close();
      }
    };
  }, [url]);

  return [data, error, clients[url]]; // Return both data and error
};
