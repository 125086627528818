import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCirclePlus,
  faClock,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { useForm } from "react-hook-form";
import Button from "../interface/Button";
import { BranchSchedule } from "../../types/models";

interface BranchAddTimeModalForm {
  id: number;
  startTime: string;
  duration: number;
  headcount: number;
}

interface BranchAddTimeModalProps {
  date: string;
  isOpen: boolean;
  refetch: any;
  branchCode: string;
  selectData?: BranchSchedule;
}

export default function BranchAddTimeModal({
  date,
  isOpen,
  branchCode,
  selectData,
  refetch,
}: BranchAddTimeModalProps) {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<number>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<BranchAddTimeModalForm>();

  // 모달이 열리거나 닫힐 때마다 폼을 초기화
  useEffect(() => {
    reset();
    setId(undefined);
    setTimeout(() => {
      if (selectData) {
        const dateTimeString = selectData.startTime;
        const [date, time] = dateTimeString.split("T");

        // '2023-07-09'와 '00:00:00'에 대한 두 변수가 생성됩니다.

        // HH:mm 형식으로 시간을 얻기 위해서는 추가 작업이 필요합니다.
        const [hour, minute] = time.split(":");
        const formattedTime = `${hour}:${minute}`; // '00:00'
        setId(selectData.id);

        setValue("startTime", formattedTime);
        setValue("duration", selectData.duration);
        setValue("headcount", selectData.headcount);
      }
    }, 10); // 0ms 후에 콜백 함수를 실행합니다.
  }, [isOpen]);

  const onValid = async (formData: BranchAddTimeModalForm) => {
    setLoading(true);
    try {
      if (id) {
        await axios.patch(
          `/api/branch/schedule/${id}`,
          {
            date: date,
            startTime: formData.startTime,
            duration: formData.duration,
            headcount: formData.headcount,
            branchCode: branchCode,
          },
          {
            withCredentials: true,
          }
        );
      } else {
        await axios.post(
          `/api/branch/schedule`,
          {
            date: date,
            startTime: formData.startTime,
            duration: formData.duration,
            headcount: formData.headcount,
            branchCode: branchCode,
          },
          {
            withCredentials: true,
          }
        );
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      } else {
        alert(`서버 오류 발생 : ${error.message} `);
      }
    }
    setLoading(false);
    //@ts-ignore
    window.addModal.close();
    refetch();
  };

  return (
    <Modal id="addModal" title={`개별 일정 ${id ? "수정" : "등록"} (${date})`}>
      <div className="mt-5">
        <FormBox
          title="수업 시작시간"
          icon={<FontAwesomeIcon icon={faClock} />}
        >
          <input
            type="time"
            className="input input-bordered w-full"
            {...register("startTime", { required: true })}
          ></input>
        </FormBox>
        <FormBox title="수업시간" icon={<FontAwesomeIcon icon={faClock} />}>
          <div className="flex items-center space-x-5">
            <FormInput
              placeholder=""
              type="number"
              register={register("duration", {
                required: "수업 시간을 입력해주세요",
              })}
            />
            <p>분</p>
          </div>
        </FormBox>
        <FormBox title="수업인원" icon={<FontAwesomeIcon icon={faClock} />}>
          <div className="flex items-center space-x-5">
            <FormInput
              placeholder=""
              type="number"
              register={register("headcount", {
                required: "수업 인원을 입력해주세요",
              })}
            />
            <p>명</p>
          </div>
        </FormBox>
        <Button
          loading={loading}
          title={`시간  ${id ? "수정" : "등록"}`}
          onClick={handleSubmit(onValid)}
          iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
          disabled={isValid ? false : true}
        />
      </div>
    </Modal>
  );
}
