import AdminLayout from "../components/Layout/AdminLayout";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faGear,
  faPenToSquare,
  faPlus,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

import {
  GetHHMM,
  cls,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../libs/utils";
import axios from "axios";
import WeekButton from "../components/WeekButton";
import BranchAddTimeModal from "../components/Modal/BranchAddTimeModal";
import { useNavigate, useParams } from "react-router-dom";
import { BranchSchedule } from "../types/models";
import ScheduleSettingsModal from "../components/Modal/ScheduleSettingsModal";
import { useQuery } from "@tanstack/react-query";
import {
  getProgramCategoryByDateRange,
  getSchedulesDescriptionToday,
  getSchedulesToday,
} from "../services/schedule";

interface BranchScheduleBoxProps {
  daysOfWeek: any;
  scheduleByDay: any;
  handleProgramClick?: any;
  addTime?: any;
  programByDay: any;
  size?: string;
  isLoading?: boolean;
  storeBranchName: string;
}

export default function BranchScheduleBoxForAds({
  daysOfWeek,
  scheduleByDay,
  handleProgramClick,
  addTime,
  programByDay,
  size,
  isLoading,
  storeBranchName,
}: BranchScheduleBoxProps) {
  const { data } = useQuery<any, Error>(["getSchedulesDescriptionToday"], () =>
    getSchedulesDescriptionToday()
  );

  console.log(data);

  //fix
  return (
    <div className="flex min-h-screen bg-black">
      {data && (
        <div className="relative bg-gray-700 w-[1300px] min-h-screen flex items-center justify-center flex-col">
          <img
            src={
              data.programCategory === "상체"
                ? "/upper.png"
                : data.programCategory === "하체"
                ? "/lower.png"
                : data.programCategory === "전신"
                ? "/full.png"
                : "/core.png"
            }
            className="w-full h-full absolute object-cover"
            alt="program background"
          />
          <div className=" absolute  w-full h-full bg-black bg-opacity-70"></div>
          <div className="z-50 flex items-center justify-center w-full h-full flex-col">
            <div className="text-7xl font-bold text-white">TODAY’s Program</div>
            <div className="text-7xl font-bold mt-5 text-gymmate">
              {data.programCategory}
            </div>
            <div className="text-white text-6xl mt-20 px-28 leading-[80px]">
              {data.description}
            </div>
          </div>
          <img
            src="/main-logo.svg"
            className=" absolute bottom-24  w-44 "
            alt="logo"
          />
        </div>
      )}
      <div className="flex flex-col items-center justify-center w-full space-y-20">
        <h1 className="text-white text-center text-9xl font-bold">
          {storeBranchName} 시간표
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-1 w-full px-20">
          {daysOfWeek?.map((day: any, index: number) => (
            <div
              key={index}
              className={cls(
                "w-full  flex-col   flex  rounded-lg shadow-md overflow-hidden p-5",
                getBoxBackgroundClass(day)
              )}
            >
              <div
                className={cls(
                  "font-bold text-center pb-2  border-gray-300",
                  size === "big" ? "text-7xl mb-10" : "mb-2 border-b"
                )}
              >
                <p className={getDayClassNames(day)}>
                  {day.format("MM")}.{day.format("DD")} ({day.format("ddd")})
                </p>

                <div
                  className={cls(
                    "h-5 flex items-center mt-2 justify-center",
                    size === "big" ? "mt-24 mb-8" : ""
                  )}
                >
                  <div className="  bg-[#222B3D] text-white rounded-full flex items-center justify-center text-5xl px-10 py-5">
                    {programByDay ? (
                      programByDay[day.format("YYYY-MM-DD")]
                    ) : (
                      <span className="loading loading-bars"></span>
                    )}
                  </div>
                </div>
              </div>
              <div className={cls("", size === "big" ? "" : "")}>
                {isLoading &&
                  Array.from({ length: Math.floor(Math.random() * 7) + 2 }).map(
                    (_, cellIndex) => (
                      <div className="w-full h-[20px] skeleton-box rounded-lg m-1 shadow-md"></div>
                    )
                  )}

                {scheduleByDay &&
                  scheduleByDay[day.format("YYYY-MM-DD")] &&
                  //@ts-ignore
                  scheduleByDay[day.format("YYYY-MM-DD")].map(
                    (program: BranchSchedule, index: number) => {
                      // 시간을 파싱하고 오전/오후 여부를 확인하는 함수
                      const isAM = (dateTimeString: string) => {
                        const hour = new Date(dateTimeString).getHours();
                        return hour < 12; // 12시보다 작으면 오전(AM)
                      };

                      return (
                        <div
                          key={index}
                          onClick={(e) =>
                            handleProgramClick
                              ? handleProgramClick(e, program)
                              : null
                          }
                          className={cls(
                            "flex px-2 rounded-3xl text-white cursor-pointer w-full transition-all hover:bg-gray-300 items-center shadow-md mb-10",
                            size === "big"
                              ? "text-7xl mt-5 justify-center py-5"
                              : "text-sm h-5 mt-2 justify-between",
                            // 오전과 오후에 따라 배경색을 조정
                            isAM(program.startTime)
                              ? "bg-[#348BCD]"
                              : "bg-[#175EA2]"
                          )}
                        >
                          <p className=" font-bold ">
                            <GetHHMM dateTimeString={program.startTime} />
                          </p>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
