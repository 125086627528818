import { create } from "zustand";
import { User } from "../types/models";
import { UserAccountDto, UserDto } from "../types/dto";

type UserStore = {
  user: UserAccountDto | null;
  setUser: (user: UserAccountDto | null) => void;
};

export const useUserStore = create<UserStore>((set) => ({
  user: null,
  setUser: (user) => set({ user }),
}));

type Store = {
  branchName: string;
  branchCode: string;
  setBranchName: (name: string) => void;
  setBranchCode: (code: string) => void;
};

export const useBranchStore = create<Store>((set) => ({
  branchName: "",
  branchCode: "",
  setBranchName: (name) => set((state) => ({ ...state, branchName: name })),
  setBranchCode: (code) => set((state) => ({ ...state, branchCode: code })),
}));

interface State {
  isMuted: boolean;
  toggleMute: () => void;
}

export const useMuteStore = create<State>((set) => ({
  isMuted: false,
  toggleMute: () => set((state) => ({ isMuted: !state.isMuted })),
}));
