import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import {
  AdsDtoWithVideo,
  ProgramDto,
  ScheduleTemplateDto,
} from "../../types/dto";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClock,
  faExternalLink,
  faFile,
  faImage,
  faPenToSquare,
  faPlus,
  faTag,
  faTrash,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/interface/Modal";
import ContentsGapDrag from "../../components/ContentGapDrag";
import { Ads } from "../../types/models";
import FormBox from "../../components/interface/FormBox";
import Radio from "../../components/interface/Radio";
import { useForm } from "react-hook-form";
import FormInput from "../../components/interface/FormInput";
import Button from "../../components/interface/Button";
import {
  uploadVideoToVimeo,
  uploadVideoWithVimeoUrl,
} from "../../services/VimeoUpload";
import axios from "axios";
import AdsBoxDrag from "../../components/AdsBoxDrag";
import { uploadImageDirectly } from "../../services/ImageUpload";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAds } from "../../services/ads";
import useMutation from "../../hooks/useMutation";
import { getTemplate } from "../../services/template";
import { useNavigate, useParams } from "react-router-dom";
import { cls } from "../../libs/utils";

interface TemplateFormData {
  id: number;
  title: string;
  description: string;
}

export default function ScheduleTemplateModal({ loadTempalte }: any) {
  const navigate = useNavigate();

  const { branchCode } = useParams();

  const { data, isLoading, isError, refetch } = useQuery(
    ["getTemplate", branchCode],
    () => getTemplate(branchCode ? branchCode : "main"),
    {}
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<TemplateFormData>();

  //모달 열기
  const showModal = () => {
    reset();
    //@ts-ignore
    window.newTp?.showModal();
  };

  const showEditModal = (item: any) => {
    reset();
    setValue("title", item.title);
    setValue("description", item.description);
    setValue("id", item.id);
    //@ts-ignore
    window.newTp?.showModal();
  };

  const [
    createTemplate,
    { loading: createTemplateLoading, data: createTemplateData },
  ] = useMutation("/api/branch/template", "post");

  const [
    updateTemplate,
    { loading: updateTemplateLoading, data: updateTemplateData },
  ] = useMutation("/api/branch/template", "put");

  const [
    deleteTemplate,
    { loading: deleteTemplateLoading, data: deleteTemplateData },
  ] = useMutation("/api/branch/template", "delete");

  const onValid = (formData: TemplateFormData) => {
    if (watch("id")) {
      updateTemplate(
        {
          title: formData.title,
          description: formData.description,
        },
        watch("id")
      );
    } else {
      createTemplate({
        title: formData.title,
        description: formData.description,
        branchCode: branchCode ? branchCode : "main", //브랜치 코드가 없을경우 메인 브랜치로 간주
      });
    }
  };

  useEffect(() => {
    if (
      createTemplateData?.ok ||
      updateTemplateData?.ok ||
      deleteTemplateData?.ok
    ) {
      refetch();
      //@ts-ignore
      window.newTp?.close();
    }
  }, [createTemplateData, updateTemplateData, deleteTemplateData]);

  const handleTemplate = (id: number) => {
    window.open(`/branch/${branchCode}/template/edit/${id}`, "_blank");
  };

  const handleDelete = async (id: number) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    deleteTemplate({}, id);
  };
  return (
    <div className="mt-2">
      <div className=" shadow-md w-full bg-white p-5">
        <div className=" flex flex-wrap gap-5">
          {data?.data?.map((tmp: any) => (
            <div className=" relative">
              <div
                onClick={() => loadTempalte(tmp.id)}
                className="flex flex-col ml-2 cursor-pointer rounded-md w-80 h-36  transition-all hover:bg-gray-100 p-5 justify-between shadow-md"
              >
                <div className="flex justify-between w-full">
                  <div className="flex flex-col">
                    <div className="text-xl font-bold">{tmp.title}</div>
                    <div
                      className={cls(
                        " text-xs flex w-fit mt-1 items-center p-1 px-3 rounded-full",
                        tmp.branchCode === "main"
                          ? "bg-gymmate text-white "
                          : " bg-gray-200 text-black"
                      )}
                    >
                      {tmp.branchName}
                    </div>
                  </div>
                </div>
                <div className="text-sm">{tmp.description}</div>
              </div>
              <FontAwesomeIcon
                icon={faExternalLink}
                className="m-2 top-5 right-5 absolute opacity-20 z-20  bg-gray-400 text-white hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
                width={10}
                onClick={() => handleTemplate(tmp.id)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
