import { SecondsToMinutes } from "../../libs/utils";
import { Program } from "../../types/models";

export default function ProgramSetDuration({
  program,
  type,
}: {
  program: Program;
  type?: string;
}) {
  console.log(program.tutorialIds);
  return (
    <div className="flex space-x-2 items-center">
      <div className="flex justify-center flex-col items-center">
        <h3 className="font-bold">
          {type === "tutorial"
            ? program.tutorialIds !== null &&
              program.tutorialIds !== undefined &&
              program.tutorialIds !== ""
              ? program.tutorialIds.split(",").length
              : program.contentsIds.split(",").length
            : program.totalSet}
          Set
        </h3>
        {type !== "tutorial" && (
          <p className="text-xs">{`(${program.exerciseTime}/${program.moveTime})`}</p>
        )}
      </div>
      <div className="font-bold text-lg">
        {type === "tutorial" ? (
          <SecondsToMinutes
            seconds={
              program.tutorialIds !== null &&
              program.tutorialIds !== undefined &&
              program.tutorialIds !== ""
                ? program.tutorialIds.split(",").length * 20
                : program.contentsIds.split(",").length * 20
            }
          />
        ) : (
          <SecondsToMinutes seconds={program.totalExerciseTime} />
        )}
      </div>
    </div>
  );
}
