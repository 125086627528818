import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import ContentsBox from "./ContentsBox";
import { useDrag } from "react-dnd";
import { ContentsDtoWithId } from "../types/dto";
import { DragItem } from "../types/dnd";

interface ContentsBoxWrapProps {
  item: ContentsDtoWithId;
  zoneId: string;
  onDelete: (id: string, zoneId: string) => void;
  onDuplicate: (item: ContentsDtoWithId, zoneId: string) => void;
  readOnly: boolean;
  setOriginalZoneId: (value: string) => void;
  tutorialCheckbox?: boolean;
  tutorialId?: number[];
  setTutorialId?: any;
}

const ContentsBoxWrap = ({
  item,
  zoneId,
  onDelete,
  onDuplicate,
  readOnly,
  setOriginalZoneId,
  tutorialCheckbox = false,
  tutorialId,
  setTutorialId,
}: ContentsBoxWrapProps) => {
  const [{ isDragging }, drag]: DragItem = useDrag({
    type: "CONTENT",
    item,
    collect: (monitor) => {
      const isDragging = monitor.isDragging();
      if (isDragging) {
        setOriginalZoneId(zoneId);
        console.log("Drag has started!");
      }
      return { isDragging };
    },
  });

  const dragDropRef = (node: HTMLElement | null) => {
    drag(node);
  };

  return (
    <div
      ref={readOnly ? undefined : dragDropRef}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="w-40 h-36 relative ml-"
    >
      {!readOnly && (
        <FontAwesomeIcon
          className="absolute m-2 z-50 left-0 opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
          icon={faArrowsUpDownLeftRight}
        />
      )}

      <ContentsBox
        key={item.id}
        item={item}
        hover={false}
        deleteMode={readOnly ? false : true}
        onDelete={() => onDelete(item.id!, zoneId)}
        duplicate={readOnly ? false : true}
        onDuplicate={() => onDuplicate(item, zoneId)}
        tutorialCheckbox={tutorialCheckbox}
        tutorialId={tutorialId}
        setTutorialId={setTutorialId}
        readOnly={readOnly}
      />
    </div>
  );
};

export default ContentsBoxWrap;
