import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import { AdsDtoWithVideo, ProgramDto } from "../../types/dto";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClock,
  faFile,
  faImage,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/interface/Modal";
import ContentsGapDrag from "../../components/ContentGapDrag";
import { Ads } from "../../types/models";
import FormBox from "../../components/interface/FormBox";
import Radio from "../../components/interface/Radio";
import { useForm } from "react-hook-form";
import FormInput from "../../components/interface/FormInput";
import Button from "../../components/interface/Button";
import {
  uploadVideoToVimeo,
  uploadVideoWithVimeoUrl,
} from "../../services/VimeoUpload";
import axios from "axios";
import AdsBoxDrag from "../../components/AdsBoxDrag";
import { uploadImageDirectly } from "../../services/ImageUpload";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAds } from "../../services/ads";

interface AdsFormData {
  id: number;
  index: number;
  type: string;
  time: number;
  videoFileType: string;
  vimeoUrl: string;
  videoFile: File[];
  imageFile: File[];
  videoId: number;
}
interface UpdateDatabaseProps {
  formData: AdsFormData;
  videoId?: number;
  imageUrl?: string;
  youtubeId?: string;
}

export default function AdsManger() {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<AdsFormData>();

  const [videoUrl, setVideoUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [adsLoading, setAdsLoading] = useState<number[]>([]);
  const [videoState, setVideoState] = useState<number>();
  const [originalVimeoUrl, setOriginalVimeoUrl] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [youtubeId, setYoutubeId] = useState<string>();

  //모달 열기
  const showModal = () => {
    reset();
    setVideoUrl("");
    setYoutubeId(undefined);
    setVideoState(undefined);
    setOriginalVimeoUrl(undefined);
    setImageUrl("");
    //@ts-ignore
    window.newAd?.showModal();
  };

  //모달 닫기
  const closeModal = () => {
    reset();
    //@ts-ignore
    window.newAd?.close();
  };

  const {
    data: adsData,
    isLoading,
    isError,
    refetch,
  } = useQuery<AdsDtoWithVideo[], Error>(["getAds"], () => getAds(), {});

  //동영상 썸네일 생성
  let videoFile = watch("videoFile") ? watch("videoFile")[0] : undefined;
  useEffect(() => {
    if (videoFile) {
      const url = URL.createObjectURL(videoFile);
      setVideoUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [videoFile]);

  //이미지 썸네일 생성
  const imageFile = watch("imageFile");
  useEffect(() => {
    if (imageFile && imageFile[0]) {
      setImageUrl(URL.createObjectURL(imageFile[0]));
    }
  }, [imageFile]);

  //Drop시 실행되는 함수
  const moveContent = async (draggedItemId: number, index: number) => {
    if (!adsData) {
      return;
    }
    console.log("index", index);

    const draggedItemIndex = adsData.findIndex((ad) => ad.id === draggedItemId);

    console.log("draggedItemIndex: ", draggedItemIndex);
    if (draggedItemIndex >= 0) {
      const newAdsData = [...adsData];

      const targetItemId = newAdsData[index].id;

      // 해당 위치의 아이템과 드래그된 아이템 위치 교환
      const temp = newAdsData[draggedItemIndex];
      newAdsData[draggedItemIndex] = newAdsData[index];
      newAdsData[index] = temp;

      // Update the state
      queryClient.setQueryData(["getAds"], newAdsData);

      await axios.patch(`/api/admin/ads/move/${draggedItemId}`, {
        targetItemId: targetItemId,
      });
    }
  };

  useEffect(() => {
    //콘텐츠 정보 가져오기
    if (watch("type") === "image") {
      resetField("videoFile");
      resetField("videoFileType");
      resetField("vimeoUrl");
    }
  }, [watch("type")]);

  const onValid = async (formData: AdsFormData) => {
    console.log(formData);
    if (
      formData.id &&
      ((formData.videoFile && formData.videoFile[0]) ||
        formData.vimeoUrl !== originalVimeoUrl)
    ) {
      var result = window.confirm(
        "영상 파일이 교체됩니다\n계속 진행하시겠어요?"
      );

      if (!result) {
        return;
      }
    }

    if (formData.id && formData.imageFile && formData.imageFile[0]) {
      var result = window.confirm(
        "이미지 파일이 교체됩니다\n계속 진행하시겠어요?"
      );

      if (!result) {
        return;
      }
    }
    setLoading(true);

    //비디오 파일 업로드의 경우
    if (formData.type === "video" && formData.videoFileType === "file") {
      await uploadVideo(formData);
    }

    //비메오 경로 입력의 경우
    if (
      formData.type === "video" &&
      formData.videoFileType === "vimeoUrl" &&
      formData.vimeoUrl
    ) {
      await importVimeoUrl(formData);
    }

    //비메오 경로 입력의 경우
    if (
      formData.type === "video" &&
      formData.videoFileType === "youtubeUrl" &&
      formData.vimeoUrl
    ) {
      await importYoutubeUrl(formData);
    }

    //이미지 업로드 경우
    if (formData.type === "image") {
      await uploadImage(formData);
    }
    setLoading(false);
  };

  const uploadVideo = async (formData: AdsFormData) => {
    try {
      let finVideoId = watch("videoId") ? watch("videoId") : undefined;
      // 1. 비디오 업로드
      if (formData.videoFile && formData.videoFile[0]) {
        try {
          const fileSize = formData.videoFile[0].size;
          const videoId = await uploadVideoToVimeo(
            fileSize.toString(),
            "광고영상",
            formData.videoFile[0]
          );

          finVideoId = videoId;
        } catch (err) {
          alert("비디오 업로드 중 문제가 발생했습니다.");
          setLoading(false);
          return;
        }
      }

      if (finVideoId) {
        await updateDatabse({ formData, videoId: finVideoId });
      } else {
        alert("비디오가 올바르지 않아요.");
        setLoading(false);
        return;
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  const uploadImage = async (formData: AdsFormData) => {
    try {
      let newImageUrl = imageUrl ? imageUrl : undefined;
      // 1. 비디오 업로드
      if (formData.imageFile && formData.imageFile[0]) {
        try {
          newImageUrl = await uploadImageDirectly(formData.imageFile[0]);
        } catch (err) {
          alert("이미지 업로드 중 문제가 발생했습니다.");
          setLoading(false);
          return;
        }
      }

      if (imageUrl) {
        await updateDatabse({ formData, imageUrl: newImageUrl });
      } else {
        alert("이미지가 올바르지 않아요.");
        setLoading(false);
        return;
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  const importVimeoUrl = async (formData: AdsFormData) => {
    try {
      let finVideoId = watch("videoId") ? watch("videoId") : undefined;

      if (formData.vimeoUrl !== originalVimeoUrl) {
        var url: string = formData.vimeoUrl;
        var splitUrl = url.split("/");
        var videoCode = splitUrl[splitUrl.length - 1];

        finVideoId = await uploadVideoWithVimeoUrl(videoCode);
      }

      if (finVideoId) {
        await updateDatabse({ formData, videoId: finVideoId });
      }
    } catch (err) {
      alert("Vimeo 경로 추출 중 오류가 발생했습니다.");
      setLoading(false);
      return;
    }
  };
  const extractVideoId = (url: string) => {
    const shortUrlRegex = /^https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})$/;
    const longUrlRegex =
      /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;

    const shortMatch = url.match(shortUrlRegex);
    const longMatch = url.match(longUrlRegex);

    if (shortMatch) {
      return shortMatch[1];
    } else if (longMatch) {
      return longMatch[1];
    } else {
      throw new Error("올바른 Youtube 경로가 아닙니다.");
    }
  };

  const importYoutubeUrl = async (formData: AdsFormData) => {
    try {
      if (formData.vimeoUrl !== originalVimeoUrl) {
        const yotubeCode = await extractVideoId(formData.vimeoUrl);
        await updateDatabse({ formData, youtubeId: yotubeCode });
      } else {
        await updateDatabse({ formData, youtubeId: youtubeId });
      }
    } catch (err) {
      alert("Youtube 경로 추출 중 오류가 발생했습니다.");
      setLoading(false);
      return;
    }
  };

  const updateDatabse = async ({
    formData,
    videoId,
    imageUrl,
    youtubeId,
  }: UpdateDatabaseProps) => {
    if (formData.id) {
      await axios.patch(`/api/admin/ads/${formData.id}`, {
        type: youtubeId ? "youtube" : formData.type,
        time: formData.time,
        videoId: youtubeId ? null : videoId,
        videoUrl: formData.vimeoUrl ? formData.vimeoUrl : null,
        imgUrl: imageUrl ? imageUrl : null,
        youtubeId: youtubeId ? youtubeId : null,
      });
    } else {
      await axios.post(`/api/admin/ads`, {
        type: youtubeId ? "youtube" : formData.type,
        time: formData.time,
        videoId: youtubeId ? null : videoId,
        videoUrl: formData.vimeoUrl ? formData.vimeoUrl : null,
        imgUrl: imageUrl ? imageUrl : null,
        youtubeId: youtubeId ? youtubeId : null,
      });
    }

    setLoading(false);
    refetch();

    closeModal();
  };

  const onDelete = async (itemId: number) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }
    setAdsLoading([...adsLoading, itemId]);
    try {
      await axios.delete(`/api/admin/ads/${itemId}`);
      closeModal();
      refetch();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }

    const newLoadingArray = adsLoading.filter(
      (item: number) => item !== itemId
    );

    setAdsLoading(newLoadingArray);
  };

  const openEditModal = (item: AdsDtoWithVideo) => {
    showModal();

    setValue("id", item.id);

    if (item.type === "youtube") {
      setValue("type", "video", { shouldValidate: true });
    } else {
      setValue("type", item.type, { shouldValidate: true });
    }

    setValue("time", item.time);

    if (item.video) {
      setVideoState(item.video.state);
      setVideoUrl(item.video.fileLink || "");
    }

    if (item.videoId) {
      setValue("videoId", item.videoId);
    }

    if (item.videoUrl) {
      if (item.type === "youtube" && item.youtubeId) {
        setValue("videoFileType", "youtubeUrl");
        setYoutubeId(item.youtubeId);
      } else {
        setValue("videoFileType", "vimeoUrl");
      }
      setValue("vimeoUrl", item.videoUrl);
      setOriginalVimeoUrl(item.videoUrl);
    } else {
      setValue("videoFileType", "file");
    }

    if (item.imgUrl) {
      setImageUrl(item.imgUrl);
    }
  };

  return (
    <>
      <Modal id="newAd" title={`광고 등록`} size="large" overflow="hidden">
        <div>
          <FormBox title="유형" icon={<FontAwesomeIcon icon={faImage} />}>
            <div className="flex space-x-5">
              <Radio
                title="이미지"
                value="image"
                register={register("type", {
                  required: "유형을 선택해주세요",
                })}
              />
              <Radio
                title="동영상"
                value="video"
                register={register("type", {
                  required: "유형을 선택해주세요",
                })}
              />
            </div>
          </FormBox>
          {watch("type") === "video" ? (
            <FormBox title="유형" icon={<FontAwesomeIcon icon={faImage} />}>
              <div className="flex space-x-5">
                <Radio
                  title="파일 업로드"
                  value="file"
                  register={register("videoFileType", {
                    required: watch("type") === "video" ? true : false,
                  })}
                />
                <Radio
                  iconComponent={
                    <img src="/vimeo.png" className="w-4 h-4" alt="vimeo" />
                  }
                  title="URL"
                  value="vimeoUrl"
                  register={register("videoFileType", {
                    required: true,
                  })}
                />
                <Radio
                  iconComponent={
                    <img src="/youtube.png" className="w-4 h-4" alt="vimeo" />
                  }
                  title="URL"
                  value="youtubeUrl"
                  register={register("videoFileType", {
                    required: true,
                  })}
                />
              </div>
            </FormBox>
          ) : null}
          {(watch("type") === "video" || watch("type") === "image") && (
            <div className="flex items-center">
              {watch("type") === "video" &&
                watch("videoFileType") === "file" && (
                  <FormBox
                    title="파일"
                    icon={<FontAwesomeIcon icon={faFile} />}
                  >
                    <label htmlFor="file-upload-platformA" className="btn">
                      <span className="cursor-pointer">파일 선택</span>
                    </label>
                    <input
                      id="file-upload-platformA"
                      type="file"
                      accept="video/mp4"
                      className="hidden"
                      {...register("videoFile", {
                        required:
                          watch("videoFileType") === "file" && !watch("id")
                            ? true
                            : false,
                      })}
                    />
                  </FormBox>
                )}

              {(watch("videoFileType") === "vimeoUrl" ||
                watch("videoFileType") === "youtubeUrl") && (
                <FormBox title="URL" icon={<FontAwesomeIcon icon={faFile} />}>
                  <FormInput
                    placeholder={
                      watch("videoFileType") === "vimeoUrl"
                        ? "https://vimeo.com/535812245"
                        : "https://www.youtube.com/watch?v=an9J6isFQag"
                    }
                    register={register("vimeoUrl", {
                      required:
                        watch("videoFileType") === "vimeoUrl" ||
                        watch("videoFileType") === "youtubeUrl"
                          ? true
                          : false,
                    })}
                  />
                </FormBox>
              )}

              {watch("type") === "image" && (
                <FormBox title="파일" icon={<FontAwesomeIcon icon={faFile} />}>
                  <label htmlFor="file-upload-platformA" className="btn">
                    <span className="cursor-pointer">파일 선택</span>
                  </label>
                  <input
                    id="file-upload-platformA"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    className="hidden"
                    {...register("imageFile", {
                      required:
                        watch("type") === "image" && !watch("id")
                          ? true
                          : false,
                    })}
                  />
                </FormBox>
              )}
            </div>
          )}

          {videoUrl ? (
            <>
              <video src={videoUrl} className="border mt-5" controls />

              {watch("videoFile") && (
                <FormBox
                  title="파일이름"
                  required={false}
                  icon={<FontAwesomeIcon icon={faFile} />}
                >
                  <p className="mt-2">{watch("videoFile")[0]?.name}</p>
                </FormBox>
              )}
            </>
          ) : (
            <div>
              {videoState === 0 && (
                <div className="h-full w-full relative text-center">
                  <div className="absolute left-[45%] top-[45%] text-xs">
                    <span className="loading loading-spinner loading-xs "></span>
                    <p>인코딩 중</p>
                  </div>
                  <img
                    className="h-full opacity-30"
                    src="/video_tmp.avif"
                    alt="Video Thumbnail"
                  />
                </div>
              )}
            </div>
          )}
          {youtubeId && watch("videoFileType") === "youtubeUrl" && (
            <iframe
              className="w-full h-[500px]"
              width="1280"
              height="720"
              src={`https://www.youtube.com/embed/${youtubeId}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          )}

          {imageUrl && (
            <div className="w-full flex justify-center items-center flex-col">
              <img src={imageUrl} className="border mt-5 w-1/2" alt="preview" />

              {watch("imageFile") && (
                <FormBox
                  title="파일이름"
                  required={false}
                  icon={<FontAwesomeIcon icon={faFile} />}
                >
                  <p className="mt-2">{watch("imageFile")[0]?.name}</p>
                </FormBox>
              )}
            </div>
          )}

          <FormBox
            title="지속 시간 (초)"
            icon={<FontAwesomeIcon icon={faClock} />}
          >
            <FormInput
              placeholder="3"
              register={register("time", {
                required: true,
              })}
              type="number"
            />
          </FormBox>
          <Button
            loading={loading}
            title={`광고 ${watch("id") ? "수정" : "등록"}`}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={isValid ? false : true}
          />
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            광고 등록
          </h1>
          <div className="flex flex-wrap">
            {isLoading &&
              Array.from({ length: 9 }).map((_, cellIndex) => (
                <div className="w-[160px] h-[144px] skeleton-box rounded-lg m-1 shadow-md"></div>
              ))}
            {adsData?.map((item: AdsDtoWithVideo, index: number) => {
              return (
                <div className="flex  mb-2">
                  <ContentsGapDrag
                    item={item}
                    index={index}
                    moveContent={moveContent}
                  />
                  <AdsBoxDrag
                    item={item}
                    onDelete={() => onDelete(item.id)}
                    loading={adsLoading}
                    openEditModal={openEditModal}
                  />

                  {adsData.length - 1 === index && (
                    <>
                      <ContentsGapDrag
                        item={item}
                        index={index}
                        moveContent={moveContent}
                      />
                      <div
                        onClick={showModal}
                        className="flex cursor-pointer  rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </>
                  )}
                </div>
              );
            })}

            {adsData?.length === 0 && (
              <div
                onClick={showModal}
                className="flex ml-2 cursor-pointer rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
              >
                <FontAwesomeIcon icon={faPlus} />
              </div>
            )}
          </div>
        </div>
      </AdminLayout>
    </>
  );
}
