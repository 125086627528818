import axios from "axios";

export const getContentsByIds = async (
  contentsIds: string,
  duplicate: boolean
) => {
  const response = await axios.get(
    `/api/public/contents?ids=${contentsIds}&duplicate=${duplicate}`
  );
  console.log("getIDs");
  return response.data;
};
