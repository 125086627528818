import { useForm } from "react-hook-form";
import axios from "axios";

import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faCode,
  faHouse,
  faIdCard,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate, formatDateString } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { BranchDto } from "../../types/dto";

interface UserFormData {
  id?: number;
  name: string;
  code: string;
}

export const translateScheduleOperationType = (operationType: string) => {
  const operationTypeMapping: any = {
    BULK_CHANGE: "일괄변경",
    TEMPLATE_LOAD: "템플릿 불러오기",
    AUTO_COPY: "자동복사",
    INDIVIDUAL_EDIT: "개별수정",
    INDIVIDUAL_CREATE: "개별생성",
    COPY: "복사",
  };

  return operationTypeMapping[operationType] || "알 수 없는 유형";
};

export default function BranchScheduleLog() {
  const { branchCode } = useParams();
  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);

  const tableHeader = [
    { title: "No." },
    { title: "변경일" },
    { title: "변경시작일" },
    { title: "변경종료일" },
    { title: "유형" },
    { title: "메시지 " },
    { title: "변경자" },
  ];

  return (
    <>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            <span
              className="cursor-pointer"
              onClick={() => navigate(`/branch/${branchCode}/assign`)}
            >
              예약자 그룹 배정 / 시간표 관리
            </span>{" "}
            - 시간표 변경로그
          </h1>
          <Table
            dataApiUrl={`/api/branch/schedule-log/${branchCode}`}
            headerArray={tableHeader}
            mutate={mutate}
            initSort="id"
            initDirection="asc"
          >
            {(data: any, startIndex: number) =>
              data.map((item: any, index: number) => {
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td className="truncate">
                      {formatDateString(item.operationTime)}
                    </td>
                    <td className="truncate">
                      <StringToDate dateString={item.startDate.toString()} />
                    </td>
                    <td className="truncate">
                      <StringToDate dateString={item.endDate.toString()} />
                    </td>

                    <td>{translateScheduleOperationType(item.type)}</td>
                    <td>{item.message}</td>
                    <td>{item.userName}</td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}
