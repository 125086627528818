import { faBuilding, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

interface ModalProps {
  iconComponent?: any;
  title: string;
  onClick: any;
  loading?: boolean;
  disabled?: boolean;
}

export default function Button({
  iconComponent,
  title,
  onClick,
  loading = false,
  disabled = false,
}: ModalProps) {
  return (
    <button
      className="btn-primary btn mt-2     text-white tv:btn-lg tv:text-6xl tv:h-44"
      onClick={onClick}
      disabled={loading || disabled ? true : false}
    >
      {loading ? (
        <span className="loading loading-dots loading-xs"></span>
      ) : (
        <div className="flex space-x-2">
          <div>{iconComponent}</div>
          <div>{title}</div>
        </div>
      )}
    </button>
  );
}
