import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faIcons,
  faImage,
  faLeftRight,
  faLocation,
  faPerson,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useRef, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";
import Button from "../../components/interface/Button";
import { Part, Tool } from "../../types/models";
import { TableHeaderForContents } from "../../constants/TableHeader";
import { ContentsDto } from "../../types/dto";
import {
  uploadVideoToVimeo,
  uploadVideoWithVimeoUrl,
} from "../../services/VimeoUpload";

interface ContentsFormData {
  id: number;
  categories: string[];
  name: string;
  effect: string;
  description: string;
  videoFile: File[];
  videoId: number;
  videoFileType: string;
  vimeoUrl?: string;
  comment: string;
  icon: string[];
  divisionAction: string;
}

export default function Contents() {
  const user = useUserStore((state) => state.user);

  const [mutate, setMutate] = useState(false);
  const [partData, setPartData] = useState<Part[]>([]); //운동 등록시 드롭다운메뉴
  const [toolData, setToolData] = useState<Tool[]>([]); //운동 등록시 드롭다운메뉴

  const [selectPart, setSelectPart] = useState<Part[]>(); //운동 등록시 선택한 부위
  const [selectTool, setSelectTool] = useState<Tool[]>(); //운동 등록시 선택한 도구
  const [videoState, setVideoState] = useState<number>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [uploadMsg, setUploadMsg] = useState<string>();
  const [originalVimeoUrl, setOriginalVimeoUrl] = useState<string>();
  const tableHeader = TableHeaderForContents;

  const searchData = [
    { title: "부위", value: partData, searchKey: "id", searchFiled: "partIds" },
    { title: "도구", value: toolData, searchKey: "id", searchFiled: "toolIds" },
    {
      title: "운동종류",
      value: [{ name: "프리웨이트" }, { name: "요가" }, { name: "필라테스" }],
      searchKey: "name",
      searchFiled: "categories",
    },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<ContentsFormData>();

  useEffect(() => {
    if (watch("videoFileType") === "file") {
      resetField("vimeoUrl");
    } else {
      resetField("videoFile");
      setVideoUrl(undefined);
    }
  }, [watch("videoFileType")]);

  //데이터 가져오기
  useEffect(() => {
    //콘텐츠 정보 가져오기
    getPartData();
    getToolData();
  }, []);

  const getPartData = async () => {
    try {
      const response = await axios.get("/api/admin/part/all");
      setPartData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getToolData = async () => {
    try {
      const response = await axios.get("/api/admin/tool/all");
      setToolData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  //모달 열기
  const showModal = (isReset: boolean) => {
    window.my_modal_2.showModal();
    if (isReset) {
      reset();
      setSelectPart(undefined);
      setSelectTool(undefined);
      setVideoUrl(undefined);
      setVideoState(undefined);
      setOriginalVimeoUrl(undefined);

      getPartData();
      getToolData();
      console.log("showModal");
    }
  };

  const showCsvModal = () => {
    //@ts-ignore
    window.csv_modal.showModal();
  };

  //썸네일 생성

  let videoFile = watch("videoFile") ? watch("videoFile")[0] : undefined;
  useEffect(() => {
    if (videoFile) {
      const url = URL.createObjectURL(videoFile);
      setVideoUrl(url);

      // URL.revokeObjectURL을 사용하여 더 이상 필요하지 않은 URL을 해제하는 것이 좋습니다.
      return () => URL.revokeObjectURL(url);
    }
  }, [videoFile]);

  //모달 닫기
  const closeModal = () => {
    reset();

    window.my_modal_2.close();
  };

  const uploadVideo = async (formData: ContentsFormData) => {
    if (formData.videoFile && formData.videoFile[0]) {
      try {
        const fileSize = formData.videoFile[0].size;
        const videoId = await uploadVideoToVimeo(
          fileSize.toString(),
          formData.name,
          formData.videoFile[0]
        );

        return videoId;
      } catch (err) {
        alert("비디오 업로드 중 문제가 발생했습니다.");
        setLoading(false);
        return;
      }
    }
  };

  const importVimeoUrl = async (formData: ContentsFormData) => {
    try {
      if (formData.vimeoUrl !== originalVimeoUrl && formData.vimeoUrl) {
        var url: string = formData.vimeoUrl;
        var splitUrl = url.split("/");
        var videoCode = splitUrl[splitUrl.length - 1];

        const finVideoId = await uploadVideoWithVimeoUrl(videoCode);

        return finVideoId;
      } else {
        throw new Error();
      }
    } catch (err) {
      alert("비디오 경로 추출 중 문제가 발생했습니다.");
      setLoading(false);
      return;
    }
  };

  const onValid = async (formData: ContentsFormData) => {
    console.log(originalVimeoUrl);
    console.log(formData.vimeoUrl);
    setLoading(true);
    if (!selectPart) {
      alert("부위를 선택하세요");
      return;
    }

    if (!selectTool) {
      alert("도구를 선택하세요");
      return;
    }

    const partIds = selectPart.map((item: Part) => item.id);
    const toolIds = selectTool.map((item: Tool) => item.id);

    let finVideoId = watch("videoId") ? watch("videoId") : undefined;

    //비디오 파일 업로드의 경우
    if (formData.videoFileType === "file" && formData.videoFile[0]) {
      const videoId = await uploadVideo(formData);
      finVideoId = videoId;
    }

    //비메오 경로 입력의 경우
    if (
      formData.videoFileType === "vimeoUrl" &&
      formData.vimeoUrl &&
      formData.vimeoUrl !== originalVimeoUrl
    ) {
      const videoId = await importVimeoUrl(formData);
      finVideoId = videoId;
    }

    if (!finVideoId) {
      alert("비디오가 올바르지 않아요.");
      setLoading(false);
      return;
    }

    try {
      setUploadMsg("데이터베이스 업데이트 중...");

      if (formData.id) {
        await axios.patch(`/api/admin/contents/${formData.id}`, {
          partIds: partIds.toString(),
          toolIds: toolIds.toString(),
          videoId: Number(finVideoId),
          categories: formData.categories.toString(),
          name: formData.name.toString(),
          effect: formData.effect.toString(),
          description: formData.description.toString(),
          videoUrl: formData.vimeoUrl ? formData.vimeoUrl : null,
          videoFileType: formData.videoFileType,
          comment: formData.comment,
          icon: formData.icon.toString(),
          divisionAction: formData.divisionAction,
        });
        setMutate(!mutate);
        closeModal();
      } else if (finVideoId) {
        // 2. DB 저장
        await axios.post(`/api/admin/contents`, {
          partIds: partIds.toString(),
          toolIds: toolIds.toString(),
          videoId: Number(finVideoId),
          categories: formData.categories.toString(),
          name: formData.name.toString(),
          effect: formData.effect.toString(),
          description: formData.description.toString(),
          videoUrl: formData.vimeoUrl ? formData.vimeoUrl : null,
          videoFileType: formData.videoFileType,
          comment: formData.comment,
          icon: formData.icon.toString(),
          divisionAction: formData.divisionAction,
        });
        setMutate(!mutate);
        closeModal();
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
    setLoading(false);
  };

  const openEditModal = (item: ContentsDto) => {
    showModal(false);

    var categoriesStr = item.content.categories;
    var categoriesArr = categoriesStr.split(",");

    var iconStr = item.content.icon || "";
    var iconArr = iconStr.split(",");

    console.log(item);

    setValue("id", item.content.id || 0);
    setValue("videoId", item.video?.id || 0);
    setValue("categories", categoriesArr);
    setValue("name", item.content.name);
    setValue("effect", item.content.effect || "");
    setValue("description", item.content.description || "");
    setValue("videoFileType", item.content.videoFileType || "file");
    setValue("vimeoUrl", item.content.videoUrl || "");
    setValue("comment", item.content.comment || "");
    setValue("icon", iconArr);
    setValue("divisionAction", item.content.divisionAction || "");
    setSelectPart(item.parts);
    setSelectTool(item.tools);
    setVideoUrl(item.video?.fileLink || "");
    setVideoState(item.video?.state);
    setOriginalVimeoUrl(item.content.videoUrl || "");
  };

  const openVideoModal = (item: ContentsDto) => {
    if (!item.video?.fileLink) {
      return;
    }
    setVideoUrl(item.video.fileLink);
    //@ts-ignore
    window.videoModal.showModal();
  };

  const onDelete = async (item: ContentsFormData) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      await axios.delete(`/api/admin/contents/${item.id}`);
      closeModal();
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  const onAddTag = (type: string, item: Part) => {
    if (type === "part") {
      if (!selectPart) {
        // if selectPart is undefined or null
        setSelectPart([item]); // initialize it with the new id
      } else {
        setSelectPart([...selectPart, item]); // otherwise, append the new id
      }
      setPartData(partData.filter((listData: Part) => item.id !== listData.id));
    } else if (type === "tool") {
      if (!selectTool) {
        // if selectTool is undefined or null
        setSelectTool([item]); // initialize it with the new id
      } else {
        setSelectTool([...selectTool, item]); // otherwise, append the new id
      }
      setToolData(toolData.filter((listData: Tool) => item.id !== listData.id));
    }
  };

  const onDeleteTag = (type: string, item: Part) => {
    if (type === "part") {
      if (selectPart) {
        // filter out the item with the provided id
        setSelectPart(
          selectPart.filter((listData: Part) => item.id !== listData.id)
        );
      }

      setPartData([...partData, item]);
    } else if (type === "tool") {
      if (selectTool) {
        // filter out the item with the provided id
        setSelectTool(
          selectTool.filter((listData: Tool) => item.id !== listData.id)
        );
      }
      setToolData([...toolData, item]);
    }
  };

  const fileInputRef: any = useRef(null);

  const [csvLoading, setCsvLoading] = useState(false);

  const handleUpload = async () => {
    setCsvLoading(true);
    const file = fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/admin/csv/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Upload Successful:", response.data);

      setCsvLoading(false);

      setMutate(!mutate);

      //@ts-ignore
      window.csv_modal.close();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <Modal id="csv_modal" title={`CSV 대량 등록`}>
        <div className="mt-5 flex flex-col">
          <input type="file" ref={fileInputRef} />
          <Button
            loading={csvLoading}
            title={`CSV 업로드`}
            onClick={handleUpload}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
          />
        </div>
      </Modal>
      <Modal id="videoModal" title={`영상`}>
        <video src={videoUrl} className="border mt-5" controls />
      </Modal>
      <Modal
        size="large"
        id="my_modal_2"
        title={`운동 ${watch("id") ? "수정" : "등록"}`}
        overflow="hidden"
      >
        <div className="flex space-x-2">
          <div className="w-1/2">
            <FormBox
              title="부위"
              icon={<FontAwesomeIcon icon={faDrumstickBite} />}
            >
              <div className="flex  items-center flex-wrap ">
                <div className="dropdown dropdown-bottom">
                  <label tabIndex={0} className="btn  btn-xs mr-1">
                    <FontAwesomeIcon icon={faCirclePlus} />
                    추가
                  </label>

                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    {partData ? (
                      partData.map((part: Part) => (
                        <li>
                          <a onClick={() => onAddTag("part", part)}>
                            {part.name}
                          </a>
                        </li>
                      ))
                    ) : (
                      <div className="flex justify-center items-center">
                        <span className="loading loading-spinner loading-xs"></span>
                      </div>
                    )}
                  </ul>
                </div>
                {selectPart &&
                  selectPart.map((part: Part) => (
                    <div className="btn btn-xs m-1 bg-blue-200">
                      {part.name}{" "}
                      <FontAwesomeIcon
                        onClick={() => onDeleteTag("part", part)}
                        icon={faCircleXmark}
                      />
                    </div>
                  ))}
              </div>
            </FormBox>
            <FormBox title="도구" icon={<FontAwesomeIcon icon={faDumbbell} />}>
              <div className="flex  items-center flex-wrap ">
                <div className="dropdown dropdown-bottom">
                  <label tabIndex={0} className="btn  btn-xs mr-1">
                    <FontAwesomeIcon icon={faCirclePlus} />
                    추가
                  </label>

                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    {toolData ? (
                      toolData.map((part: Tool) => (
                        <li>
                          <a onClick={() => onAddTag("tool", part)}>
                            {part.name}
                          </a>
                        </li>
                      ))
                    ) : (
                      <div className="flex justify-center items-center">
                        <span className="loading loading-spinner loading-xs"></span>
                      </div>
                    )}
                  </ul>
                </div>
                {selectTool &&
                  selectTool.map((part: Tool) => (
                    <div className="btn btn-xs m-1 bg-orange-200">
                      {part.name}
                      <FontAwesomeIcon
                        onClick={() => onDeleteTag("tool", part)}
                        icon={faCircleXmark}
                      />
                    </div>
                  ))}
              </div>
            </FormBox>

            <FormBox title="구분" icon={<FontAwesomeIcon icon={faLocation} />}>
              <div className="flex space-x-5">
                <Radio
                  title="프리웨이트"
                  value="프리웨이트"
                  type="checkbox"
                  register={register("categories", {
                    required: "운동종류를 선택해주세요",
                  })}
                />
                <Radio
                  title="요가"
                  value="요가"
                  type="checkbox"
                  register={register("categories", {
                    required: "운동종류를 선택해주세요",
                  })}
                />
                <Radio
                  title="필라테스"
                  value="필라테스"
                  type="checkbox"
                  register={register("categories", {
                    required: "운동종류를 선택해주세요",
                  })}
                />
              </div>
            </FormBox>
            <FormBox title="운동명" icon={<FontAwesomeIcon icon={faSign} />}>
              <FormInput
                placeholder="운동명"
                register={register("name", {
                  required: "운동명을 입력해주세요",
                })}
              />
            </FormBox>
            <FormBox
              title="효과"
              required={false}
              icon={<FontAwesomeIcon icon={faFire} />}
            >
              <FormInput placeholder="효과" register={register("effect")} />
            </FormBox>
            <FormBox
              title="설명"
              required={false}
              icon={<FontAwesomeIcon icon={faBook} />}
            >
              <FormInput
                placeholder="설명"
                type="textarea"
                register={register("description")}
              />
            </FormBox>
            <FormBox
              title="수업 코멘트"
              required={false}
              icon={<FontAwesomeIcon icon={faBook} />}
            >
              <FormInput
                placeholder="수업 코멘트"
                type="textarea"
                register={register("comment")}
              />
            </FormBox>
          </div>

          <div className="w-1/2">
            <FormBox
              title="아이콘"
              required={false}
              icon={<FontAwesomeIcon icon={faLeftRight} />}
            >
              <div className="flex space-x-5">
                <Radio
                  title="L"
                  value="L"
                  type="checkbox"
                  register={register("icon", {
                    required: false,
                  })}
                />
                <Radio
                  title="R"
                  value="R"
                  type="checkbox"
                  register={register("icon", {
                    required: false,
                  })}
                />
              </div>
            </FormBox>
            <FormBox
              title="구분동작"
              required={false}
              icon={<FontAwesomeIcon icon={faPerson} />}
            >
              <FormInput
                placeholder="구분동작"
                type="textarea"
                register={register("divisionAction")}
              />
            </FormBox>
            <FormBox title="유형" icon={<FontAwesomeIcon icon={faImage} />}>
              <div className="flex space-x-5">
                <Radio
                  title="파일 업로드"
                  value="file"
                  register={register("videoFileType", {
                    required: true,
                  })}
                />
                <Radio
                  iconComponent={
                    <img src="/vimeo.png" className="w-4 h-4" alt="vimeo" />
                  }
                  title="URL"
                  value="vimeoUrl"
                  register={register("videoFileType", {
                    required: true,
                  })}
                />
              </div>
            </FormBox>
            {watch("videoFileType") === "file" && (
              <>
                <FormBox
                  title="운동영상"
                  icon={<FontAwesomeIcon icon={faVideo} />}
                >
                  <div className="flex items-center">
                    <label htmlFor="file-upload-platformA" className="btn">
                      <span className="cursor-pointer">파일 선택</span>
                    </label>
                    <input
                      id="file-upload-platformA"
                      type="file"
                      accept="video/mp4"
                      className="hidden"
                      {...register("videoFile", {
                        required:
                          watch("videoFileType") === "file" && !watch("id")
                            ? true
                            : false,
                      })}
                    />
                  </div>
                </FormBox>
                {videoUrl ? (
                  <video src={videoUrl} className="border mt-5" controls />
                ) : (
                  <div className="border mt-5 h-[273px]">
                    {videoState === 0 && (
                      <div className="h-full w-full relative text-center">
                        <div className="absolute left-[45%] top-[45%] text-xs">
                          <span className="loading loading-spinner loading-xs "></span>
                          <p>인코딩 중</p>
                        </div>
                        <img
                          className="h-full opacity-30"
                          src="/video_tmp.avif"
                          alt="Video Thumbnail"
                        />
                      </div>
                    )}
                  </div>
                )}
                {watch("videoFile") && (
                  <FormBox
                    title="파일이름"
                    required={false}
                    icon={<FontAwesomeIcon icon={faFile} />}
                  >
                    <p className="mt-2">{watch("videoFile")[0]?.name}</p>
                  </FormBox>
                )}
              </>
            )}
            {watch("videoFileType") === "vimeoUrl" && (
              <FormBox title="파일" icon={<FontAwesomeIcon icon={faFile} />}>
                <FormInput
                  placeholder="https://vimeo.com/535812245"
                  register={register("vimeoUrl", {
                    required:
                      watch("videoFileType") === "vimeoUrl" ? true : false,
                  })}
                />
              </FormBox>
            )}
          </div>
        </div>

        <div className="w-full flex flex-col justify-center mt-5">
          {watch("id") && (
            <button
              className=" btn w-full bg-black text-white  "
              onClick={() => onDelete(watch())}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              운동 삭제
            </button>
          )}

          <Button
            loading={loading}
            title={`운동 ${watch("id") ? "수정" : "등록"}`}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={isValid ? false : true}
          />
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <div className="flex justify-between">
            <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
              운동 콘텐츠 관리
            </h1>
            <button
              className=" btn-primary btn btn-sm   text-white  "
              onClick={() => showCsvModal()}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              CSV 대량 등록
            </button>
          </div>
          <Table
            dataApiUrl="/api/admin/contents"
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={() => showModal(true)}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                운동 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl="/api/admin/contents/search"
          >
            {(data: ContentsDto[], startIndex: number) =>
              data.map((item: ContentsDto, index: number) => {
                return (
                  <tr key={item.content.id}>
                    <th>{startIndex - index}</th>
                    <td className="truncate">
                      <StringToDate
                        dateString={item.content.createdAt.toString()}
                      />
                    </td>

                    <td>
                      {item.parts.map((part: Part) => (
                        <p className="btn btn-xs m-1 bg-blue-200 truncate ">
                          {part.name}
                        </p>
                      ))}
                    </td>
                    <td>
                      {item.tools.map((tool: Tool) => (
                        <p className="btn btn-xs m-1 bg-orange-200 truncate">
                          {tool.name}
                        </p>
                      ))}
                    </td>
                    <td className="w-[100px] flex  justify-center ">
                      {item.video?.state === 0 ? (
                        <div className="h-10 relative text-center">
                          <div className="absolute mx-auto  left-3 top-1 text-xs">
                            <span className="loading loading-spinner loading-xs "></span>
                            <p>인코딩 중</p>
                          </div>
                          <img
                            className=" opacity-30"
                            src="/video_tmp.avif"
                            alt="Video Thumbnail"
                          />
                        </div>
                      ) : (
                        <img
                          className="h-10 cursor-pointer "
                          onClick={() => openVideoModal(item)}
                          src={item.video?.thumbnail || ""}
                        />
                      )}
                    </td>
                    <td className="truncate">{item.content.name}</td>
                    <td className="truncate">{item.content.categories}</td>
                    <td>{item.content.registrationCount}</td>
                    <td>
                      <button
                        onClick={() => openEditModal(item)}
                        className="btn btn-xs truncate "
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}
