import { useDrag, useDrop } from "react-dnd";
import { ContentsDtoWithId } from "../types/dto";
import { BranchGroup } from "../types/models";

interface ContentsGapDragProps {
  index: number;
  moveContent: any;
}

const BranchGroupNameDrop = ({ index, moveContent }: ContentsGapDragProps) => {
  const [{ isOver }, drop] = useDrop({
    accept: "NAME",
    drop: (item: BranchGroup, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop && item.id) {
        console.log(index);
        moveContent(item.indexNum, index);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className="w-full absolute">
      {isOver ? (
        <div className="h-10 bg-blue-400 bg-opacity-20 z-50"></div>
      ) : (
        <div className="h-10  bg-green-500 z-30  bg-opacity-0   " />
      )}
    </div>
  );
};

export default BranchGroupNameDrop;
