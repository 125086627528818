import { UseFormRegisterReturn } from "react-hook-form";
import { cls } from "../../libs/utils";

interface ModalProps {
  cheked?: boolean;
  title: string;
  register: UseFormRegisterReturn;
  value: any;
  disabled?: boolean;
  type?: string;
  iconComponent?: any;
}

export default function Radio({
  cheked = false,
  title,
  register,
  value,
  disabled = false,
  type = "radio",
  iconComponent,
}: ModalProps) {
  return (
    <div className="form-control">
      <label className="label cursor-pointer">
        <input
          {...register}
          type={type}
          className={cls(
            " ",
            type === "radio" ? "radio checked:bg-gymmate" : "checkbox "
          )}
          value={value}
          disabled={disabled}
        />
        <div className="ml-2 label-text flex items-center justify-center space-x-1">
          {iconComponent} <p>{title}</p>
        </div>
      </label>
    </div>
  );
}
