import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMsg from "../../components/ErrorMsg";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode } from "../../services/branch";
import Button from "../../components/interface/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faDumbbell,
  faQrcode,
  faSignIn,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useBranchStore } from "../../store";
import ScreenMenu from "../../components/Menu/ScreenMenu";

type SigninForm = {
  userId: string;
};

export default function ScreenHome() {
  const { branchCode } = useParams();
  const storeBranchName = useBranchStore((state) => state.branchName);
  const navigate = useNavigate();

  const onQrPage = () => {};
  const onGroupPage = () => {
    navigate(`/screen/${branchCode}/group`);
  };
  //t
  return (
    <div className=" justify-center items-center min-h-screen flex p-5 md:p-20  flex-col    ">
      <img
        src="/main-logo.svg"
        className=" w-56 tv:w-[1000px]  mb-5 "
        alt="logo"
      />
      <h1 className="text-2xl font-bold tv:text-8xl tv:mt-20">
        {storeBranchName}
      </h1>
      <p className="tv:text-6xl tv:mt-5">표시할 화면을 선택하세요</p>
      <ScreenMenu />
    </div>
  );
}
