import { useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faHouse,
  faIdCard,
  faKey,
  faTriangleExclamation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate, cls } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";
import { Part, Tool } from "../../types/models";

interface CategoryFormData {
  id: number;
  name: string;
  active: string;
}

export default function Category() {
  const user = useUserStore((state) => state.user);

  const [mutate, setMutate] = useState(false);

  const [modalType, setModalType] = useState("part");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<CategoryFormData>({
    defaultValues: {
      active: "true",
    },
  });

  const tableHeaderPart = [
    { title: "No." },
    { title: "부위명" },
    { title: "사용여부" },
  ];
  const tableHeaderTool = [
    { title: "No." },
    { title: "도구명" },
    { title: "사용여부" },
  ];
  const showModal = (type: string) => {
    reset();

    setModalType(type);
    window.my_modal_2.showModal();
  };

  const closeModal = () => {
    reset();

    window.my_modal_2.close();
  };

  const onValid = async (formData: CategoryFormData) => {
    try {
      const part = {
        name: formData.name,
        active: formData.active,
      };

      const url = modalType === "part" ? "/api/admin/part" : "/api/admin/tool";

      if (formData.id) {
        await axios.patch(`${url}/${formData.id}`, part);
      } else {
        await axios.post(url, part);
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }

    closeModal();
    setMutate(!mutate);
  };

  const openEditModal = (item: Tool, type: string) => {
    showModal(type);
    setValue("id", item.id);
    setValue("name", item.name);
    setValue("active", item.active.toString());
  };

  const onDelete = async (item: CategoryFormData) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }
    const url = modalType === "part" ? "/api/admin/part" : "/api/admin/tool";

    try {
      await axios.delete(`${url}/${item.id}`);
      closeModal();
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  return (
    <>
      <Modal
        id="my_modal_2"
        title={`${modalType === "part" ? "부위" : "도구"} ${
          watch("id") ? "수정" : "등록"
        }`}
      >
        <FormBox
          title={modalType === "part" ? "부위명" : "도구명"}
          icon={<FontAwesomeIcon icon={faUser} />}
        >
          <FormInput
            placeholder={modalType === "part" ? "부위명" : "도구명"}
            register={register("name", {
              required: `${
                modalType === "part" ? "부위" : "도구"
              }명을 입력해주세요`,
            })}
          />
        </FormBox>
        <FormBox title="사용여부" icon={<FontAwesomeIcon icon={faBuilding} />}>
          <div className="flex space-x-5">
            <Radio
              title="사용"
              value={true}
              register={register("active", {
                required: "사용여부를 선택해주세요",
              })}
            />
            <Radio
              title="미사용"
              value={false}
              register={register("active", {
                required: "사용여부를 선택해주세요",
              })}
            />
          </div>
        </FormBox>

        <div className="w-full flex flex-col justify-center mt-5">
          {watch("id") && (
            <button
              className=" btn w-full bg-black text-white  "
              onClick={() => onDelete(watch())}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              {modalType === "part" ? "부위" : "도구"} 삭제
            </button>
          )}
          <button
            className=" btn-primary btn w-full mt-2   text-white  "
            onClick={handleSubmit(onValid)}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            {`${modalType === "part" ? "부위" : "도구"} ${
              watch("id") ? "수정" : "등록"
            }`}
          </button>
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            운동 카테고리 관리
          </h1>
          <div className="flex justify-center space-x-10">
            <div className="w-full">
              <Table
                initSize={999}
                visiblePagination={false}
                dataApiUrl="/api/admin/part"
                buttonComponent={
                  <button
                    className=" btn-primary btn   text-white  "
                    onClick={() => showModal("part")}
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                    부위 등록
                  </button>
                }
                headerArray={tableHeaderPart}
                mutate={mutate}
                initSort="id"
                initDirection="desc"
              >
                {(data: Part[], startIndex: number) =>
                  data.map((item: Part, index: number) => {
                    return (
                      <tr key={item.id}>
                        <th>{startIndex - index}</th>
                        <td>{item.name}</td>
                        <td
                          className={cls(
                            "",
                            item.active.toString() === "true"
                              ? "text-blue-500"
                              : "text-red-500"
                          )}
                        >
                          {item.active.toString() === "true"
                            ? "사용"
                            : "미사용"}
                        </td>
                        <td>
                          <button
                            onClick={() => openEditModal(item, "part")}
                            className="btn btn-xs"
                          >
                            수정
                          </button>
                        </td>
                      </tr>
                    );
                  })
                }
              </Table>
            </div>
            <div className="w-full">
              <Table
                initSize={999}
                visiblePagination={false}
                dataApiUrl="/api/admin/tool"
                buttonComponent={
                  <button
                    className=" btn-primary btn   text-white  "
                    onClick={() => showModal("tool")}
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                    도구 등록
                  </button>
                }
                headerArray={tableHeaderTool}
                mutate={mutate}
                initSort="id"
                initDirection="desc"
              >
                {(data: Tool[], startIndex: number) =>
                  data.map((item: Tool, index: number) => {
                    return (
                      <tr key={item.id}>
                        <th>{startIndex - index}</th>
                        <td>{item.name}</td>
                        <td
                          className={cls(
                            "",
                            item.active.toString() === "true"
                              ? "text-blue-500"
                              : "text-red-500"
                          )}
                        >
                          {item.active.toString() === "true"
                            ? "사용"
                            : "미사용"}
                        </td>
                        <td>
                          <button
                            onClick={() => openEditModal(item, "tool")}
                            className="btn btn-xs"
                          >
                            수정
                          </button>
                        </td>
                      </tr>
                    );
                  })
                }
              </Table>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
}
