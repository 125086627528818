import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMsg from "../../components/ErrorMsg";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Button from "../../components/interface/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faDumbbell,
  faQrcode,
  faSignIn,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useBranchStore } from "../../store";
import { useEffect, useState } from "react";
import BranchGroupGrid from "../../components/BranchGroupGrid";

import { BranchGroup, BranchSchedule } from "../../types/models";
import { getGroupData } from "../../services/group";
import { AdsDtoWithVideo, BranchScheduleResponse } from "../../types/dto";
import ScreenLayout from "../../components/Layout/ScreenLayout";
import { useWebSocket } from "../../hooks/useWebSocket";
import { getAds } from "../../services/ads";
import AdsSlide from "../../components/Slide/AdsSlide";
import { getScheduleData } from "../../services/branchSchedule";
import { CurrentProgramResponse } from "../../types/response";
import {
  getCurrentContentsIds,
  getCurrentProgram,
} from "../../services/branchStreaming";
import { fetchBranchIdByCode } from "../../services/branch";
import WorkoutScreen from "../../components/WorkoutScreen/WorkoutScreen";
import TopErrorMsg from "../../components/WorkoutScreen/TopErrorMsg";
import AdsSlideWithData from "../../components/AdsSlideWithData";

type SigninForm = {
  userId: string;
};

export default function ScreenWorkout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { branchCode, scheduleId } = useParams();
  const [contentsIds, setContentsIds] = useState<string>();

  //웹소켓 연결을 위한 브랜치 아이디 조회
  const { data: branchId } = useQuery<CurrentProgramResponse, Error>(
    ["fetchBranchIdByCode", branchCode],
    () => fetchBranchIdByCode(branchCode!),
    {
      enabled: !!branchCode,
    }
  );

  //웹소켓 연결
  const [socketData, socketError, client] = useWebSocket(
    `${process.env.REACT_APP_WS_SERVER}/ws/streaming/${branchId}`
  );

  //현재 진행중인 프로그램 정보 상태 가져오기 (페이지 첫 로딩 시 실행)
  const { data, isLoading, isError, refetch } = useQuery<
    CurrentProgramResponse,
    Error
  >(["getCurrentProgram", branchCode], () => getCurrentProgram(branchCode!), {
    enabled: !!branchCode,
  });

  // //현재 진행중인 프로그램의 콘텐츠 ID가져오기
  // const {
  //   data: contentsIdsData,
  //   isLoading: contentsIdsDataLoading,
  //   isError: contentsIdsDataError,
  //   refetch: contentsIdsDataRefetch,
  // } = useQuery<CurrentProgramResponse, Error>(
  //   ["getCurrentContentsIds", branchCode],
  //   () => getCurrentContentsIds(branchCode!),
  //   {
  //     enabled: !!branchCode,
  //   }
  // );

  //수신한 소켓 데이터를 바인딩
  useEffect(() => {
    if (socketData) {
      console.log(socketData);
      const oldData = queryClient.getQueryData<CurrentProgramResponse>([
        "getCurrentProgram",
        branchCode,
      ]);
      let newData;

      switch (socketData.operation) {
        case "playing":
          newData = socketData.data;
          break;
        case "pause":
          newData = socketData.data;
          break;
        case "stop":
          newData = null;
          break;

        default:
          return;
      }

      queryClient.setQueryData(["getCurrentProgram", branchCode], newData);
    }
  }, [socketData, queryClient, branchCode]);

  return (
    <ScreenLayout>
      {socketError && (
        <TopErrorMsg title="서버와의 소켓연결에 문제가 있습니다. 실시간 제어가 불가능합니다." />
      )}
      {data ? (
        <div className="p-20">
          {data && data.contentsIds && (
            <WorkoutScreen
              program={data.program}
              branchStreaming={data.branchStreaming}
              contentsIds={data.contentsIds.toString()}
            />
          )}
        </div>
      ) : (
        <AdsSlideWithData schedule={false} />
      )}
    </ScreenLayout>
  );
}
