import useMutation from "./useMutation";

// 타입 정의
type HandlePlayArgs = {
  id: number; // 추가
  branchCode: string;
  type: string;
  broadcast: boolean;
  operation?: string;
  indexNum?: number;
};

const useUpdateStremingIndex = () => {
  const [updateStreamingPlay, { loading, data }] = useMutation(
    "/api/public/branch/streaming/updateIndex",
    "put",
    true
  );

  const updateIndex = async ({
    id,
    branchCode,
    type,
    broadcast,
    operation = "playing",
    indexNum,
  }: HandlePlayArgs) => {
    await updateStreamingPlay(
      { type, branchCode, broadcast, operation, indexNum },
      id
    ); // id를 두 번째 인자로 넘깁니다.
  };

  return { updateIndex, loading, data };
};

export default useUpdateStremingIndex;
