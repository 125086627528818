import axios from "axios";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import { StringToDate, cls } from "../../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCirclePlus,
  faMagnifyingGlass,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import QueryString from "qs";
import { getTableData, getTableSearchData } from "../../services/table";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Button from "./Button";
import TableSkeleton from "../Skeleton/TableSkeleton";

interface TableProps {
  dataApiUrl: string;
  buttonComponent?: any;
  headerArray: any;
  children: any;
  mutate: boolean;
  initSort: string;
  initDirection: string;
  visiblePagination?: boolean;
  initSize?: number;
  searchData?: any;
  searchApiUrl?: string;
  theme?: string;
  branchCode?: string;
}

interface SearchParams {
  [key: string]: string[] | null;
}

export default function Table({
  dataApiUrl,
  buttonComponent,
  headerArray,
  children,
  mutate,
  initSort,
  initDirection,
  visiblePagination = true,
  initSize = 10,
  searchData,
  searchApiUrl,
  theme = "table",
  branchCode,
}: TableProps) {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(initSize);

  const [totalElements, setTotalElements] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [tableHeaderWithDirection, setTableHeaderWithDirection] =
    useState<any>();

  const [changeValue, setChangeValue] = useState(initSort);
  const [changeDirection, setChangeDirection] = useState(initDirection);

  // 상태 초기화
  const [searchDisplayName, setSearchDisplayName] = useState<SearchParams>({});
  const [searchParams, setSearchParams] = useState<SearchParams>({});

  const [searchMode, setSearchMode] = useState(false);

  const [searchResponseData, setSearchResponseData] = useState<any>();
  const [searchLoading, setSearchLoading] = useState(false);

  const { isLoading, isError, data, error, refetch } = useQuery(
    [
      "getTableData",
      page,
      size,
      changeValue,
      changeDirection,
      dataApiUrl,
      branchCode,
    ],
    () =>
      getTableData(
        page,
        size,
        changeValue,
        changeDirection,
        dataApiUrl,
        branchCode
      ),
    { enabled: searchMode === false }
  );

  const refetchSearchData = async () => {
    if (!searchApiUrl) {
      return;
    }
    setSearchLoading(true);

    const getSearch = await getTableSearchData(
      page,
      size,
      changeValue,
      changeDirection,
      searchApiUrl,
      searchParams
    );

    setSearchResponseData(getSearch);
    setSearchLoading(false);
  };

  useEffect(() => {
    if (searchMode === true) {
      refetchSearchData(); // if there are search parameters, fetch the search data
    } else {
      refetch(); // if there are no search parameters, fetch the normal data
    }
  }, [page, size, changeValue, changeDirection, mutate]);

  //유저가 검색중인지 판단
  useEffect(() => {
    const isEmpty = (obj: any) => {
      return (
        !obj ||
        Object.keys(obj).length === 0 ||
        (Object.keys(obj).length === 1 && obj.name === "")
      );
    };

    if (isEmpty(searchParams)) {
      setSearchMode(false);
    } else {
      setSearchMode(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchMode === false) {
      setSearchResponseData(undefined);
    }
  }, [searchMode]);

  useEffect(() => {
    let tableHeader = headerArray;
    const updatedHeader = tableHeader.map((item: any) => ({
      ...item,
      direction: item.value === initSort ? initDirection : "asc",
    }));

    setTableHeaderWithDirection(updatedHeader);
  }, [headerArray]);

  useEffect(() => {
    if (searchMode === true && searchResponseData) {
      setTotalElements(searchResponseData.totalElements);
      setStartIndex(searchResponseData.totalElements - page * size);
    } else if (data) {
      setTotalElements(data.totalElements);
      setStartIndex(data.totalElements - page * size);
    }
  }, [data, searchMode, searchResponseData]);

  const handleSizeChange = (event: any) => {
    const newSize = parseInt(event.target.value);
    setSize(newSize);
  };

  const handleSort = (value: string, direction: string) => {
    const updatedHeader = tableHeaderWithDirection.map((item: any) => {
      if (item.value === value) {
        // If the value matches, toggle the direction
        return {
          ...item,
          direction: direction,
        };
      } else {
        // Otherwise, set the direction to 'asc'
        return { ...item, direction: "asc" };
      }
    });

    setTableHeaderWithDirection(updatedHeader);
    setChangeValue(value);
    setChangeDirection(direction);
  };

  const handleKeyPress = async (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault(); // avoid form submission if in form
      handleSearch();
    }
  };

  const handleSearch = async () => {
    refetchSearchData();
  };

  const handleSearchCategoryChange = (
    key: string,
    value: string,
    name: string
  ) => {
    if (value === "all") {
      setSearchParams((prev: SearchParams) => {
        const { [key]: _, ...rest } = prev;
        return rest;
      });
      setSearchDisplayName((prev: SearchParams) => {
        const { [key]: _, ...rest } = prev;
        return rest;
      });
    } else {
      setSearchParams((prev: SearchParams) => {
        let prevValues = prev[key];
        let newValues;

        if (Array.isArray(prevValues)) {
          if (prevValues.includes(value)) {
            newValues = prevValues.filter((item) => item !== value);
          } else {
            newValues = [...prevValues, value];
          }
        } else {
          newValues = [value];
        }

        if (newValues.length === 0) {
          const { [key]: _, ...rest } = prev;
          return rest;
        } else {
          return {
            ...prev,
            [key]: newValues,
          };
        }
      });

      setSearchDisplayName((prev: SearchParams) => {
        let prevNames = prev[key];
        let newNames;

        if (Array.isArray(prevNames)) {
          if (prevNames.includes(name)) {
            newNames = prevNames.filter((item) => item !== name);
          } else {
            newNames = [...prevNames, name];
          }
        } else {
          newNames = [name];
        }

        if (newNames.length === 0) {
          const { [key]: _, ...rest } = prev;
          return rest;
        } else {
          return {
            ...prev,
            [key]: newNames,
          };
        }
      });
    }
  };

  // 선택 이벤트 핸들러
  const handleSearchTermChange = (event: any) => {
    const newValue = event.target.value;
    setSearchParams((prev) => ({
      ...prev,
      name: newValue,
    }));
  };

  return (
    <div className="bg-white drop-shadow-md p-10 ">
      <div className=" justify-end flex w-full text-sm items-center text-gray-300 ">
        {visiblePagination && (
          <>
            <div className="flex space-x-2">
              <span>페이지 당</span>
              <select
                className="select select-bordered select-xs w-[60px]"
                onChange={handleSizeChange}
              >
                <option selected={initSize === 10}>10</option>
                <option selected={initSize === 20}>20</option>
                <option selected={initSize === 30}>30</option>
                <option selected={initSize === 40}>40</option>
                <option selected={initSize === 50}>50</option>
              </select>
              <span>개씩 보기</span>
            </div>
            <div className="ml-10">
              <Pagination
                totalCount={totalElements}
                page={page + 1}
                setPage={setPage}
                pageSize={size}
              />
            </div>
          </>
        )}
      </div>
      {searchData ? (
        <div className="flex justify-between my-10 items-center  ">
          <div className="flex space-x-5 items-end  ">
            {searchData?.map((search: any) => (
              <div className="dropdown z-[99]">
                <label className="label">
                  <span className="label-text-alt">{search.title}</span>
                </label>
                <label
                  tabIndex={0}
                  className="select select-bordered select-sm w-[128px]   items-center"
                >
                  <p className="truncate">
                    {searchParams && searchParams[search.searchFiled]
                      ? searchDisplayName[search.searchFiled]!.join(", ")
                      : "전체"}
                  </p>
                </label>
                <ul
                  tabIndex={0}
                  className={`dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box   ${
                    search?.value?.length >= 6
                      ? "grid grid-cols-2 gap-2 w-96"
                      : ""
                  }`}
                >
                  <li>
                    <div
                      onClick={() =>
                        handleSearchCategoryChange(
                          search.searchFiled,
                          "all",
                          "전체"
                        )
                      }
                    >
                      전체
                    </div>
                  </li>
                  {search?.value?.map((val: any, index: number) => (
                    <li key={index}>
                      <div
                        className={cls(
                          "",
                          searchParams[search.searchFiled]?.includes(
                            val[search.searchKey]
                          )
                            ? "bg-slate-900 text-white"
                            : "bg-white text-black"
                        )}
                        onClick={() =>
                          handleSearchCategoryChange(
                            search.searchFiled,
                            val[search.searchKey],
                            val.name
                          )
                        }
                      >
                        {val.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="flex space-x-2 items-end pb-1">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                type="text"
                placeholder="검색어를 입력하세요"
                className=" min-w-[300px] max-w-[500px]  border-b focus:outline-none"
                onKeyPress={handleKeyPress}
                onChange={handleSearchTermChange}
              />
              <div>
                <div
                  className=" btn-primary btn btn-sm w-fit  text-white  "
                  onClick={handleSearch}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex ">{buttonComponent}</div>
        </div>
      ) : (
        <div className="flex justify-end my-10 ">{buttonComponent}</div>
      )}
      {theme === "table" && (
        <table className="table">
          <thead>
            <tr>
              {tableHeaderWithDirection?.map(
                ({ title, value, direction }: any) => (
                  <th
                    onClick={() =>
                      value &&
                      handleSort(value, direction === "desc" ? "asc" : "desc")
                    }
                    className={value && "cursor-pointer "}
                  >
                    <span className="mr-2">{title}</span>
                    {direction === "desc" && value && (
                      <FontAwesomeIcon icon={faArrowUp} />
                    )}
                    {direction === "asc" && value && (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {searchLoading ? (
              <TableSkeleton rowSize={5} headerSize={headerArray.length} />
            ) : searchMode && searchResponseData ? (
              children(searchResponseData.content, startIndex)
            ) : isLoading ? (
              <TableSkeleton rowSize={10} headerSize={headerArray.length} />
            ) : (
              children(data.content, startIndex)
            )}
          </tbody>
        </table>
      )}

      {theme === "gallery" && (
        <div className="flex flex-wrap">
          {searchLoading ? (
            Array.from({ length: 9 }).map((_, cellIndex) => (
              <div className="w-[160px] h-[144px] skeleton-box rounded-lg m-1 shadow-md"></div>
            ))
          ) : searchMode && searchResponseData ? (
            children(searchResponseData.content, startIndex)
          ) : isLoading ? (
            <div className="w-[160px] h-[144px] skeleton-box rounded-lg"></div>
          ) : (
            children(data.content, startIndex)
          )}
        </div>
      )}
    </div>
  );
}
