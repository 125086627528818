import React, { useEffect, useState } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import useScrollToTop from "./libs/useScrollToTop";
import Home from "./pages/Home";
import AdminHome from "./pages/Admin/AdminHome";
import PrivateRoute from "./components/Route/PrivateRoute";
import axios from "axios";
import AdminBranch from "./pages/Admin/AdminBranch";
import { useUserStore } from "./store";
import BranchHome from "./pages/Branch/BrnachHome";
import Contents from "./pages/Admin/Contents";
import Category from "./pages/Admin/Category";
import Program from "./pages/Admin/Program/Program";
import AddProgram from "./pages/Admin/Program/AddProgram";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Schedule from "./pages/Admin/Schedule";
import Statistics from "./pages/Admin/Statistics";
import Ads from "./pages/Admin/Ads";
import { UserAccountDto } from "./types/dto";
import BranchSchedule from "./pages/Branch/BranchSchedule";
import { BranchRoute } from "./components/Route/BranchRoute";
import BranchAssign from "./pages/Branch/BranchAssign";
import BranchProgram from "./pages/Branch/BranchProgram";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScreenLogin from "./pages/Screen/ScreenLogin";
import ScreenHome from "./pages/Screen/ScreenHome";
import { ScreenRoute } from "./components/Route/ScreenRoute";
import ScreenGroup from "./pages/Screen/ScreenGroup";
import ScreenGroupDetail from "./pages/Screen/ScreenGroupDetail";
import ScreenAds from "./pages/Screen/ScreenAds";
import ScreenQr from "./pages/Screen/ScreenQr";
import ScreenRegister from "./pages/Screen/ScreenRegister";
import BranchController from "./pages/Branch/BranchController";
import ScreenWorkout from "./pages/Screen/ScreenWorkout";
import BranchAccount from "./pages/Branch/BranchAccount";
import ScheduleTemplate from "./pages/Admin/ScheduleTemplate";
import ScheduleTemplateDetail from "./pages/Admin/ScheduleTemplateDetail";
import BranchScheduleLog from "./pages/Branch/BranchScheduleLog";

function ScrollToTop() {
  //페이지 이동 시 최상단으로 스크롤
  useScrollToTop();

  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const setUser = useUserStore((state) => state.setUser);

  const [isGetInfo, setIsGetInfo] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const getMyInfo = await axios.get("/api/myinfo", {
          withCredentials: true,
        });

        const myInfo: UserAccountDto = getMyInfo.data;

        setUser(myInfo); //로그인 유저 정보 전역저장

        if (myInfo.branchCode === "main") {
          //본사 관리자
          setIsAdmin(true);
          setIsUser(true);
        } else if (myInfo.branchCode !== "main") {
          //지점 관리자
          setIsAdmin(false);
          setIsUser(true);
        } else {
          //기타
          setIsAdmin(false);
          setIsUser(false);
        }
      } catch (error) {
        //권한 없음 (미로그인)
        console.error("Error checking authentication:", error);
        setIsAdmin(false);
        setIsUser(false);
      }

      setIsGetInfo(true);
    };

    getUserInfo();
  }, []);

  if (isGetInfo) {
    return (
      <Routes>
        {/* 인증을 반드시 하지 않아야만 접속 가능한 페이지 정의 */}
        <Route
          element={
            <PrivateRoute
              authentication={false}
              isAuthenticated={isAdmin || isUser}
              redirectPage={isAdmin ? "/admin" : `/branch`}
            />
          }
        >
          <Route path="/" element={<Home />} />
        </Route>

        {/* 어드민 인증을 반드시 해야지만 접속 가능한 페이지 정의 */}
        <Route
          element={
            <PrivateRoute
              authentication={true}
              isAuthenticated={isAdmin}
              redirectPage="/"
            />
          }
        >
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/admin/branch" element={<AdminBranch />} />
          <Route path="/admin/category" element={<Category />} />
          <Route path="/admin/contents" element={<Contents />} />
          <Route path="/admin/program" element={<Program />} />
          <Route path="/admin/program/add" element={<AddProgram />} />
          <Route
            path="/admin/program/edit/:programId"
            element={<AddProgram />}
          />
          <Route path="/admin/schedule" element={<Schedule />} />
          <Route path="/admin/statistics" element={<Statistics />} />
          <Route path="/admin/ads" element={<Ads />} />
          <Route path="/admin/template" element={<ScheduleTemplate />} />
          <Route
            path="/admin/template/edit/:templateId"
            element={<ScheduleTemplateDetail />}
          />
        </Route>

        {/* 지점 관리자만 겁근 가능한 페이지, BranchRoute를 통해 본인 지점만 접근가능 */}
        <Route
          element={
            <PrivateRoute
              authentication={true}
              isAuthenticated={isAdmin || isUser}
              redirectPage="/"
            />
          }
        >
          <Route path="/branch" element={<BranchHome />} />
          <Route
            path="/branch/:branchCode/*"
            element={
              <BranchRoute>
                <Routes>
                  <Route path="/" element={<BranchController />} />
                  <Route path="assign" element={<BranchSchedule />} />
                  <Route path="assign/:scheduleId" element={<BranchAssign />} />
                  <Route path="program" element={<BranchProgram />} />
                  <Route path="account" element={<BranchAccount />} />
                  <Route path="template" element={<ScheduleTemplate />} />
                  <Route
                    path="template/edit/:templateId"
                    element={<ScheduleTemplateDetail />}
                  />
                  <Route path="assign/log" element={<BranchScheduleLog />} />
                </Routes>
              </BranchRoute>
            }
          />
        </Route>

        <Route path="/screen" element={<ScreenLogin />} />

        {/* 로그인 없이 접근 가능,, ScreenRoute를 통해 지점명 전역 저장*/}
        <Route
          path="/screen/:branchCode/*"
          element={
            <ScreenRoute>
              <Routes>
                <Route path="/" element={<ScreenHome />} />
                <Route path="workout" element={<ScreenWorkout />} />
                <Route path="group" element={<ScreenGroup />} />
                <Route
                  path="group/:scheduleId"
                  element={<ScreenGroupDetail />}
                />
                <Route path="ads" element={<ScreenAds />} />
                <Route path="qr" element={<ScreenQr />} />
                <Route path="register" element={<ScreenRegister />} />
              </Routes>
            </ScreenRoute>
          }
        />
      </Routes>
    );
  } else {
    return <div></div>;
  }
}
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <Router>
          <ScrollToTop />
        </Router>
      </DndProvider>
    </QueryClientProvider>
  );
}

export default App;
