import axios from "axios";

export const fetchBranches = async () => {
  const { data } = await axios.get("/api/public/branches/all");
  return data;
};

export async function fetchBranchNameByCode(
  branchCode: string
): Promise<string> {
  const { data } = await axios.get(`/api/public/branches/${branchCode}`);
  return data;
}

export async function fetchBranchIdByCode(branchCode: string) {
  const { data } = await axios.get(`/api/public/branches/get-id/${branchCode}`);
  return data;
}

export async function getBranchData(branchCode: string) {
  const { data } = await axios.get(`/api/branch/settings/${branchCode}`);
  return data;
}
