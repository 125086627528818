import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/AdminLayout";
import ProgramDetail from "../../../components/ProgramDetail";

export default function AddProgram() {
  const { programId } = useParams();
  const navigate = useNavigate();
  const onBackBtn = () => {
    navigate(`/admin/program`);
  };
  return (
    <>
      <AdminLayout>
        <div className="flex space-x-5">
          <h1
            onClick={onBackBtn}
            className="text-gray-800 font-bold  text-2xl cursor-pointer"
          >
            운동 프로그램 관리
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            -
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            운동 프로그램 {programId ? "수정" : "등록"}
          </h1>
        </div>
        <ProgramDetail programId={programId} />
      </AdminLayout>
    </>
  );
}
