import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMsg from "../../components/ErrorMsg";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Button from "../../components/interface/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faDumbbell,
  faQrcode,
  faSignIn,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useBranchStore } from "../../store";
import { useEffect, useState } from "react";
import BranchGroupGrid from "../../components/BranchGroupGrid";

import { BranchGroup, BranchSchedule } from "../../types/models";
import { getGroupData } from "../../services/group";
import { AdsDtoWithVideo, BranchScheduleResponse } from "../../types/dto";
import ScreenLayout from "../../components/Layout/ScreenLayout";
import { useWebSocket } from "../../hooks/useWebSocket";
import { getAds } from "../../services/ads";
import AdsSlide from "../../components/Slide/AdsSlide";
import { getScheduleData } from "../../services/branchSchedule";
import QRCode from "qrcode.react";
import TopErrorMsg from "../../components/WorkoutScreen/TopErrorMsg";

type SigninForm = {
  userId: string;
};

export default function ScreenGroupDetail() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { branchCode, scheduleId } = useParams();

  const {
    data: scheduleData,
    isLoading: scheduleDataLoading,
    isError: scheduleDataError,
  } = useQuery<BranchScheduleResponse, Error>(
    ["getScheduleData", scheduleId],
    () => getScheduleData(scheduleId!, true),
    {
      enabled: !!scheduleId,
    }
  );

  const { data, isLoading, isError, refetch } = useQuery<BranchGroup[], Error>(
    ["getGroupData", scheduleId],
    () => getGroupData(scheduleId!),
    {
      enabled: !!scheduleId,
    }
  );

  //웹소켓 연결
  const [socketData, socketError, client]: any = useWebSocket(
    `${process.env.REACT_APP_WS_SERVER}/ws/group/${scheduleId}`
  );

  //웹소켓으로 수신한 데이터를 등록
  useEffect(() => {
    if (socketData) {
      console.log(socketData);
      const oldData = queryClient.getQueryData<BranchGroup[]>([
        "getGroupData",
        scheduleId,
      ]);

      let newData;
      switch (socketData.operation) {
        case "post":
          newData = [...(oldData || []), socketData.data];
          break;
        case "delete":
          newData = (oldData || []).filter(
            (item) => item.id !== socketData.data.id
          );
          break;
        case "update":
          newData = (oldData || []).map((item) =>
            item.id === socketData.data.id ? socketData.data : item
          );
          break;
        default:
          return;
      }

      queryClient.setQueryData(["getGroupData", scheduleId], newData);
    }
  }, [socketData, queryClient, scheduleId]);

  //수업종료시간 체크 후, 5분 경과시 리다이렉트
  useEffect(() => {
    const interval = setInterval(() => {
      if (scheduleData && scheduleData.startTime) {
        const startTime = new Date(scheduleData.startTime);

        // startTime에서 30분을 밀리초로 환산하여 더합니다.
        const thirtyMinutesAfterStart = new Date(
          startTime.getTime() + 30 * 60 * 1000
        );

        console.log(new Date()); // 현재 시각을 콘솔에 기록합니다.
        console.log(thirtyMinutesAfterStart); // 30분 후 시각을 콘솔에 기록합니다.

        // 현재 시각이 startTime에서 30분이 지난 시각보다 뒤일 경우
        if (new Date() > thirtyMinutesAfterStart) {
          navigate(`/screen/${branchCode}/group`); // 다른 화면으로 리디렉션합니다.
        }
      }
    }, 5000); // 5초마다 실행

    // 컴포넌트가 언마운트될 때 타이머를 정리합니다.
    return () => {
      clearInterval(interval);
    };
  }, [scheduleData]);

  // const { data: adsData, isLoading: adsDataLoading } = useQuery<
  //   AdsDtoWithVideo[],
  //   Error
  // >(["getAds"], () => getAds(), {});

  const [qrUrl, setQrUrl] = useState<string>();

  useEffect(() => {
    const url = new URL(window.location.href);

    const qrLink = `${url.origin}/screen/${branchCode}/register`;
    setQrUrl(qrLink);
  }, []);

  return (
    <ScreenLayout>
      {socketError && (
        <TopErrorMsg title="서버와의 소켓연결에 문제가 있습니다. 실시간 통신이 불가능합니다." />
      )}
      {data ? (
        <div className="bg-black  w-full flex flex-col ">
          <div className="py-14 flex justify-center items-center">
            <h1 className="font-bold  text-7xl text-white">
              {scheduleData?.startTime?.replace("T", " ").slice(0, -3)} -{" "}
              {scheduleData?.programCategory}
            </h1>
          </div>
          <div className="flex justify-center items-center px-[500px] ">
            <BranchGroupGrid
              headcount={Number(scheduleData?.headcount)}
              data={data}
              refetch={refetch}
              scheduleId={scheduleId}
              readonly={true}
              size="tv"
            />
          </div>
          <div className="text-white mt-24  text-9xl font-bold flex text-center justify-center items-center space-x-20">
            <h1>수업 전 반드시 QR코드로 그룹을 배정받으세요!</h1>
            <div className="bg-white p-5 ">
              {qrUrl && <QRCode value={qrUrl} size={300} />}
            </div>
          </div>
        </div>
      ) : (
        <div className=" flex justify-center items-center min-h-screen">
          <p>가까운 수업이 없습니다!</p>
        </div>
      )}
    </ScreenLayout>
  );
}
