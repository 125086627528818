import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { ContentsWithOnlyVideoDto } from "../../types/dto";
import { useEffect, useState } from "react";
import { cls } from "../../libs/utils";
import { LazyLoadVideo } from "../LazyLoadVideo";
interface NonMainScreenProps {
  data: ContentsWithOnlyVideoDto[];
  currentIndex: number;
}

export default function MainScreen({ data, currentIndex }: NonMainScreenProps) {
  const [translateX, setTranslateX] = useState(0);
  const gridWidth = 1500; // 그리드 칸 하나의 넓이, 각 칸이 같은 넓이라고 가정합니다.
  const gridFullWidth = gridWidth * 2; //그리드 전체 넓이
  const [slideCounter, setSlideCounter] = useState(0);
  const [realData, setRealData] = useState([...data, { type: "end" }]);

  useEffect(() => {
    setSlideCounter(Math.floor(currentIndex / 2));
  }, [currentIndex]);

  useEffect(() => {
    setTranslateX((-gridWidth - 40) * slideCounter);
  }, [slideCounter]);

  console.log(`slideCounter: `, slideCounter);

  return (
    <div
      className={`flex  w-[3040px] h-[1727.5px] overflow-hidden mx-auto flex-col `}
    >
      <div
        className={` absolute grid grid-cols-2 gap-10 z-50 w-[3040px] h-[1727.5px] `}
      >
        {["A", "B", "C", "Next"].map((item, i) => {
          return (
            <div
              key={i}
              className={` w-[1500px] h-[843.75px]  flex justify-between p-5  `}
            >
              <h1 className="text-blaxk text-9xl font-bold">{item}</h1>
            </div>
          );
        })}
      </div>
      <div className="relative grid grid-flow-col auto-cols-[1500px] gap-10">
        {realData.map((item: any, i) => {
          const shouldDisplayVideo =
            i >= slideCounter - 1 && i <= slideCounter + 4;

          return (
            <motion.div
              animate={{ x: translateX }}
              transition={{ duration: 0.5 }}
              key={i}
            >
              <motion.div
                animate={
                  i > 1
                    ? i <= slideCounter + 1
                      ? { x: 0, y: 0 }
                      : { x: -3080, y: 883.75 }
                    : { x: 0, y: 0 }
                }
                transition={{ duration: 1 }}
                initial={{
                  x: i > 1 ? -3080 : 0,
                  y: i > 1 ? 883.75 : 0,
                }}
                className="w-full inline-flex relative "
              >
                {item.type === "end" ? (
                  <div className="flex justify-center items-center w-full h-[843.75px] bg-white">
                    <h1 className="text-black text-9xl font-bold">END</h1>
                  </div>
                ) : (
                  <>
                    <h1 className="text-black text-7xl font-bold absolute right-0 p-5  ">
                      {item.content.name}
                    </h1>
                    {item.content.divisionAction && (
                      <h1 className="text-blue-400 text-7xl font-bold text-right absolute bottom-0 right-0 p-5 whitespace-pre-wrap  ">
                        {item.content.divisionAction}
                      </h1>
                    )}
                    {item.content.icon && (
                      <div className="flex absolute bottom-0 left-0 m-5 space-x-5">
                        {item.content.icon.split(",").includes("L") && (
                          <h1 className="text-white rounded-full text-7xl font-bold  bg-black w-32 h-32 justify-center items-center pb-3 flex ">
                            L
                          </h1>
                        )}
                        {item.content.icon.split(",").includes("R") && (
                          <h1 className="text-white rounded-full text-7xl font-bold  bg-black w-32 h-32 justify-center items-center pb-3 flex ">
                            R
                          </h1>
                        )}
                      </div>
                    )}

                    {shouldDisplayVideo && (
                      <video
                        src={item ? item.video?.fileLink || "" : ""}
                        className="bg-white h-[843.75px] w-full"
                        autoPlay
                        loop
                        muted
                      />
                    )}
                  </>
                )}
              </motion.div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
