import { useEffect } from "react";
import { useUserStore } from "../../store";
import { useNavigate } from "react-router-dom";

export default function BranchHome() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.branchCode) {
      window.location.href = `/branch/${user.branchCode}`;
    }
  }, [user]);

  return <div></div>;
}
