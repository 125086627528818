import {
  faBullhorn,
  faCalendarDays,
  faDumbbell,
  faHouse,
  faNoteSticky,
  faPersonBiking,
  faPersonWalking,
  faPlay,
  faSquarePollVertical,
  faTv,
  faTvAlt,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

export const adminMenu = [
  { icon: faHouse, title: "지점 관리", url: "/admin/branch" },
  { icon: faUser, title: "관리자 계정 관리", url: "/admin" },
  {
    icon: faPersonWalking,
    title: "운동 카테고리 관리",
    url: "/admin/category",
  },
  { icon: faDumbbell, title: "운동 콘텐츠 관리", url: "/admin/contents" },
  {
    icon: faPersonBiking,
    title: "운동 프로그램 관리",
    url: "/admin/program",
  },
  {
    icon: faCalendarDays,
    title: "일별 프로그램 관리",
    url: "/admin/schedule",
  },
  {
    icon: faSquarePollVertical,
    title: "프로그램 예약 통계",
    url: "/admin/statistics",
  },
  { icon: faBullhorn, title: "광고 관리", url: "/admin/ads" },
  { icon: faCalendarDays, title: "시간표 템플릿", url: "/admin/template" },
  { icon: faTv, title: "모니터 출력 화면", url: "/screen" },
];

// URL에서 branchCode 추출
const currentPath = window.location.pathname;
const pathSegments = currentPath.split("/");
const branchCodeFromUrl = pathSegments[2]; // 두 번째 세그먼트

export const branchMenu = [
  {
    icon: faPlay,
    title: "동작 재생",
    url: `/branch/${branchCodeFromUrl}`,
  },
  {
    icon: faUserGroup,
    title: "예약자 그룹 배정 / 시간표 관리",
    url: `/branch/${branchCodeFromUrl}/assign`,
  },
  {
    icon: faCalendarDays,
    title: "일별 프로그램",
    url: `/branch/${branchCodeFromUrl}/program`,
  },
  {
    icon: faTv,
    title: "모니터 출력 화면",
    url: `/screen/${branchCodeFromUrl}`,
  },
];

export const branchMenuWithAdmin = [
  {
    icon: faPlay,
    title: "동작 재생",
    url: `/branch/${branchCodeFromUrl}`,
  },
  {
    icon: faUserGroup,
    title: "예약자 그룹 배정 / 시간표 관리",
    url: `/branch/${branchCodeFromUrl}/assign`,
  },
  {
    icon: faCalendarDays,
    title: "일별 프로그램",
    url: `/branch/${branchCodeFromUrl}/program`,
  },
  {
    icon: faUser,
    title: "지점 계정관리",
    url: `/branch/${branchCodeFromUrl}/account`,
  },
  {
    icon: faCalendarDays,
    title: "시간표 템플릿",
    url: `/branch/${branchCodeFromUrl}/template`,
  },
  {
    icon: faTv,
    title: "모니터 출력 화면",
    url: `/screen/${branchCodeFromUrl}`,
  },
];
