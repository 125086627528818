import { cls } from "../../libs/utils";

interface ModalProps {
  id?: string;
  title?: string;
  children: React.ReactNode;
  size?: string;
  closeClickedOutside?: boolean;
  overflow?: string;
}

export default function Modal({
  id,
  title,
  children,
  size = "default",
  closeClickedOutside = false,
  overflow = "visible",
}: ModalProps) {
  return (
    <dialog id={id} className="modal">
      <form
        method="dialog"
        className={cls(
          "modal-box",
          size === "large"
            ? `max-w-5xl min-w-[64rem] ${overflow === "visible" ? "" : ""}`
            : size === "xlarge"
            ? `max-w-7xl min-w-[64rem]  ${
                overflow === "visible" ? "overflow-visible" : ""
              }`
            : size === "fit"
            ? "max-w-fit min-w-[64rem] "
            : size === "full"
            ? " min-w-fit"
            : ""
        )}
      >
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 tv:btn-lg">
          ✕
        </button>
        <h3 className="font-bold text-lg text-gray-800 tv:text-5xl ">
          {title}
        </h3>

        {children}
      </form>

      <form method="dialog" className="modal-backdrop">
        {closeClickedOutside && <button>close</button>}
      </form>
    </dialog>
  );
}
