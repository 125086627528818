import axios from "axios";

export const getAds = async () => {
  try {
    const { data } = await axios.get("/api/public/ads");
    return data;
  } catch (error) {
    console.error(error);
  }
};
