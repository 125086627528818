import { useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faCode,
  faHouse,
  faIdCard,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { BranchDto } from "../../types/dto";

interface UserFormData {
  id?: number;
  name: string;
  code: string;
}

export default function AdminBranch() {
  const [mutate, setMutate] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<UserFormData>();

  const tableHeader = ["No.", "지점명", "지점코드", "수정"];
  const showModal = () => {
    reset();
    window.my_modal_2.showModal();
  };

  const closeModal = () => {
    window.my_modal_2.close();
  };

  const onValid = async (formData: UserFormData) => {
    try {
      if (formData.id) {
        const response = await axios.put(`/api/admin/branches/${formData.id}`, {
          name: formData.name,
          code: formData.code,
        });
      } else {
        const response = await axios.post(`/api/admin/branches`, {
          name: formData.name,
          code: formData.code,
        });
      }

      setMutate(!mutate);
      closeModal();
    } catch (error: any) {
      if (error.message) {
        console.log(error);
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  const openEditModal = (item: BranchDto) => {
    if (item.code === "main") {
      alert("본사는 수정할 수 없어요");
      return;
    }

    showModal();
    setValue("id", item.id);
    setValue("code", item.code);
    setValue("name", item.name);
  };

  const onDelete = async (item: BranchDto) => {
    if (item.code === "main") {
      alert("본사는 삭제할 수 없어요");
      return;
    }

    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      const response = await axios.delete(`/api/admin/branches/${item.id}`);
      console.log(response);
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  return (
    <>
      <Modal id="my_modal_2" title={`지점 ${watch("id") ? "수정" : "등록"}`}>
        <FormBox title="지점명" icon={<FontAwesomeIcon icon={faHouse} />}>
          <FormInput
            placeholder="강남점"
            register={register("name", {
              required: "지점 또는 본사를 선택해주세요",
            })}
          />
        </FormBox>
        <FormBox
          toolTip="지점 도메인으로 사용되어요. (eg: /gangnam)"
          title="지점코드"
          icon={<FontAwesomeIcon icon={faCode} />}
        >
          <FormInput
            placeholder="gangnam"
            register={register("code", {
              required: "지점 또는 본사를 선택해주세요",
            })}
          />
        </FormBox>

        <div className="w-full justify-center flex mt-5">
          <button
            className=" btn-primary btn w-full   text-white  "
            onClick={handleSubmit(onValid)}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            {`지점 ${watch("id") ? "수정" : "등록"}`}
          </button>
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            지점 관리
          </h1>
          <Table
            dataApiUrl="/api/admin/branches"
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={showModal}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                지점 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="id"
            initDirection="asc"
          >
            {(data: BranchDto[], startIndex: number) =>
              data.map((item: BranchDto, index: number) => {
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>

                    <td>{item.name}</td>
                    <td>{item.code}</td>

                    <td className="flex space-x-2">
                      {item.code !== "main" && (
                        <>
                          <button
                            onClick={() => openEditModal(item)}
                            className="btn btn-xs"
                          >
                            수정
                          </button>
                          <button
                            onClick={() => onDelete(item)}
                            className="btn btn-xs btn-error"
                          >
                            삭제
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}
