import create from "zustand";
import { w3cwebsocket as W3CWebSocket } from "websocket";

// 스토어 상태에 대한 인터페이스 정의
interface WebSocketStoreState {
  clients: { [url: string]: W3CWebSocket | undefined };
  setClient: (url: string, client: W3CWebSocket | undefined) => void;
}

// 인터페이스를 사용하여 스토어 생성
const useWebSocketStore = create<WebSocketStoreState>((set) => ({
  clients: {},
  setClient: (url: string, client: W3CWebSocket | undefined) =>
    set((state) => ({
      clients: { ...state.clients, [url]: client },
    })),
}));

export default useWebSocketStore;
