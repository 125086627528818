import { Program } from "../../types/models";

export default function ProgramName({
  program,
  type,
}: {
  program: Program;
  type?: string;
}) {
  return (
    <div>
      <h1 className="font-bold text-xl">
        {program.programType} {type === "tutorial" && "설명"}
      </h1>
      <h2 className="text-sm">
        [{program.programCategory}] {program.name}
      </h2>
    </div>
  );
}
