import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import {
  AdsDtoWithVideo,
  ProgramDto,
  ScheduleTemplateDto,
} from "../../types/dto";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClock,
  faFile,
  faGear,
  faImage,
  faPenToSquare,
  faPlus,
  faTag,
  faTrash,
  faUnderline,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/interface/Modal";
import ContentsGapDrag from "../../components/ContentGapDrag";
import { Ads, BranchSchedule } from "../../types/models";
import FormBox from "../../components/interface/FormBox";
import Radio from "../../components/interface/Radio";
import { useForm } from "react-hook-form";
import FormInput from "../../components/interface/FormInput";
import Button from "../../components/interface/Button";
import {
  uploadVideoToVimeo,
  uploadVideoWithVimeoUrl,
} from "../../services/VimeoUpload";
import axios from "axios";
import AdsBoxDrag from "../../components/AdsBoxDrag";
import { uploadImageDirectly } from "../../services/ImageUpload";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAds } from "../../services/ads";
import useMutation from "../../hooks/useMutation";
import {
  getTemplate,
  getTemplateDetail,
  getTemplateScheduleDetail,
} from "../../services/template";
import { useNavigate, useParams } from "react-router-dom";
import { getBranchSchedules } from "../../services/branchSchedule";
import { getProgramCategoryByDateRange } from "../../services/schedule";
import BranchAddTimeModal from "../../components/Modal/BranchAddTimeModal";
import ScheduleSettingsModal from "../../components/Modal/ScheduleSettingsModal";
import WeekButton from "../../components/WeekButton";
import BranchScheduleBox from "../../components/BranchScheduleBox";
import {
  GetHHMM,
  cls,
  convertToHHMM,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../../libs/utils";
import TemplateAddTimeModal from "../../components/Modal/TemplateAddModal";
import TemplateBulkModal from "../../components/Modal/TemplateBulkModal";

interface TemplateFormData {
  id: number;
  title: string;
  description: string;
}

export default function ScheduleTemplateDetail() {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { data } = useQuery(
    ["getTemplateDatail", templateId],
    () => getTemplateDetail(templateId!),
    { enabled: templateId !== undefined }
  );
  const { branchCode } = useParams();

  const [addModalDate, setAddModalDate] = useState<string>("");

  const [addModalIsOpen, setIsModalIsOpen] = useState(false);
  const [scheduleSettingslIsOpen, setScheduleSettingsIsOpen] = useState(false);

  const [dropdown, setDropdown] = useState({
    show: false,
    x: 0,
    y: 0,
  });
  const [selectData, setSelectData] = useState<any>();
  const [scheduleLoading, setSheduleLoading] = useState<number[]>([]);
  const [isEnableEdit, setIsEnableEdit] = useState(false);

  const {
    data: scheduleByDay,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["getTemplateScheduleDetail", templateId],
    () => getTemplateScheduleDetail(templateId!),
    {
      enabled: templateId !== undefined,
    }
  );

  const addTime = async (date: string) => {
    setAddModalDate(date);
    showModal();
  };

  //모달 열기
  const showModal = () => {
    setSelectData(undefined);
    //@ts-ignore
    window.addModal.showModal();
    setIsModalIsOpen(!addModalIsOpen);
  };

  const showEditModal = () => {
    //@ts-ignore
    window.addModal.showModal();
    setIsModalIsOpen(!addModalIsOpen);
  };

  const [
    deleteTemplate,
    { loading: deleteTemplateLoading, data: deleteTemplateData },
  ] = useMutation("/api/branch/templateDetail", "delete");

  const onDelete = async () => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result || !selectData) {
      return;
    }
    setSheduleLoading([...scheduleLoading, selectData.id]);

    deleteTemplate({}, selectData.id);
  };

  useEffect(() => {
    if (deleteTemplateData?.ok) {
      refetch();
      const newLoadingArray = scheduleLoading.filter(
        (item: number) => item !== selectData.id
      );

      setSheduleLoading(newLoadingArray);

      setDropdown({
        show: false,
        x: 0,
        y: 0,
      });
    }
  }, [deleteTemplateData]);

  useEffect(() => {
    if (!branchCode || data?.data?.branchCode === branchCode) {
      setIsEnableEdit(true);
    }
  }, [branchCode, data]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        dropdown.show &&
        !e.target.closest(".dropdown-content") &&
        !e.target.closest(".menu")
      ) {
        setDropdown({
          show: false,
          x: 0,
          y: 0,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const handleProgramClick = (e: any, selData: any) => {
    e.preventDefault();
    setDropdown({
      show: true,
      x: e.clientX,
      y: e.clientY,
    });

    setSelectData(selData);

    setAddModalDate(selData.day);
  };

  const onScheduleSettingsModal = () => {
    //@ts-ignore
    window.scheduleSettingsModal.showModal();
    setScheduleSettingsIsOpen(!scheduleSettingslIsOpen);
  };

  console.log(scheduleByDay);
  return (
    <>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            <span
              className="cursor-pointer"
              onClick={() =>
                navigate(
                  branchCode
                    ? `/branch/${branchCode}/template`
                    : "/admin/template"
                )
              }
            >
              시간표 템플릿
            </span>{" "}
            - {data?.data?.title}
          </h1>
          <>
            {templateId && (
              <TemplateAddTimeModal
                day={addModalDate}
                templateId={templateId}
                refetch={refetch}
                isOpen={addModalIsOpen}
                selectData={selectData}
              />
            )}
            {templateId && (
              <TemplateBulkModal
                isOpen={scheduleSettingslIsOpen}
                refetch={refetch}
                templateId={templateId}
              />
            )}
            <div className="">
              <div className="w-full bg-white px-5 py-10 drop-shadow-md ">
                <div className="flex flex-col justify-center ">
                  {isEnableEdit && (
                    <div className="flex  justify-end items-end w-fit self-end mb-5">
                      <Button
                        title={`일괄 변경`}
                        onClick={onScheduleSettingsModal}
                        iconComponent={<FontAwesomeIcon icon={faGear} />}
                      />
                    </div>
                  )}
                  <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 ">
                    {["일", "월", "화", "수", "목", "금", "토"].map(
                      (day: any, index: number) => (
                        <div
                          key={index}
                          className={cls(
                            "w-full  flex-col   flex  p-2  rounded-lg shadow-md overflow-hidden min-h-[300px]",
                            getBoxBackgroundClass(day)
                          )}
                        >
                          <div
                            className={cls(
                              "font-bold text-center pb-2  border-gray-300 mb-2 border-b"
                            )}
                          >
                            <div
                              className={cls(
                                "h-5 flex items-center mt-2 justify-center"
                              )}
                            >
                              {day}
                            </div>
                          </div>
                          <div>
                            {isLoading &&
                              Array.from({
                                length: Math.floor(Math.random() * 7) + 2,
                              }).map((_, cellIndex) => (
                                <div className="w-full h-[20px] skeleton-box rounded-lg m-1 shadow-md"></div>
                              ))}

                            {scheduleByDay &&
                              scheduleByDay?.data[day] &&
                              scheduleByDay?.data[day].map(
                                (program: any, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={(e) =>
                                        isEnableEdit && handleProgramClick
                                          ? handleProgramClick(e, program)
                                          : null
                                      }
                                      className={cls(
                                        "flex  px-2 bg-gymmate text-white   cursor-pointer     rounded-md  w-full   transition-all hover:bg-gray-300  items-center shadow-md text-sm h-5 mt-2 justify-between"
                                      )}
                                    >
                                      <p className=" font-bold ">
                                        {convertToHHMM(program.startTime)}
                                      </p>
                                      <div className="flex w-32 justify-end space-x-1 pl-1">
                                        <div className="text-xs flex justify-end items-center">
                                          <FontAwesomeIcon
                                            icon={faClock}
                                            width={8}
                                            className="mr-1"
                                          />
                                          {program.duration}
                                        </div>
                                        <div className=" flex text-xs justify-end items-center">
                                          <FontAwesomeIcon
                                            icon={faUser}
                                            width={8}
                                            className="mr-1"
                                          />
                                          {program.headcount}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          {isEnableEdit && addTime && (
                            <div
                              onClick={() => addTime(day)}
                              className="flex opacity-30 cursor-pointer mt-2  rounded-md  w-full h-5 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>

            {dropdown.show && (
              <div
                style={{ position: "fixed", top: dropdown.y, left: dropdown.x }}
              >
                <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box text-xs ">
                  <li onClick={showEditModal}>
                    <a>
                      <FontAwesomeIcon icon={faPenToSquare} />
                      수정
                    </a>
                  </li>
                  <li onClick={onDelete}>
                    <a>
                      <FontAwesomeIcon icon={faTrash} /> 삭제
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </>
        </div>
      </AdminLayout>
    </>
  );
}
