import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import { SecondsToMinutes, StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { SearchDataForProgram } from "../../constants/SearchData";
import { TableHeaderForStatistics } from "../../constants/TableHeader";
import { ProgramDto } from "../../types/dto";
import { Program } from "../../types/models";
import Modal from "../../components/interface/Modal";
import ProgramDetail from "../../components/ProgramDetail";

export default function Statistics() {
  const [mutate, setMutate] = useState(false);
  const tableHeader = TableHeaderForStatistics;
  const searchData = SearchDataForProgram;
  const [programId, setProgramId] = useState("");

  const showProgramDetailModal = (id: string) => {
    setProgramId(id);
    //@ts-ignore
    window.programDetail.showModal();
  };

  return (
    <>
      <Modal
        id="programDetail"
        title={`프로그램 상세보기`}
        size="xlarge"
        overflow="hidden"
      >
        <div className="mt-5">
          <ProgramDetail programId={programId} readOnly={true} />
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            프로그램 예약 통계
          </h1>

          <Table
            dataApiUrl="/api/admin/program"
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl="/api/admin/program/search"
          >
            {(data: Program[], startIndex: number) =>
              data.map((item: any, index: number) => {
                // console.log(item);
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>
                      <StringToDate dateString={item?.createdAt} />
                    </td>
                    <td>{item?.programType}</td>
                    <td>{item?.programCategory}</td>
                    <td
                      className=" cursor-pointer"
                      onClick={() => showProgramDetailModal(item.id)}
                    >
                      {item?.name}
                    </td>
                    <td className="">
                      <p> {item?.totalMotions}</p>

                      <p className="text-xs">{`(${item.exerciseTime}/${item.moveTime})`}</p>
                    </td>
                    <td className="">
                      <p>{item.totalSet}</p>

                      <p className="text-xs">
                        {" "}
                        <SecondsToMinutes seconds={item?.totalExerciseTime} />
                      </p>
                    </td>
                    <td>{item?.registrationCount}</td>
                    <td>{item?.reservationCount}</td>
                    <td>
                      {item?.averageCount !== null &&
                      item?.averageCount !== undefined
                        ? Number(Number(item.averageCount).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}
