export const TableHeaderForProgram = [
  { title: "No." },
  { title: "등록일" },
  { title: "프로그램 종류" },
  { title: "프로그램 구분" },
  { title: "프로그램명" },
  { title: "동작수 (SET당시간)" },
  { title: "총세트수 (총운동시간)" },
  { title: "최종수업일", value: "lastLectureDate" },
  { title: "관리" },
];

export const TableHeaderForStatistics = [
  { title: "No." },
  { title: "등록일", value: "createdAt" },
  { title: "프로그램 종류" },
  { title: "프로그램 구분" },
  { title: "프로그램명" },
  { title: "동작수 (SET당시간)" },
  { title: "총세트수 (총운동시간)" },
  { title: "수업일수", value: "registrationCount" },
  { title: "예약자수", value: "reservationCount" },
  { title: "평균예약자수", value: "averageCount" },
];

export const TableHeaderForContents = [
  { title: "No." },
  { title: "등록일", value: "createdAt" },
  { title: "부위" },
  { title: "도구" },
  { title: "영상" },
  { title: "운동명" },
  { title: "운동종류" },
  { title: "등록 수", value: "registrationCount" },
  { title: "관리" },
];
