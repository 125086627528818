import axios from "axios";

export const getClosestBranchScheduleId = async (branchCode: string) => {
  const { data } = await axios.get(
    `/api/public/group/${branchCode}/closest-future-schedule`,
    {}
  );

  return data;
};

export const getGroupData = async (scheduleId: string) => {
  const { data } = await axios.get(
    `/api/public/group/schedule/${scheduleId}`,
    {}
  );

  console.log("Get Group Data Complete!");
  return data;
};
