import axios from "axios";

export const getTemplate = async (branchCode: string) => {
  try {
    const { data } = await axios.get(
      `/api/branch/template?branchCode=${branchCode}`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getTemplateDetail = async (id: string) => {
  try {
    const { data } = await axios.get(`/api/branch/template/${id}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getTemplateScheduleDetail = async (id: string) => {
  const response = await axios.get(
    `/api/branch/templateDetail/byTemplate/${id}`
  );

  return response.data;
};

export const getTemplateScheduleDetailByTime = async (id: string) => {
  const response = await axios.get(`/api/branch/templateDetail/byTime/${id}`);

  return response.data;
};
