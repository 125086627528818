import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faLocation,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { Pagination } from "../../../components/interface/Pagination";
import { useEffect, useState } from "react";
import { SecondsToMinutes, StringToDate } from "../../../libs/utils";
import Table from "../../../components/interface/Table";
import { useUserStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { SearchDataForProgram } from "../../../constants/SearchData";
import { TableHeaderForProgram } from "../../../constants/TableHeader";

export default function Program() {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);

  const tableHeader = TableHeaderForProgram;
  const searchData = SearchDataForProgram;

  const onAddBtn = () => {
    navigate(`/admin/program/add`);
  };

  const onEditProgram = (id: number) => {
    navigate(`/admin/program/edit/${id}`);
  };

  const onDelete = async (item: any) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      const response = await axios.delete(`/api/admin/program/${item.id}`);
      console.log(response);
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  return (
    <>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            운동 프로그램 관리
          </h1>

          <Table
            dataApiUrl="/api/admin/program"
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={onAddBtn}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                프로그램 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl="/api/admin/program/search"
          >
            {(data: any, startIndex: number) =>
              data.map((item: any, index: number) => {
                // console.log(item);
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>
                      <StringToDate dateString={item?.createdAt} />
                    </td>
                    <td>{item?.programType}</td>
                    <td>{item?.programCategory}</td>
                    <td>{item?.name}</td>
                    <td className="">
                      <p> {item?.totalMotions}</p>

                      <p className="text-xs">{`(${item.exerciseTime}/${item.moveTime})`}</p>
                    </td>
                    <td className="">
                      <p>{item.totalSet}</p>

                      <p className="text-xs">
                        {" "}
                        <SecondsToMinutes seconds={item?.totalExerciseTime} />
                      </p>
                    </td>
                    <td className="">
                      <p>
                        {item?.lastLectureDate
                          ? item?.lastLectureDate.toString()
                          : null}
                      </p>
                    </td>
                    <td className="flex space-x-2">
                      {item.code !== "main" && (
                        <>
                          <button
                            onClick={() => onEditProgram(item.id)}
                            className="btn btn-xs"
                          >
                            수정
                          </button>
                          <button
                            onClick={() => onDelete(item)}
                            className="btn btn-xs btn-error"
                          >
                            삭제
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}
