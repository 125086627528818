import { useQuery } from "@tanstack/react-query";
import { getContentsByIds } from "../../services/contents";
import { ContentsWithOnlyVideoDto } from "../../types/dto";
import { BranchStreaming, Program } from "../../types/models";
import { useEffect, useRef, useState } from "react";
import { useBranchStore, useMuteStore } from "../../store";
import BottomProgress from "./BottomProgress";
import { motion } from "framer-motion";
import { count } from "console";
import useUpdateStremingIndex from "../../hooks/useUpdateStremingIndex";
import { useParams } from "react-router-dom";
import CompleteScreen from "./CompleteScreen";
import NonMainScreen from "./NonMainScreen";
import MainScreen from "./MainScreen";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import StartCountdown from "./StartCountdown";
import useMutation from "../../hooks/useMutation";

interface SubScreenProps {
  branchStreaming: BranchStreaming;
  program: Program;
  contentsIds: string;
}
const soundSrc = "/alert.mp3";
const middleSoundSrc = "/half_bell_sounds.mp3";
export default function WorkoutScreen({
  branchStreaming,
  program,
  contentsIds,
}: SubScreenProps) {
  const { branchCode } = useParams();
  const [currentIndex, setCurrentIndex] = useState(
    branchStreaming.indexNum || 0
  );
  const [finalIndex, setFinalIndex] = useState(0);
  const [slideDuration, setSlideDuration] = useState<number>(0); // 현재 프로그램의 운동시간
  const [moveDuration, setMoveDuration] = useState<number>(
    program.moveTime || 0
  ); // 현재 프로그램의 휴식/이동 시간
  const [isComplete, setIsComplete] = useState(
    branchStreaming.isComplete || false
  );
  const [counter, setCounter] = useState(0); // 1초마다 차감되게끔
  const [programCounter, setProgramCounter] = useState(0);
  const [pauseTime, setPauseTime] = useState<number>(0); // 일시정지한 시간(초)을 기록
  const [programCounterPauseTime, setProgramCounterPauseTime] =
    useState<number>(0);
  const [isMoveTime, setIsMoveTime] = useState(false);
  const [startCounter, setStartCounter] = useState(10);
  const [middleSound, setMiddleSound] = useState(true);

  const isMuted = useMuteStore((state) => state.isMuted); // Zustand store에서 상태 가져오기

  const { data, isLoading, isError, refetch } = useQuery<
    ContentsWithOnlyVideoDto[],
    Error
  >(
    ["getContentsByIds", contentsIds, false],
    () => getContentsByIds(contentsIds, false),
    {
      enabled: !!contentsIds,
    }
  );

  useEffect(() => {
    if (branchStreaming.flag === "playing") {
      //본운동 설명의 경우 20초로 고정
      let initDuration =
        branchStreaming.type === "tutorial" ? 20 : program.exerciseTime;

      //일시 정지 상태였을경우, 일시정지 초를 slideDuration으로
      let duration = pauseTime > 0 ? pauseTime : initDuration;

      setSlideDuration(initDuration);
      console.log("duration", duration);
      setCounter(duration);

      setPauseTime(0);

      programCounterInit(currentIndex);
    }
  }, []);

  //Final index 설정
  useEffect(() => {
    if (data) {
      console.log(data);
      let getFinalIndex =
        program.programType === "본운동" && branchStreaming.type === "tutorial"
          ? data.length - 1
          : program.totalSet - 1;

      setFinalIndex(getFinalIndex);
    }
  }, [data]);

  useEffect(() => {
    if (isMoveTime === true) {
      setCounter(moveDuration);
    } else {
      setCounter(slideDuration);
      setMiddleSound(true);
    }
  }, [isMoveTime, slideDuration, currentIndex, moveDuration]);

  //마지막 인덱스 넘었을때 종료 처리
  useEffect(() => {
    if (data && finalIndex > 0) {
      if (currentIndex > finalIndex) {
        setIsComplete(true);
      }
    }
  }, [currentIndex, data, finalIndex]);

  //종료가 활성화 되었을 때 실행
  useEffect(() => {
    if (isComplete) {
      //마지막 인덱스를 초과했을경우 (끝났을 경우)
      console.log("마지막 인덱스 도달, 20초후 종료됩니다.");
      setTimeout(function () {
        console.log("20초 경과, 프로그램 자동 종료");
        completePlay({}, branchStreaming.id);
      }, 20000); // 20,000 밀리초 = 20초
      //DB에서 삭제 명령
    }
  }, [isComplete]);

  const programCounterInit = (index: number) => {
    // 현재 index에 따른 남은 시간 계산
    let remaining_time;
    if (index === program.totalSet - 1) {
      // 마지막 운동 세트의 경우
      remaining_time = program.exerciseTime;
    } else if (index === 0) {
      // 첫번째 운동 세트의 경우
      remaining_time =
        (program.exerciseTime + program.moveTime) * program.totalSet -
        program.moveTime;
    } else {
      // 그 외의 경우
      remaining_time =
        (program.exerciseTime + program.moveTime) *
          (program.totalSet - index - 1) +
        program.exerciseTime;
    }

    // 프로그램 카운터 재설정
    setProgramCounter(remaining_time);
  };
  //branchStreaming 변경되었을 시
  useEffect(() => {
    let newCurrentIndex;
    if (branchStreaming.indexNum !== undefined) {
      newCurrentIndex = branchStreaming.indexNum;
    } else {
      newCurrentIndex = 0;
    }

    //index의 변경이 일어났을 경우 (지점관리자에서 조작한경우)
    if (newCurrentIndex !== currentIndex) {
      setCurrentIndex(newCurrentIndex);
      setIsMoveTime(false);

      programCounterInit(newCurrentIndex);
    }

    if (branchStreaming.isComplete) {
      setIsComplete(true);
    }
  }, [branchStreaming]);

  const {
    updateIndex,
    loading: updateIndexLoading,
    data: updateIndexData,
  } = useUpdateStremingIndex();

  const [completePlay, { loading, data: completePlayData }] = useMutation(
    "/api/public/branch/streaming",
    "delete",
    false
  );

  const nextStep = () => {
    setIsMoveTime(false);
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    setPauseTime(0); // Reset the pause time when timer is not paused
    setProgramCounterPauseTime(0);

    if (newIndex <= finalIndex) {
      console.log("DB 인덱스 업데이트");
      //아직 진행중인 경우
      //DB에 현재 INDEX 저장, 웹소켓 보내지 않음
      if (branchStreaming.type === "tutorial") {
        updateIndex({
          id: branchStreaming.id,
          branchCode: branchCode!,
          type: "forward",
          broadcast: true,
          operation: "playing-screen",
        });
      } else {
        updateIndex({
          id: branchStreaming.id,
          branchCode: branchCode!,
          type: "forward",
          broadcast: false,
        });
      }
    }
  };

  useEffect(() => {
    let timer: any;

    if (
      branchStreaming.flag !== "pause" &&
      counter > 0 &&
      !isComplete &&
      startCounter === 0
    ) {
      timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter - 1 < 1) {
            // 타이머 종료 시 실행
            if (isMoveTime) {
              //이동시간 종료 시 세트 변경
              setIsMoveTime(false);
            } else {
              if (
                moveDuration > 0 &&
                currentIndex < finalIndex &&
                branchStreaming.type !== "tutorial"
              ) {
                //이동시간(휴식시간) 설정되어 있을 경우, 운동시간 종료 시 이동시간 활성화
                nextStep();
                setIsMoveTime(true);
              } else {
                //이동시간(휴식시간) 설정 없을 경우, 즉시 세트 변경
                nextStep();
              }
            }
          }
          return prevCounter - 1;
        });
        setProgramCounter((prevCounter) => {
          return prevCounter - 1;
        });
      }, 1000);
    } else if (branchStreaming.flag === "pause") {
      setPauseTime(counter); // Save remaining time when paused
      setProgramCounterPauseTime(programCounter);
      clearInterval(timer); // Stop the counter decrement
    }
    return () => timer && clearInterval(timer); // clear the interval on component unmount or when branchStreaming.flag changes
  }, [branchStreaming.flag, counter, startCounter, isComplete]);

  const audioRef: any = useRef(null);
  audioRef.current = new Audio(soundSrc);
  useEffect(() => {
    if (
      (counter <= 5 && counter > 0) ||
      (startCounter < 4 && startCounter > 0)
    ) {
      if (audioRef.current && !isMuted) {
        audioRef.current.play().catch((error: any) => console.error(error));
      }
    }
  }, [counter, startCounter]);

  const middleAudioRef: any = useRef(null);
  middleAudioRef.current = new Audio(middleSoundSrc);

  // console.log("middleSound", middleSound);

  useEffect(() => {
    const halfTime =
      branchStreaming.type === "tutorial" ? 10 : program.exerciseTime / 2;

    // console.log("halfTime", halfTime);
    // console.log("counter", counter);

    if (
      counter <= halfTime &&
      !isMoveTime &&
      middleSound &&
      startCounter === 0 &&
      counter > 0
    ) {
      if (middleAudioRef.current && !isMuted) {
        middleAudioRef.current
          .play()
          .catch((error: any) => console.error(error));

        setMiddleSound(false);
      }
    }
  }, [
    counter,
    program,
    isMuted,
    middleSound,
    startCounter,
    isMoveTime,
    branchStreaming.type,
  ]);

  const startTimerRef: any = useRef();

  useEffect(() => {
    if (data) {
      if (
        currentIndex > 0 ||
        branchStreaming.type === "tutorial" ||
        program.programType === "준비운동"
      ) {
        setStartCounter(0);
      } else if (currentIndex === 0) {
        clearInterval(startTimerRef.current);
        runStartTimer();
      }
    } else {
      clearInterval(startTimerRef.current);
    }
  }, [data, branchStreaming, program, currentIndex]);

  const runStartTimer = () => {
    if (startCounter > 0) {
      startTimerRef.current = setInterval(() => {
        setStartCounter((prevCounter) => {
          if (prevCounter - 1 < 1) {
            // 타이머 종료 시 실행
            clearInterval(startTimerRef.current);
          }
          return prevCounter - 1;
        });
      }, 1000);
    }
  };

  // console.log("startCounter", startCounter);
  return (
    <div className="flex justify-center items-center min-h-screen h-[2160px] ">
      {isLoading ? (
        <span className="loading loading-bars bg-white w-96 h-96"></span>
      ) : isComplete ? (
        <CompleteScreen
          streamingType={branchStreaming.type}
          programType={program.programType}
        />
      ) : (
        data && (
          <div className="flex flex-col  w-full min-h-screen ">
            {startCounter > 0 && (
              <StartCountdown
                startCounter={startCounter}
                programType={program.programType}
              />
            )}
            {program.programType === "본운동" &&
            branchStreaming.type !== "tutorial" ? (
              <MainScreen data={data} currentIndex={currentIndex} />
            ) : (
              <NonMainScreen
                data={data}
                currentIndex={currentIndex}
                isMoveTime={isMoveTime}
                counter={counter}
              />
            )}

            <BottomProgress
              startCounter={startCounter}
              currentIndex={currentIndex}
              slideDuration={slideDuration}
              programType={program.programType}
              streamingType={branchStreaming.type}
              totalSet={
                branchStreaming.type === "tutorial"
                  ? program.tutorialIds !== null &&
                    program.tutorialIds !== undefined &&
                    program.tutorialIds !== ""
                    ? program.tutorialIds.split(",").length
                    : program.contentsIds.split(",").length
                  : program.totalSet
              }
              counter={counter}
              moveTime={moveDuration}
              isMoveTime={isMoveTime}
              flag={branchStreaming.flag}
              programCounter={programCounter}
            />
          </div>
        )
      )}
    </div>
  );
}
