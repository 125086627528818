import { useQuery } from "@tanstack/react-query";
import { getContentsByIds } from "../../services/contents";
import { ContentsWithOnlyVideoDto } from "../../types/dto";
import { BranchStreaming, Program } from "../../types/models";
import { useEffect, useState } from "react";
import { useBranchStore, useMuteStore } from "../../store";
import { cls } from "../../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";

interface BottomProgressProps {
  currentIndex: number;
  slideDuration: number;
  programType: string;
  streamingType: string;
  totalSet: number;
  counter: number;
  programCounter: number;
  isMoveTime: boolean;
  moveTime: number;
  startCounter: number;
  flag: string;
}

export default function BottomProgress({
  currentIndex,
  slideDuration,
  programType,
  streamingType,
  totalSet,
  counter,
  isMoveTime,
  moveTime,
  startCounter,
  flag,
  programCounter,
}: BottomProgressProps) {
  //처음 시작 정의
  useEffect(() => {
    if (startCounter === 0) {
      const element: any = document.querySelector(".progressbar");
      // 애니메이션 클래스 제거
      element.classList.remove("animate");

      // 강제 리플로우 (브라우저에게 애니메이션 변경을 알리는 트릭)
      void element.offsetWidth;

      // 애니메이션 클래스 다시 추가
      element.classList.add("animate");
      element.style.animationDuration = `${
        isMoveTime ? moveTime : slideDuration
      }s`;
    }
  }, [startCounter]);

  //Index 바뀌었을때 초기화
  useEffect(() => {
    if (currentIndex >= 0) {
      const element: any = document.querySelector(".progressbar");
      // 애니메이션 클래스 제거
      element.classList.remove("animate");

      // 강제 리플로우 (브라우저에게 애니메이션 변경을 알리는 트릭)
      void element.offsetWidth;

      // 애니메이션 클래스 다시 추가
      element.classList.add("animate");
      element.style.animationDuration = `${
        isMoveTime ? moveTime : slideDuration
      }s`;
    }
  }, [currentIndex, moveTime, isMoveTime, slideDuration]);

  const pauseAnimation = () => {
    const element: any = document.querySelector(".progressbar");
    element.classList.add("paused");
  };

  const resumeAnimation = () => {
    const element: any = document.querySelector(".progressbar");
    element.classList.remove("paused");
  };

  useEffect(() => {
    if (flag === "playing") {
      resumeAnimation();
    }

    if (flag === "pause") {
      pauseAnimation();
    }
  }, [flag]);

  const isMuted = useMuteStore((state) => state.isMuted); // Zustand store에서 상태 가져오기
  const toggleMute = useMuteStore((state) => state.toggleMute); // Zustand store에서 액션 가져오기

  const storeBranchName = useBranchStore((state) => state.branchName);

  const formatTime = (timeInSeconds: number) => {
    const date = new Date(timeInSeconds * 1000);
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="  text-white w-full  flex justify-center">
      <div
        className={cls(
          " flex items-center justify-between  ",
          programType === "본운동" && streamingType !== "tutorial"
            ? "w-[3040px]"
            : "w-full"
        )}
      >
        <div className="flex space-x-20 items-center">
          <div
            className={cls(
              "text-[200px] font-bold w-[537px] flex items-center text-center justify-center",
              isMoveTime
                ? "text-info"
                : counter < 6
                ? "text-warning"
                : "text-white"
            )}
          >
            {counter}
          </div>
          <div className="bg-gray-300 relative rounded-full w-[1200px] h-32 z-20 mt-7 overflow-hidden">
            {!isMoveTime && (
              <div className="h-32 w-5 z-50 bg-black m-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            )}
            <div
              className={cls(
                "progressbar  w-full h-full ",
                isMoveTime ? "bg-blue-400" : "bg-red-400"
              )}
            ></div>
          </div>
        </div>
        <div className="flex space-x-10 ">
          <div className="text-[100px] font-bold whitespace-nowrap">
            {streamingType === "tutorial"
              ? "Today's workout"
              : {
                  본운동: "Class",
                  스트레칭: "Strerching",
                  준비운동: "Warm-up",
                  마무리운동: "core flex",
                }[programType]}
          </div>
          <div className="flex space-x-20 items-center ">
            <div className="text-[100px]  font-bold w-full whitespace-nowrap ">
              {currentIndex + 1}/{totalSet} Set
            </div>
          </div>
          {streamingType !== "tutorial" && (
            <div className="text-[100px] font-bold">
              {formatTime(programCounter)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
