import axios from "axios";
import QueryString from "qs";

export const getTableData = async (
  page: number,
  size: number,
  sortBy: string,
  direction: string,
  dataApiUrl: string,
  branchCode?: string
) => {
  console.log("getTableData");
  const { data } = await axios.get(dataApiUrl, {
    withCredentials: true,
    params: {
      page,
      size,
      sortBy,
      direction,
      branchCode,
    },
  });
  return data;
};

export const getTableSearchData = async (
  page: number,
  size: number,
  sortBy: string,
  direction: string,
  dataApiUrl: string,
  searchParams: any
) => {
  console.log("getTableSearchData");
  const { data } = await axios.get(dataApiUrl!, {
    withCredentials: true,
    params: {
      ...searchParams,
      page,
      size,
      sortBy,
      direction,
    },
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: "comma" });
    },
  });
  return data;
};
