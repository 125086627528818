import { useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faHouse,
  faIdCard,
  faKey,
  faTriangleExclamation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../components/Layout/AdminLayout";

import { useEffect, useState } from "react";
import Modal from "../components/interface/Modal";
import Radio from "../components/interface/Radio";
import FormBox from "../components/interface/FormBox";
import FormInput from "../components/interface/FormInput";
import { StringToDate } from "../libs/utils";
import Table from "../components/interface/Table";
import { useUserStore } from "../store";
import { BranchDto, UserAccountDto } from "../types/dto";
import Button from "../components/interface/Button";

interface UserFormData {
  type: string | undefined;
  id: number | undefined;
  userId: string | undefined;
  password: string | undefined;
  manager: string | undefined;
  branchId?: number | undefined;
  newPassword: string | undefined;
  role: string | undefined;
}

interface AccountTableProps {
  apiUrl: string;
  newPasswordApiUrl: string;
  branchCode?: string;
}

export default function AccountTable({
  apiUrl,
  newPasswordApiUrl,
  branchCode,
}: AccountTableProps) {
  const user = useUserStore((state) => state.user);

  const [branchList, setBranchList] = useState<BranchDto[]>();
  const [mutate, setMutate] = useState(false);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  useEffect(() => {
    //콘텐츠 정보 가져오기
    getBranch();
  }, []);

  const getBranch = async () => {
    try {
      const response = await axios.get("/api/public/branches/all", {
        withCredentials: true,
      });

      console.log(response.data);
      setBranchList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<UserFormData>();

  const tableHeader = [
    { title: "No." },
    { title: "등록일" },
    { title: "지점명" },
    { title: "ID" },
    { title: "역할" },
    { title: "담당자명" },
    { title: "관리구분" },
  ];

  const resetFields = () => {
    setValue("type", "지점", { shouldValidate: true });
    setValue("id", undefined, { shouldValidate: true });
    setValue("userId", undefined, { shouldValidate: true });
    setValue("password", undefined, { shouldValidate: true });
    setValue("manager", undefined, { shouldValidate: true });
    setValue("newPassword", undefined, { shouldValidate: true });
    setValue("role", "admin", { shouldValidate: true });

    if (branchCode && branchList) {
      const myBranchId = branchList.find((loc) => loc.code === branchCode);
      setValue("branchId", myBranchId?.id, { shouldValidate: true });
    }
  };

  const showModal = () => {
    resetFields();
    setIsRemoveModal(false);
    window.my_modal_2.showModal();
  };

  const closeModal = () => {
    resetFields();
    setIsRemoveModal(false);
    window.my_modal_2.close();
  };

  const showPasswordModal = () => {
    resetFields();
    setIsRemoveModal(false);
    window.password.showModal();
  };

  const onValid = async (formData: UserFormData) => {
    try {
      if (formData.id) {
        const res = await axios.patch(
          `${apiUrl}/${formData.id}`,
          {
            userId: formData.userId,
            manager: formData.manager,
            branchId: formData.type === "본사" ? 1 : formData.branchId,
            role: formData.role || "admin",
          },
          { withCredentials: true }
        );
      } else {
        await axios.post(
          apiUrl,
          {
            userId: formData.userId,
            password: formData.password,
            manager: formData.manager,
            branchId: formData.type === "본사" ? 1 : formData.branchId,
            role: formData.role || "admin",
          },
          { withCredentials: true }
        );
      }

      if (formData.id === user?.id) {
        // window.location.reload();
        await axios.post("/api/logout", { withCredentials: true });
        window.location.reload();
      } else {
        setMutate(!mutate);
        closeModal();
      }
    } catch (error: any) {
      console.log(error.response);
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  const openEditModal = (item: UserAccountDto, enableRemove: boolean) => {
    showModal();

    console.log(item.branchCode);

    setValue("id", item.id, { shouldValidate: true });
    setValue("type", item.branchCode === "main" ? "본사" : "지점", {
      shouldValidate: true,
    });
    setValue("manager", item.manager, { shouldValidate: true });
    setValue("userId", item.userId, { shouldValidate: true });
    setValue("branchId", item.branchId!, { shouldValidate: true });
    setValue("role", item.role, { shouldValidate: true });

    setIsRemoveModal(enableRemove);
  };

  const openPaswordModal = (item: UserAccountDto) => {
    showPasswordModal();
    setValue("id", item.id);
  };

  const onDelete = async (item: UserFormData) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      await axios.delete(`${apiUrl}/${item.id}`, { withCredentials: true });
      closeModal();
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  const submitNewPassword = async () => {
    try {
      const response = await axios.post(
        newPasswordApiUrl,
        {
          userId: watch("id"),
          newPassword: watch("newPassword"),
        },
        { withCredentials: true }
      );

      alert("비밀번호 수정이 완료되었습니다.");

      if (watch("id") === user?.id) {
        // window.location.reload();
        // await axios.post("/api/logout", { withCredentials: true });
      }

      setMutate(!mutate);
      closeModal();
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };
  console.log(watch());
  console.log(isRemoveModal);

  return (
    <>
      <Modal id="password" title={`비밀번호 수정`}>
        {watch("id") && watch("id") === user?.id && (
          <div className="alert alert-warning mt-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6 "
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span className="text-sm">
              내 계정을 수정하려고 하고 있어요. <br />
              계정 수정 후 로그아웃 됩니다.
            </span>
          </div>
        )}
        <FormBox title="비밀번호" icon={<FontAwesomeIcon icon={faKey} />}>
          <FormInput
            placeholder="새로운 비밀번호"
            register={register("newPassword")}
          />
        </FormBox>
        <div className="w-full flex flex-col justify-center mt-5">
          <button
            onClick={submitNewPassword}
            className=" btn-primary btn w-full mt-2   text-white  "
          >
            {`비밀번호 수정`}
          </button>
        </div>
      </Modal>
      <Modal id="my_modal_2" title={`계정 ${watch("id") ? "수정" : "등록"}`}>
        {watch("id") && watch("id") === user?.id && (
          <div className="alert alert-warning mt-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6 "
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span className="text-sm">
              내 계정을 수정하려고 하고 있어요. <br />
              계정 수정 후 로그아웃 됩니다.
            </span>
          </div>
        )}

        <FormBox title="구분" icon={<FontAwesomeIcon icon={faBuilding} />}>
          <div className="flex space-x-5">
            {!branchCode && (
              <Radio
                title="본사"
                value="본사"
                register={register("type", {
                  required: "지점 또는 본사를 선택해주세요",
                })}
              />
            )}
            <Radio
              title="지점"
              value="지점"
              register={register("type", {
                required: "지점 또는 본사를 선택해주세요",
              })}
              disabled={
                branchCode ? false : watch("id") !== undefined && !isRemoveModal
              }
            />
          </div>
        </FormBox>
        {watch("type") === "지점" && (
          <FormBox title="지점명" icon={<FontAwesomeIcon icon={faHouse} />}>
            <select
              {...register("branchId", {
                required:
                  watch("type") === "지점" ? "지점을 선택해주세요" : false,
              })}
              className="select select-bordered w-full max-w-xs"
              disabled={branchCode ? true : false}
            >
              {branchList &&
                branchList.map(
                  (branch: BranchDto) =>
                    branch.code !== "main" && (
                      <option value={branch.id}>
                        {branch.name} ({branch.code})
                      </option>
                    )
                )}
            </select>
          </FormBox>
        )}
        {watch("type") === "지점" && (
          <FormBox title="역할" icon={<FontAwesomeIcon icon={faUser} />}>
            <select
              {...register("role", {
                required: watch("type") === "지점" ? true : false,
              })}
              className="select select-bordered w-full max-w-xs"
            >
              <option value="admin">지점 관리자</option>
              <option value="employee">지점 직원</option>
            </select>
          </FormBox>
        )}
        <FormBox title="담당자명" icon={<FontAwesomeIcon icon={faUser} />}>
          <FormInput
            placeholder="홍길동"
            register={register("manager", {
              required: "지점 또는 본사를 선택해주세요",
            })}
          />
        </FormBox>
        <FormBox title="아이디" icon={<FontAwesomeIcon icon={faIdCard} />}>
          <FormInput
            placeholder="아이디"
            register={register("userId", {
              required: "아이디를 입력해주세요",
            })}
          />
        </FormBox>
        {!watch("id") && (
          <FormBox title="비밀번호" icon={<FontAwesomeIcon icon={faKey} />}>
            <FormInput
              placeholder="비밀번호"
              register={register("password", {
                required: watch("id") ? false : true,
              })}
            />
          </FormBox>
        )}
        <div className="w-full flex flex-col justify-center mt-5">
          {isRemoveModal && (
            <button
              className=" btn w-full bg-black text-white  "
              onClick={() => onDelete(watch())}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              계정 삭제
            </button>
          )}
          <Button
            title={`계정 ${watch("id") ? "수정" : "등록"}`}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={isValid ? false : true}
          />
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            관리자 계정 관리
          </h1>
          <Table
            dataApiUrl={apiUrl}
            branchCode={branchCode}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={showModal}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                계정 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="registeredAt"
            initDirection="desc"
          >
            {(data: UserAccountDto[], startIndex: number) =>
              data.map((item: UserAccountDto, index: number) => {
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>
                      <StringToDate
                        dateString={item.registeredAt?.toString() || ""}
                      />
                    </td>

                    <td>{item.branchName}</td>
                    <td>{item.userId}</td>
                    <td>{item.role === "admin" ? "관리자" : "직원"}</td>
                    <td>{item.manager}</td>
                    <td className="flex">
                      <button
                        onClick={() =>
                          openEditModal(
                            item,
                            index === data.length - 1 || item.id === user?.id
                              ? false
                              : true
                          )
                        }
                        className="btn btn-xs"
                      >
                        수정
                      </button>
                      <button
                        onClick={() => openPaswordModal(item)}
                        className="btn btn-xs ml-2 truncate "
                      >
                        비밀번호 수정
                      </button>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}
