import axios from "axios";

export const uploadImageDirectly = async (imageFile: File) => {
  const formData = new FormData();
  formData.append("file", imageFile);

  try {
    const response = await axios.post("/api/admin/upload/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data; // S3에 업로드된 파일의 ETag를 반환합니다.
  } catch (error) {
    console.error("Failed to upload file directly: ", error);
  }
};

// export const uploadImageCloud = async (imageFile: File) => {
//   try {
//     // S3에 업로드하기 위한 사전 서명된 URL을 얻습니다.
//     const response = await axios.get(`/api/admin/upload/upload-url`, {
//       params: {
//         keyName: imageFile.name,
//       },
//     });

//     console.log(response.data);

//     // 미리 서명된 URL로 파일을 업로드합니다.
//     const result = await axios.put(response.data, imageFile, {
//       headers: {
//         "Content-Type": imageFile.type,
//       },
//     });

//     console.log(result);

//     if (result.status !== 200) {
//       throw new Error("Error during the upload");
//     }

//     // 업로드된 파일의 URL을 반환합니다.
//     return response.data.url;
//   } catch (error) {
//     console.error("Error during file upload:", error);
//     throw error;
//   }
// };
