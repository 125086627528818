import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faCircleXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import ContentsBox from "./ContentsBox";
import { useDrag } from "react-dnd";
import { AdsDtoWithVideo, ContentsDtoWithId } from "../types/dto";
import { DragItem } from "../types/dnd";

interface AdsBoxDragProps {
  item: AdsDtoWithVideo;
  onDelete: any;
  loading: number[];
  openEditModal: any;
}

const AdsBoxDrag = ({
  item,
  onDelete,
  loading,
  openEditModal,
}: AdsBoxDragProps) => {
  const [{ isDragging }, drag]: DragItem = useDrag({
    type: "CONTENT",
    item,
    collect: (monitor) => {
      const isDragging = monitor.isDragging();
      if (isDragging) {
        console.log("Drag has started!");
      }
      return { isDragging };
    },
  });

  const dragDropRef = (node: HTMLElement | null) => {
    drag(node);
  };

  return (
    <div
      ref={dragDropRef}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="w-40 h-36 relative ml-"
    >
      <FontAwesomeIcon
        className="absolute m-2 z-50 left-0 opacity-20 bg-gray-100 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
        icon={faArrowsUpDownLeftRight}
      />
      {onDelete && (
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="absolute m-2 z-50 right-0 opacity-20 bg-gray-100 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
          width={10}
          onClick={onDelete}
        />
      )}
      {openEditModal && (
        <FontAwesomeIcon
          icon={faPenToSquare}
          className="absolute m-2 z-50 right-0 top-6 opacity-20 bg-gray-100 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
          width={10}
          onClick={() => openEditModal(item)}
        />
      )}

      <span className="absolute p-1  bg-gymmate bottom-0 m-2 rounded-md text-xs  text-white">
        {item.time} 초 |{" "}
        {item.type === "video"
          ? "동영상"
          : item.type === "image"
          ? "이미지"
          : item.type === "youtube"
          ? "유튜브"
          : ""}
      </span>

      <div className="flex cursor-pointer  overflow-hidden rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md">
        {loading.includes(item.id) && (
          <div className="h-full w-full text-center ">
            <div className=" absolute  mx-auto  left-14 top-14 text-xs z-[70]">
              <span className="loading loading-spinner loading-xs "></span>
              <p>로딩 중</p>
            </div>
            <div className="bg-gray-400 w-full h-full z-[60] absolute opacity-40 rounded-md "></div>
          </div>
        )}
        {item.video &&
          (item.video?.state === 0 ? (
            <div className="relative h-full w-full text-center">
              <div className="absolute mx-auto  left-14 top-14 text-xs">
                <span className="loading loading-spinner loading-xs "></span>
                <p>업로드 중</p>
              </div>
              <img
                className="h-full w-full opacity-30"
                src="/video_tmp.avif"
                alt="Video Thumbnail"
              />
            </div>
          ) : (
            <img
              src={item.video.thumbnail || ""}
              className={` w-full h-full object-cover `}
              alt="Thumbnail"
            />
          ))}

        {item.type === "youtube" && (
          <img
            src={`https://img.youtube.com/vi/${item.youtubeId}/0.jpg`}
            alt="YouTube Video Thumbnail"
            className={` w-full h-full object-cover `}
          />
        )}

        {item.imgUrl && (
          <img
            src={item.imgUrl}
            className={` w-full h-full object-cover `}
            alt="preview"
          />
        )}
      </div>
    </div>
  );
};

export default AdsBoxDrag;
