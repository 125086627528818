import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faLocation,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";

import { SecondsToMinutes, StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";

import { useNavigate } from "react-router-dom";
import FullPageLoading from "../interface/FullPageLoading";
import Button from "../interface/Button";
import { TableHeaderForProgram } from "../../constants/TableHeader";
import { SearchDataForProgram } from "../../constants/SearchData";
import { ProgramDto } from "../../types/dto";
import { Program } from "../../types/models";
import Modal from "../interface/Modal";
import ProgramDetail from "../ProgramDetail";

interface ProgramsSearchModalProps {
  modalId: string;
  refetch: any;
  selectedData?: any;
}

export default function ProgramsSearchModal({
  modalId,
  refetch,
  selectedData,
}: ProgramsSearchModalProps) {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);

  const tableHeader = TableHeaderForProgram;
  const searchData = SearchDataForProgram;

  const [loading, setLoading] = useState(false);

  const [modalProgramId, setModalProgramId] = useState("");

  const onAddBtn = async (item: Program) => {
    try {
      setLoading(true);
      const response = await axios.post("/api/admin/schedule", {
        date: modalId,
        contentId: item.id,
      });

      console.log(response.data);
      refetch();
      setMutate(!mutate);
      //@ts-ignore
      window.searchProgram.close();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
    setLoading(false);
  };

  const handleOpenDetail = (id: string) => {
    setModalProgramId(id);
    //@ts-ignore
    window.programDetailModal.showModal();
  };

  return (
    <>
      <Modal
        id="programDetailModal"
        title={`프로그램 상세보기`}
        size="xlarge"
        overflow="hidden"
      >
        <div className="mt-5">
          <ProgramDetail programId={modalProgramId} readOnly={true} />
        </div>
      </Modal>
      {loading && <FullPageLoading />}
      <div className="mt-5">
        <Table
          dataApiUrl="/api/admin/program"
          headerArray={tableHeader}
          mutate={mutate}
          initSort="createdAt"
          initDirection="desc"
          searchData={searchData}
          searchApiUrl="/api/admin/program/search"
        >
          {(data: Program[], startIndex: number) =>
            data.map((item: Program, index: number) => {
              // console.log(item);
              return (
                <tr key={item.id}>
                  <th>{startIndex - index}</th>
                  <td>
                    <StringToDate dateString={item?.createdAt.toString()} />
                  </td>
                  <td>{item?.programType}</td>
                  <td>{item?.programCategory}</td>
                  <td>
                    {item?.name}
                    <FontAwesomeIcon
                      className=" cursor-pointer ml-2 "
                      onClick={() => handleOpenDetail(item.id.toString())}
                      icon={faArrowUpRightFromSquare}
                    />
                  </td>
                  <td className="">
                    <p> {item?.totalMotions}</p>

                    <p className="text-xs">{`(${item.exerciseTime}/${item.moveTime})`}</p>
                  </td>
                  <td className="">
                    <p>{item.totalSet}</p>

                    <p className="text-xs">
                      {" "}
                      <SecondsToMinutes seconds={item?.totalExerciseTime} />
                    </p>
                  </td>
                  <td className="">
                    <p>
                      {item?.lastLectureDate
                        ? item?.lastLectureDate.toString()
                        : null}
                    </p>
                  </td>
                  <td>
                    <Button
                      loading={loading}
                      title="추가"
                      onClick={() => onAddBtn(item)}
                      iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
                      disabled={selectedData?.some(
                        (data: any) => data.id === item.id
                      )}
                    />
                  </td>
                </tr>
              );
            })
          }
        </Table>
      </div>
    </>
  );
}
