import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BranchStreaming, Contents, Program } from "../../types/models";

import { ContentsDto, ContentsWithOnlyVideoDto } from "../../types/dto";
import { getContentsByIds } from "../../services/contents";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import useUpdateStremingIndex from "../../hooks/useUpdateStremingIndex";

const TutorialDetail = ({
  branchStreaming,
  program,
}: {
  branchStreaming?: BranchStreaming;
  program: Program;
}) => {
  const { branchCode } = useParams();

  const [contentsIds, setContentsIds] = useState<string>();

  const { data, isLoading, isError, refetch } = useQuery<
    ContentsWithOnlyVideoDto[],
    Error
  >(
    ["getContentsByIds", contentsIds, false],
    () => getContentsByIds(contentsIds!, false),
    {
      enabled: !!contentsIds,
    }
  );

  useEffect(() => {
    if (program.contentsIds) {
      setContentsIds(
        program.tutorialIds !== null &&
          program.tutorialIds !== undefined &&
          program.tutorialIds !== ""
          ? program.tutorialIds
          : program.contentsIds
      );
    }
  }, [program]);

  const [swiper, setSwiper] = useState<any>(null);

  useEffect(() => {
    if (swiper && branchStreaming) {
      swiper.slideTo(branchStreaming.indexNum ? branchStreaming.indexNum : 0);
    }
  }, [swiper, branchStreaming]);

  const {
    updateIndex,
    loading: updateIndexLoading,
    data: updateIndexData,
  } = useUpdateStremingIndex();

  const handleSlideChange = () => {
    if (swiper && branchStreaming && branchCode) {
      console.log("slide change", swiper.activeIndex);

      updateIndex({
        id: branchStreaming.id,
        branchCode: branchCode!,
        type: "changeIndex",
        broadcast: true,
        indexNum: swiper.activeIndex,
      });
    }
  };

  return (
    <div className="text-sm w-full ">
      <div className="bg-base-200 mt-5 p-2 mb-3 shadow-sm h-96 overflow-y-scroll ">
        <h1 className="font-bold">프로그램 코멘트</h1>
        <p className="whitespace-pre-wrap mb-5">{program.comment}</p>
        <div className="border mb-5"></div>
        <div>
          <Swiper
            onSwiper={setSwiper}
            //@ts-ignore
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={handleSlideChange}
            pagination={{ clickable: true }}
          >
            {data &&
              data.map((content: ContentsWithOnlyVideoDto, index: number) => (
                <SwiperSlide key={index}>
                  <div>
                    <div className=" justify-center flex flex-col items-center mb-2">
                      <div
                        className="relative w-full h-0 overflow-hidden bg-white mb-2"
                        style={{ paddingTop: "56.25%" }}
                      >
                        <video
                          src={content.video?.fileLink || ""}
                          className="absolute top-0 left-0 w-full h-full"
                          autoPlay
                          loop
                          muted
                          preload="auto"
                          playsInline
                        />
                      </div>
                      <p>
                        {index + 1}/{data.length}
                      </p>
                    </div>
                    <p className="font-bold">{content.content.name}</p>
                    <p className="whitespace-pre-wrap mb-10">
                      {content.content.comment}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TutorialDetail;
