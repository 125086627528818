import ScreenLayout from "../../components/Layout/ScreenLayout";
import AdsSlideWithData from "../../components/AdsSlideWithData";

export default function ScreenAds() {
  return (
    <ScreenLayout>
      <AdsSlideWithData schedule={true} />
    </ScreenLayout>
  );
}
