export default function FullPageLoading() {
  return (
    <div className="fixed z-[999] inset-0 ">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity"></div>
        <div className="z-50">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      </div>
    </div>
  );
}
